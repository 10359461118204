import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import Switch from 'rc-switch';
import { DragDropContext } from 'react-beautiful-dnd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import { Menu } from '../Main/Menu';
import InfoTextsModal from '../Main/InfoTextsModal';
import { CardBoard, cardValues, getCount } from './Cards';
import { apiPath, changeThemeColor, globalErrorHandler, messages } from '../../reusableFunctions';

import './Audience.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faBars, faPlus, faUsers, faPencilAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
library.add(faSearch, faBars, faSave, faPlus, faUsers, faPencilAlt, faDownload);

let audienceName = messages["Audience.audienceName"];
let targetGroupJSON = [];

const AudienceTopHeader = (props) => {
  const moveMenu = props.moveMenu;
  let audienceTopHeaderText = ''
  let targetGroupName = props.targetGroupName;

  if (props.personalized) {
    if (targetGroupName === '') { audienceTopHeaderText = 'Audience'; }
    else { audienceTopHeaderText = targetGroupName; }
  } else {
    audienceTopHeaderText = <FormattedMessage id="Audience.topheadertext" defaultMessage="Neue Display- & Retargeting- Zielgruppe" />;
  }
  return (
    <div className="audience-top-header">
      <div className="row">
        <div className="col-8">
          <span className="audience-top-header-text">
            {audienceTopHeaderText}
          </span>
        </div>
        <div className="col">
          <div className="audience-top-header-icon-wrapper">
            <InfoTextsModal
              info="Audience:top:general"
            />
            <button
              id="evaluation_neu_back_desk"
              onClick={() => moveMenu()}
              style={{
                background: 'transparent',
                border: 'none',
                color: 'white',
                padding: 0,
                bottom: '1px',
                position: 'relative'
              }}>
              <i className="fas fa-bars"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

class AudienceBottomHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audienceName: this.props.audienceName,
      toggleSaveButton: false,
      targetgroupId: false
    }
    this.loadTargetGroup = this.props.loadTargetGroup;
  }

  saveTargetGroup() {

    let audienceName = document.querySelector("#audience-name-input").value;

    let bool = false;

    this.setState({ toggleSaveButton: bool });

    //TODO: if saved reRender sideMenu (TargetList)

    for (let i in cardValues) { // for operators < >
      if (cardValues[i][0]) {
        if (typeof cardValues[i][0] === 'string' && (cardValues[i][0].includes("<") || cardValues[i][0].includes(">"))) {
          for (let j in cardValues[i]) {
            cardValues[i][j] = JSON.parse(cardValues[i][j]);
          }
        }
      }
    }

    let query = JSON.stringify(cardValues).toString().replace(/"/g, '\\"');


    if (audienceName === '' || audienceName === messages['Audience.audienceName']) {
      this.notifyName();
      return;
    }

    this.props.targetList.map(function (name) {
      if (audienceName === name["name"] || audienceName === '') bool = true;
      return null;
    });

    if (Object.keys(query).length === 2) {
      this.notifyNoSelection();
      return;
    }

    if (bool) {
      this.notifyNameGiven();
      return;
    }

    let count = Number.parseInt(this.props.count.replace(/\./g, ""));

    let that = this;
    fetch(apiPath + 'audience', {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: '{ "name" : "' + audienceName + '", "query_object" : "' +
        query + '", "lastCount" : ' + count + '}'
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.success) {
        that.setState({
          targetgroupId: data.success
        }, () => {
          that.notifySave();
          let audienceText = document.querySelector('.audience-top-header-text');
          audienceText.textContent = audienceName; // needs to be redone but works for now
        });
      } else {
        that.notifyNoSave();
        return false;
      }
    }).catch(function (error) {
      globalErrorHandler(error);
    });

    this.toggleSaveButton = true;
  }

  deleteTargetgroup = (id) => {
    let that = this;
    fetch(apiPath + 'audience/delete/' + id, {
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      if (data.status === 'success') {
        that.notifyDelete();
        Audience.deleteAudience();
      } else if (data.status === 'error') {
        that.notifyUsedInJourney();
      }
    }).catch(function (error) {
      // globalErrorHandler(error);
    });
  }

  notifyNoSelectionText = <FormattedMessage id="Audience.notify.noSelectionText" defaultMessage="Bitte wählen Sie eine Option aus" />;
  notifyNameGivenText = <FormattedMessage id="Audience.notify.nameGivenText" defaultMessage="Name bereits vergeben" />;
  notifySaveText = <FormattedMessage id="Audience.notify.saveText" defaultMessage="Audience erfolgreich gespeichert" />;
  notifyNoSaveText = 'Fehler beim Speichern der Audience';
  notifyNameText = <FormattedMessage id="Audience.notify.nameText" defaultMessage="Bitte geben sie der Audience einen Namen" />;
  notifyDeleteText = <FormattedMessage id="Audience.notify.audienceDeleted" defaultMessage="Audience erfolgreich gelöscht" />;
  notifyUsedInJourneyText = <FormattedMessage id="Audience.notify.audienceInUse" defaultMessage="Audience wird in Journey benutzt" />;


  notifyNoSelection = () => toast(this.notifyNoSelectionText, {
    className: 'audience-notify',
    autoClose: 3000,
    progressClassName: 'audience-notify-progressbar'
  });
  notifyNameGiven = () => toast(this.notifyNameGivenText, {
    className: 'audience-notify',
    autoClose: 3000,
    progressClassName: 'audience-notify-progressbar'
  });
  notifySave = () => toast(this.notifySaveText, {
    className: 'audience-notify',
    autoClose: 3000,
    progressClassName: 'audience-notify-progressbar'
  });
  notifyNoSave = () => toast(this.notifyNoSaveText, {
    className: 'audience-notify',
    autoClose: 3000,
    progressClassName: 'audience-notify-progressbar'
  });
  notifyName = () => toast(this.notifyNameText, {
    className: 'audience-notify',
    autoClose: 3000,
    progressClassName: 'audience-notify-progressbar'
  });
  notifyDelete = () => toast(this.notifyDeleteText, {
    className: 'audience-notify',
    autoClose: 3000,
    progressClassName: 'audience-notify-progressbar'
  });
  notifyUsedInJourney = () => toast(this.notifyUsedInJourneyText, {
    className: 'audience-notify',
    autoClose: 3000,
    progressClassName: 'audience-notify-progressbar'
  });

  deleteAlert = (id) => {
    confirmAlert({
      title: messages['Audience.delete.title'],
      message: messages['Audience.delete.message'],
      buttons: [
        {
          label: messages['Audience.delete.yes'],
          onClick: () => { this.deleteTargetgroup(id); }
        },
        {
          label: messages['Audience.delete.no'],
          onClick: () => { return false; }
        }
      ]
    });
  }

  componentDidUpdate = () => {
    if ((this.state.targetgroupId !== this.props.targetgroupId) && this.props.targetgroupId !== false) {
      this.setState({ targetgroupId: this.props.targetgroupId });
    }
  }

  componentWillUnmount = () => { this.setState({ targetgroupId: false }); }

  render() {
    return (
      <div className="audience-bottom-header">
        <div className="row">
          {this.state.targetgroupId ?
            <div className="col-12 col-md-6">
              <div style={{ display: 'inline-block' }}>
                <button
                  onClick={() => this.deleteAlert(this.state.targetgroupId)}
                  className="droid-btn audience-delete-btn shadow"
                >
                  <span className="audience-btn-text">
                    <FormattedMessage
                      id="Audience.delete.deleteButtonText"
                      defaultMessage="Löschen"
                    />
                  </span>
                  <i className="far fa-trash-alt"></i>
                </button>
              </div>
              <div style={{ display: 'inline-block', float: 'left' }}>
                <AudienceDownloadButton
                  targetgroupId={this.state.targetgroupId}
                />
              </div>
            </div>
            :
            <div className="col-12 col-md-6">
              <button
                onClick={() => this.saveTargetGroup()}
                disabled={this.toggleSaveButton}
                className="droid-btn audience-save-btn shadow"
              >
                <span className="audience-btn-text">
                  <FormattedMessage
                    id="Audience.saveButton"
                    defaultMessage="Einstellungen speichern"
                  />
                </span>
                <i className="far fa-save" />
              </button>
            </div>
          }
          <div className="col-md-6 d-none d-md-block">
            <AudienceCount
              count={this.props.count}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

const AudienceDownloadButton = (props) => {

  function checkExport(id) {
    const currentToken = localStorage.getItem('token');
    const url = apiPath + 'audience/' + id + '/export';
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      dataType: 'json',
      headers: {
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + currentToken
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.success) {
        toast('Audience CSV export will be created in the background', {
          className: 'audience-notify',
          autoClose: 3000,
          progressClassName: 'audience-notify-progressbar'
        });
        getExport(id);
      } else {
        toast('ERROR: Audience CSV export', {
          className: 'audience-notify',
          autoClose: 3000,
          progressClassName: 'audience-notify-progressbar'
        });
      }
    }).catch(function (error) {
      toast('ERROR: Audience CSV export', {
        className: 'audience-notify',
        autoClose: 3000,
        progressClassName: 'audience-notify-progressbar'
      });
    });

  }

  function getExport(id) {
    const currentToken = localStorage.getItem('token');
    const url = apiPath + 'audience/' + id + '/get/export';
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': 'Bearer ' + currentToken
      }
    }).then(function (response) {
      return response.text();
    }).then(function (data) {
      if (data.startsWith('{')) {
        data = JSON.parse(data);
        toast(data.status, {
          className: 'audience-notify',
          autoClose: 1000,
          progressClassName: 'audience-notify-progressbar'
        });
        setTimeout(function () {
          getExport(id);
        }, 1000);
      } else {
        const audienceName = document.querySelector('.audience-top-header-text').textContent;
        const encodedUri = 'data:application/csv;charset=utf-8,' + encodeURIComponent(data);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', audienceName + '.csv');
        document.body.appendChild(link);
        link.click();
      }
    }).catch(function (error) {

    });
  }

  return (
    <button
      onClick={() => checkExport(props.targetgroupId)}
      className="droid-btn audience-delete-btn shadow"
    >
      <span className="audience-btn-text">
        Download Audience
      </span>
      <i className="fas fa-download"></i>
    </button>
  )
}

const AudienceNameInput = (props) => {

  function updateInputValue(value) { audienceName = value; }

  let audienceName = props.audienceName;
  if (props.targetGroupName) { audienceName = props.targetGroupName; }

  return (
    <div className="audience-name-input">
      <div className="row">
        <div className="col">
          <label>
            <FormattedMessage
              id="Audience.nameInput"
              defaultMessage="Name der Empfängergruppe"
            />
          </label><br />
          <input
            id="audience-name-input"
            type="text"
            disabled={props.targetGroupName ? true : false}
            placeholder={audienceName}
            onChange={(e) => updateInputValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export class ProtoAudienceSwitch extends React.Component {

  updateState = this.props.updateState;

  state = {
    disabled: false,
    personalized: this.props.personalized
  }

  toggle = () => { // toggle function can disable/enable the switch
    this.setState({ disabled: !this.state.disabled, });
  }

  onChange = (value) => {
    this.updateState('personalized', value); // Switch between anonym and personalized audience
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <Switch
          onChange={this.onChange}
          disabled={this.state.disabled}
          defaultChecked={this.state.personalized}
        />
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

const AudienceSwitchAnonym = (props) => {

  let personalized = props.personalized;
  const updateState = props.updateState;

  if (personalized !== undefined) {
    return (
      <div className="row">
        <div className="col text-center">
          <span className="audience-switch-top-text">
            <FormattedMessage
              id="Audience.personalized.topText"
              defaultMessage="Anonyme Audience"
            />
          </span>
          <ProtoAudienceSwitch
            personalized={personalized}
            updateState={updateState}
          />
          <span className="audience-switch-bottom-text">
            <FormattedMessage
              id="Audience.personalized.bottomText"
              defaultMessage="Personalisierte Audience"
            /></span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <div className="col text-center">
          <span>
            <FormattedMessage
              id="Audience.error.audienceSwitch"
              defaultMessage="Problem mit Audience-Switch"
            /></span>
        </div>
      </div>
    );
  }
}

const AudienceSwitchEnrichment = () => {
  return (
    <div className="audience-switch-enrichment">
      <div className="row">
        <div className="col text-center">
          <ProtoAudienceSwitch />
        </div>
      </div>
    </div>
  );
}

const AudienceCount = (props) => {
  return (
    <div className="audience-count">
      <div className="little-loading-droid"></div>
      <div className="row text-center">
        <div className="col">
          <span className="count">{props.count} </span><br />
          <span>
            <FormattedMessage
              id="Audience.audienceCountText"
              defaultMessage="erreichbare Personen"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

const AudienceCountFloat = (props) => {
  let personalized = props.personalized;
  const updateState = props.updateState;

  return (
    <div className="audience-count-switch d-block d-md-none">
      <div className="row">
        <div className="col count-wrapper text-center">
          <AudienceCount
            count={props.count}
          />
        </div>
        <div className="col enrichment">
          <AudienceSwitchEnrichment />
        </div>
        <div className="col audience-switch-anonym">
          <AudienceSwitchAnonym
            personalized={personalized}
            updateState={updateState}
          />
        </div>
      </div>
    </div>
  );
}

export class Audience extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardSelections: {},
      cardLongnames: [],
      cardNames: [],
      cardsLoaded: false,
      cardValues: {},
      personalized: true,
      count: 0,
      sideMenu: false,
      targetGroupName: '',
      targetGroupQuery: [],
      targetgroupId: false,
      targetList: false
    };
    this.baseState = this.state;
  };

  static deleteAudience = () => {
    window.location.reload();
  }

  updateState = (type, newState) => {
    if (type === 'personalized') {
      this.setState({
        personalized: newState
      });
    } else if (type === 'count') {
      this.setState({
        count: newState
      }, () => Audience.loadingCount(false));
    }
  }

  static loadingCount = (bool) => { // show/hide loading-droid when getting the count
    if (bool) {
      document.querySelector('.audience-count .count').style.display = 'none';
      document.querySelector('.audience-count .little-loading-droid').style.display = 'block';
    } else {
      document.querySelector('.audience-count .little-loading-droid').style.display = 'none';
      document.querySelector('.audience-count .count').style.display = 'inline';
    }
  }

  componentDidMount = () => {
    this.getCards();
    this.getTargetList();
  }

  getCards = () => {
    var that = this;

    fetch(apiPath + 'audience/cards', {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {

      let cardSelections = []; // all the selections of the cards
      let cardLongnames = []; // the full name of the card

      let cards = data.success;
      for (let i in cards) {
        cardLongnames.push(cards[i].name);
        cardSelections.push(cards[i].children);
      }

      that.setState({
        cardsLoaded: true,
        cardSelections: cardSelections,
        cardLongnames: cardLongnames,
      });
    }).catch(function (error) {
      globalErrorHandler(error);
      that.setState({
        cardsLoaded: false
      });
    })
    let color = '#5E1763';
    changeThemeColor(color);
  }

  getTargetList = () => {
    let that = this;
    fetch(apiPath + 'audiences', {
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      data = data.reverse();
      that.setState({
        targetList: data
      });
    }).catch(function (error) {
      globalErrorHandler(error);
    });
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = (result) => {
    // the only one that is required
    if (!result.destination) {
      return;
    } else { // reorder cards arrays if user reorderd in FrontEnd
      let reorderedCardSelections = this.reorder(
        this.state.cardSelections,
        result.source.droppableId,
        result.destination.droppableId
      );
      let reorderedCardLongnames = this.reorder(
        this.state.cardLongnames,
        result.source.droppableId,
        result.destination.droppableId
      );

      this.setState({
        cardSelections: reorderedCardSelections,
        cardLongnames: reorderedCardLongnames
      });
    }
  };

  moveMenu = () => {
    this.setState({ sideMenu: !this.state.sideMenu });
  }

  user = {
    count: 0,
    targetGroupName: '',
    targetGroupQuery: {}
  }

  loadTargetGroup = (count, audienceName, query, targetgroupId) => {

    getCount(this.updateState, query);

    this.setState({
      count: count,
      targetGroupName: audienceName,
      targetgroupId: targetgroupId
    }, () => {
      this.user.targetGroupQuery = JSON.parse(query);
      this.user.targetGroupName = audienceName;
      this.user.count = count;
      this.moveMenu();
    });
  }

  render() {
    return (
      <div className="audience droid-module">
        <Menu
          showMenu={this.state.sideMenu}
          moveMenu={this.moveMenu}
          moduleName="audience"
          loadTargetGroup={this.loadTargetGroup}
          updateState={this.updateState}
          targetList={this.state.targetList}
        />
        <ToastContainer />
        <div className="audience-header">
          <AudienceTopHeader
            personalized={this.state.personalized}
            moveMenu={this.moveMenu}
            targetGroupName={this.state.targetGroupName}
          />
          <AudienceBottomHeader
            count={this.state.count}
            name={this.user}
            query={this.state.updateState}
            targetList={this.state.targetList}
            sideMenu={this.state.sideMenu}
            moveMenu={this.moveMenu}
            loadTargetGroup={this.loadTargetGroup}
            targetgroupId={this.state.targetgroupId}
          />
        </div>
        <div className="audience-body">
          <div className="row">
            <div className="col-12">
              <AudienceNameInput
                audienceName={audienceName}
                targetGroupName={this.state.targetGroupName}
              />
              {(window.innerWidth < 768) ?
                <AudienceCountFloat
                  personalized={this.state.personalized}
                  updateState={this.updateState}
                  count={this.state.count}
                />
                :
                null}
            </div>
          </div>
          <div className="droppable-wrapper">
            {this.state.cardsLoaded ?
              <div>
                {(this.state.cardSelections.length !== 0) ?
                  <DragDropContext
                    onDragStart={this.onDragStart}
                    onDragUpdate={this.onDragUpdate}
                    onDragEnd={this.onDragEnd}
                  >
                    <CardBoard
                      cardSelections={this.state.cardSelections}
                      cardLongnames={this.state.cardLongnames}
                      cardNames={this.state.cardNames}
                      personalized={this.state.personalized}
                      updateState={this.updateState}
                      tick={this.user}
                    />
                  </DragDropContext>
                  :
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ color: 'white', fontSize: '1.5em' }}>
                      <FormattedMessage
                        id="Audience.error.noCards"
                        defaultMessage="Keine Cards angelegt"
                      />
                    </span>
                  </div>
                }
              </div>
              :
              <div style={{ textAlign: 'center' }}>
                <span style={{ color: 'white', fontSize: '1.5em' }}>
                  <FormattedMessage
                    id="Audience.error.loadingCards"
                    defaultMessage="Cards werden geladen"
                  />
                </span>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
};

export class AudienceMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      targets: [],
      targetJSON: {},
      targetList: false,
      searchResult: false,
      value: '',
      count: 0
    }
    this.baseState = this.state;
    this.loadTargetGroup = this.props.loadTargetGroup;
    this.handleChange = this.handleChange.bind(this);
  }

  audienceName
  updateState = this.props.updateState;

  loadTargetGroupItem = (obj) => {
    let urlInput = document.getElementsByClassName('audience-selection-input')[0];
    if (urlInput) {
      urlInput.value = '';
    }
    if (obj.query_object === "") {
      return false;
    }
    this.updateState('count', obj.lastCount);
    return (
      this.count = JSON.stringify(obj.lastCount),
      this.audienceName = JSON.stringify(obj.name).replace(/['"]+/g, ''),
      this.loadTargetGroup(this.count, this.audienceName, obj.query_object, obj.id))
  }

  deleteInfo = () => {
    Audience.deleteAudience();
  }

  componentDidMount() {
    let targetList = (this.props.targetList.map((obj) =>
      <li className="row" key={JSON.stringify(obj).toString()} onClick={() => { this.loadTargetGroupItem(obj) }} >
        <div className="col-6 text-left">{JSON.stringify(obj.name).replace(/['"]+/g, '')}</div>
        <div className="col-4 text-right">{'~' + JSON.stringify(obj.lastCount)}</div>
        <div className="col-2 icon-color"><i className="fas fa-users align-self-center" /></div>
      </li>
    ));
    if (targetList.length === 0) {
      targetList = <li className="row" style={{ cursor: 'unset' }}>
        <div className="col-12 text-center">
          <FormattedMessage
            id="Audience.menu.noAudiences"
            defaultMessage="Bitte legen Sie eine Empfängergruppe an"
          />
        </div>
      </li>
    }
    this.setState({
      targetList: targetList
    });
  }

  handleChange(event) {

    let input = event.target.value;
    this.setState({ value: event.target.value });

    function searchCondition(obj) {
      let condition = ((obj.name.includes(input)) || (obj.name.toUpperCase().includes(input.toUpperCase()))) && (input !== '');
      if (condition) {
        return obj;
      }
    }

    let target = this.props.targetList;
    let searchResult = target.filter(target => searchCondition(target));

    let searchResultReturn = false;
    if (searchResult.length > 0) {
      searchResultReturn = searchResult.map((result) => (
        <li
          className="row audience-single-search-result"
          key={JSON.stringify(result).toString()}
          onClick={() => { this.loadTargetGroupItem(result) }}
        >
          <div className="col no-padder text-left">{JSON.stringify(result.name).replace(/['"]+/g, '')}</div>
          <div className="col-md-3 text-right">{JSON.stringify(result.lastCount)}</div>
          <div className="icon-color"><i className="fas fa-users align-self-center" /></div>
        </li>
      ));
    } else {
      (input === '') ? searchResultReturn = false : searchResultReturn = <span>
        <FormattedMessage
          id="Audience.error.noSearchResult"
          defaultMessage="Kein Ergebnis"
        />
      </span>;
    }

    this.setState({ searchResult: searchResultReturn });
  }

  defineNewTargetGroup() {
    this.setState({
      // TODO: set everything back and active the "Einstellungen speichern"-btn
    })
  }

  render() {
    return (
      <div className="audience-menu-inner">
        <div className="createNewTargetGroup row">
          <button
            id="saveTargetBtn"
            onClick={() => this.deleteInfo()}
            className="droid-btn audience-save-btn shadow col-10 align-self-center"
          >
            <span className="audience-btn-text">
              <FormattedMessage
                id="Audience.menu.newAudience"
                defaultMessage="Neue Empfängergruppe definieren"
              />
            </span>
            <i className="fas fa-plus" />
          </button>
        </div>
        <div className="side-menu-search row">
          <div className="col align-self-center no-gutters">
            <div className="title row align-self-start">
              <FormattedMessage
                id="Audience.menu.searchAudience"
                defaultMessage="Empfängergruppe suchen"
              />
            </div>
            <div className="row align-self-start justify-content-between input">
              <div className="row no-gutters">
                <div className="col-11">
                  <input
                    placeholder={messages['Audience.nameInput']}
                    type="text"
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
                <div className="col-1">
                  <i
                    className="icon-color fas fa-pencil-alt align-self-center"
                    style={{ position: 'relative', top: '5px' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <ul className="search-result col">{this.state.searchResult}</ul>
        </div>
        <div className="side-menu-target-list row">
          <div className="col">
            <div className="target-list title row align-self-start">
              <FormattedMessage
                id="Audience.menu.definedAudiences"
                defaultMessage="Ihre definierten Empfängergruppen"
              />:</div>
            <ul className="search-result col">{this.state.targetList ? this.state.targetList : <span>
              <FormattedMessage
                id="Audience.menu.loadingAudiences"
                defaultMessage="Laden"
              />...</span>}</ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Audience;