import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import CircularProgressbar from 'react-circular-progressbar';

import { DataLineChart } from '../Data/Charts/prototypeCharts';

import { redish, messages, decimalAdjust, numberWithComma, numberWithPoints } from '../../reusableFunctions';

function getCentValue(value) {
  let string = value.toString();
  let stringLength = string.length;
  let newString = string.slice(0, (stringLength - 3)) + ',' + string.substring((stringLength - 2));
  return newString;
}

class DeliveryChart extends Component {

  tkp = '0.90';
  proEinheit = '0.00090';

  getChannelName = (channel) => {
    let channelName = '';
    switch (channel) {
      case 'newsletter':
        channelName = <FormattedMessage id="Delivery.channelName.newsletter" defaultMessage="Newsletter" />;
        break;
      case 'print':
        channelName = <FormattedMessage id="Delivery.channelName.print" defaultMessage="Direct-2-Print" />;
        break;
      case 'sms':
        channelName = <FormattedMessage id="Delivery.channelName.sms" defaultMessage="SMS" />;
        break;
      case 'banner':
        channelName = <FormattedMessage id="Delivery.channelName.banner" defaultMessage="Banner" />;
        break;
      default:
        channelName = <FormattedMessage id="Delivery.channelName.delivery" defaultMessage="Delivery" />;
    }
    return channelName;
  }

  componentDidMount = () => {
    let percentage = (Number(this.props.overview.spend_from_volume) / Number(this.props.overview.volume) * 100);
    percentage = percentage.toFixed(2);

    if (Number(percentage) > 90) { this.openModal(); }
  }

  openModal = () => {
    confirmAlert({
      title: messages['Delivery.error.quotaAlertTitle'],
      message: messages['Delivery.error.quotaAlertMessage'] + ':',
      buttons: [
        {
          label: messages['Delivery.error.quotaAlertContact'],
          onClick: () => {
            window.location.href = "mailto:beep@droidmarketing.com?subject=Neues Kontingent kaufen";
          }
        },
        {
          label: messages['Delivery.error.quotaAlertClose'],
          onClick: () => { return false; }
        }
      ]
    })
  }

  noticePeriodValue = 3

  render() {
    let percentage = (Number(this.props.overview.spend_from_volume) / Number(this.props.overview.volume) * 100);
    percentage = percentage.toFixed(2);
    return (
      <div className="row data-charts-single">
        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-4">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="overview-box">
                <h4 className="pb-5 pt-3">
                  <FormattedMessage
                    id="Delivery.deliveredChannelName"
                    defaultMessage="Zugestellte {channelname}"
                    values={{
                      channelname: this.getChannelName(this.props.channel)
                    }}
                  />
                </h4>
                <div className="chart-wrapper chart-wrapper-progress-circle-delivery">
                  <div className="progress-circle">
                    <CircularProgressbar
                      percentage={percentage}
                      text={`${percentage}%`}
                      initialAnimation={true}
                    />
                  </div>
                </div>
                <div className="overview-box-middle">
                  <strong>
                    {numberWithPoints(this.props.overview.spend_from_volume)} / {numberWithPoints(this.props.overview.volume)}
                  </strong>
                </div>
                <div className="overview-box-bottom">
                  <strong>€ {
                    numberWithComma(decimalAdjust('floor', (this.props.overview.spend_from_volume * this.props.overview.costs), -2))
                  }</strong><br />
                  <FormattedMessage
                    id="Delivery.costsWithinConractDuration"
                    defaultMessage="Kosten der zugestellten {channelname} innerhalb der Vertragslaufzeit"
                    values={{
                      channelname: this.getChannelName(this.props.channel)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="overview-box col-md-12">
                <h4 className="pb-5 pt-3">
                  <FormattedMessage
                    id="Delivery.conditionsOfContract"
                    defaultMessage="Vertragsbedingungen"
                  />
                </h4>
                <div className="row text-left tabular-info">
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.name"
                        defaultMessage="Name"
                      />
                    </strong>
                    <br />
                    <span>{this.props.overview.name}</span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.commitmentPeriod"
                        defaultMessage="Bindungsdauer"
                      />
                    </strong>
                    <br />
                    <span>
                      <FormattedMessage
                        id="Delivery.contractDuration"
                        defaultMessage="{contractDuration} Monate"
                        values={{
                          contractDuration: this.props.overview.contract_duration
                        }}
                      />
                    </span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.noticePeriod"
                        defaultMessage="Kündigungsfrist"
                      />
                    </strong>
                    <br />
                    <span>
                      <FormattedMessage
                        id="Delivery.noticePeriodValue"
                        defaultMessage="{noticePeriodValue} Monate"
                        values={{
                          noticePeriodValue: this.noticePeriodValue
                        }}
                      />
                    </span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.contractType"
                        defaultMessage="Vertragsstart"
                      />
                    </strong>
                    <br />
                    <span>{this.props.overview.contract_start}</span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.expirationContract"
                        defaultMessage="Vertragsende"
                      />
                    </strong>
                    <br />
                    <span>{this.props.overview.contract_end}</span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.volume"
                        defaultMessage="Volumen"
                      />
                    </strong>
                    <br />
                    <span>{numberWithPoints(this.props.overview.volume)}</span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.costsPerUnit"
                        defaultMessage="Kosten / Einheit"
                      />
                    </strong>
                    <br />
                    <span>{this.props.overview.costs} €</span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.cpt"
                        defaultMessage="TKP"
                      />
                    </strong>
                    <br />
                    <span>{this.props.overview.tkp}</span>
                  </div>
                  <div className="col-4 pb-4">
                    <strong>
                      <FormattedMessage
                        id="Delivery.totalPrice"
                        defaultMessage="Gesamtpreis"
                      />
                    </strong>
                    <br />
                    <span>{getCentValue(this.props.overview.total_costs)} €</span>
                  </div>
                </div>
                <div className="overview-box-middle">
                  <strong>
                    <FormattedMessage
                      id="Delivery.percentageOfVolume"
                      defaultMessage="{percentage}% des Gesamtvolumens"
                      values={{
                        percentage: percentage
                      }}
                    />
                  </strong>
                </div>
                <div className="overview-box-bottom">
                  <strong>{numberWithPoints(this.props.overview.volume)}</strong><br />
                  <span>
                    <FormattedMessage
                      id="Delivery.channelNameTotalVolume"
                      defaultMessage="{channelName} Gesamtvolumen"
                      values={{
                        channelName: this.getChannelName(this.props.channel)
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-lg-8 col-xl-8 delivery-background">
          <DataLineChart
            labels={[this.props.stats.labels]}
            names={[messages['Delivery.Sent']]}
            color={redish}
            data={[this.props.stats.data]}
          />
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default DeliveryChart;
