import React, { Component } from 'react';


import { Transition, config } from 'react-spring'

import { NavLink } from 'react-router-dom';

import { getElementWidth, getElementHeight } from '../../reusableFunctions';

import './Spider.css';
import SVGLogo from './pictures/logo_login.svg';

class Spider extends Component {

  render() {
    return (
      <div className="droid-module spider">
        <div className="inner-spider">
          <SpiderAnimation />
        </div>
        <Lines />
      </div>
    )
  }
}

class SpiderAnimation extends Component {

  state = { items: ['center', 'data'] }

  componentWillMount() {
    this.startAnimation();
  }

  componentDidMount = () => {
    window.onresize = function () {
      let hash = window.location.hash;
      if (hash === '#/' && localStorage.getItem('token')) {
        SpiderAnimation.adjustLinesAfterResize();
      }
    };
  }

  startAnimation = () => {
    setTimeout(() => this.setState({ items: ['center', 'data', 'audience'] }), 100)
    setTimeout(() => this.setState({ items: ['center', 'data', 'audience', 'journey'] }), 200)
    setTimeout(() => this.setState({ items: ['center', 'data', 'audience', 'journey', 'creator'] }), 300)
    setTimeout(() => this.setState({ items: ['center', 'data', 'audience', 'journey', 'creator', 'delivery'] }), 400)
    setTimeout(() => this.setState({ items: ['center', 'data', 'audience', 'journey', 'creator', 'delivery', 'reports'] }), 500)
  }

  adjustLines = (item) => {
    document.querySelector('.item.item-' + item).style.transform = 'unset';
    if (item !== 'center') {

      var line = document.getElementById(item + '-line');
      var div1 = document.getElementById('single-spider-bubble-' + item);
      var div2 = document.getElementById('droid-spider-center');


      var x1 = div1.getBoundingClientRect().left + (getElementWidth(div1) / 2);
      var y1 = div1.getBoundingClientRect().top + (getElementHeight(div1) / 2);
      var x2 = div2.getBoundingClientRect().left + (getElementWidth(div2) / 2);
      var y2 = div2.getBoundingClientRect().top + (getElementHeight(div2) / 2);

      if (item === 'audience' || item === 'delivery') { // line fix, for now
        x1 = x1 + 30;
      }


      line.setAttribute('x1', x1);
      line.setAttribute('y1', y1);
      line.setAttribute('x2', x2);
      line.setAttribute('y2', y2);
    }
  }

  static adjustLinesAfterResize = () => {
    let items = ['data', 'audience', 'journey', 'creator', 'delivery', 'reports', 'center'];

    for (let i in items) {
      document.querySelector('.item.item-' + items[i]).style.transform = 'unset';
      if (items[i] !== 'center') {
        var line = document.getElementById(items[i] + '-line');
        var div1 = document.getElementById('single-spider-bubble-' + items[i]);
        var div2 = document.getElementById('droid-spider-center');

        var x1 = div1.getBoundingClientRect().left + (getElementWidth(div1) / 2);
        var y1 = div1.getBoundingClientRect().top + (getElementHeight(div1) / 2);
        var x2 = div2.getBoundingClientRect().left + (getElementWidth(div2) / 2);
        var y2 = div2.getBoundingClientRect().top + (getElementHeight(div2) / 2);

        if (items[i] === 'audience' || items[i] === 'delivery') { // line fix, for now
          x1 = x1 + 30;
        }

        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);
      }
    }
  }

  render() {
    return (
      <div>
        <Transition
          items={this.state.items}
          from={{ position: 'absolute', opacity: 0, transform: 'translate3d(0,-800px,0)' }}
          enter={[{ opacity: 1, height: '100vh', width: '100vw' }, { transform: 'translate3d(0,0px,0)' }]}
          config={{ ...config.default, precision: 0.01 }}
          onRest={item => this.adjustLines(item)}
        >
          {item => props => <div style={props} className={"item item-" + item}><Bubble kind={item} /></div>}
        </Transition>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


class Bubble extends Component {
  render() {
    let returnBubble = false;
    switch (this.props.kind) {
      case 'data':
        returnBubble = <NavLink
          className="bubble data-connector-bubble nav_data"
          id="single-spider-bubble-data"
          activeClassName="active"
          to={{ pathname: '/data' }}>
          <span className="uppercase bold">Data</span>
        </NavLink>
        break;
      case 'audience':
        returnBubble = <NavLink
          className="bubble evaluation-connector-bubble nav_evaluation"
          id="single-spider-bubble-audience"
          activeClassName="active"
          to="/audience" >
          <span className="uppercase bold">Audience</span>
        </NavLink>
        break;
      case 'journey':
        returnBubble = <NavLink
          className="single-spider-bubble bubble journey-connector-bubble nav_journey"
          id="single-spider-bubble-journey"
          activeClassName="active"
          to="/journey">
          <span className="uppercase bold">Journey</span>
        </NavLink>
        break;
      case 'creator':
        returnBubble = <a
          id="single-spider-bubble-creator"
          href="https://creator.droidmarketing.com"
          target="_blank"
          rel="noopener noreferrer"
          className="bubble creator-connector-bubble nav_creator"
        >
          <span className="uppercase bold">Creator</span>
        </a>;
        break;
      case 'delivery':
        returnBubble = <NavLink
          className="single-spider-bubble bubble delivering-connector-bubble nav_deliver"
          id="single-spider-bubble-delivery"
          activeClassName="active"
          to="/delivery/newsletter">
          <span className="uppercase bold">Delivery</span>
        </NavLink>
        break;
      case 'reports':
        returnBubble = <NavLink
          className="single-spider-bubble bubble controlling-connector-bubble nav_controlling"
          id="single-spider-bubble-reports"
          activeClassName="active"
          to="/reports/allgemeine_werte">
          <span className="uppercase bold">Reports</span>
        </NavLink>
        break;
      case 'center':
        returnBubble = <NavLink
          className="droid-spider-center"
          id="droid-spider-center"
          activeClassName="active"
          to="/connector/website">
          <img id="droid-spider-center-img" src={SVGLogo} alt="droid-logo" />
        </NavLink>
        break;
      default:
        returnBubble = false;
        break;
    }
    return (
      <div className="spider-bubble">{returnBubble}</div>
    )
  }
}

const Lines = () => {
  return (
    <div id="line-wrapper">
      <svg>
        <line className="spider-line" id="data-line" />
        <line className="spider-line" id="audience-line" />
        <line className="spider-line" id="journey-line" />
        <line className="spider-line" id="creator-line" />
        <line className="spider-line" id="delivery-line" />
        <line className="spider-line" id="reports-line" />
      </svg>
    </div>
  )
}

export default Spider;