import React, { Component } from 'react';

import Checkbox from 'rc-checkbox';
import { Droppable, Draggable } from 'react-beautiful-dnd'; // Dropable resources

// import Droid Stuff
import { Audience } from './Audience'; // to use the static function inside the Audience Class
import { getKeys, removeArrayValue, numberWithPoints, apiPath, globalErrorHandler } from '../../reusableFunctions';

export function getCount(updateState, query) {

  Audience.loadingCount(true);

  for (let i in cardValues) { // for operators < >
    if (cardValues[i][0] && typeof cardValues[i][0] === 'string') {
      if (cardValues[i][0].includes("<") || cardValues[i][0].includes(">")) {
        cardValues[i][0] = JSON.parse(cardValues[i][0]);
      }
    }
  }

  let data = JSON.stringify(cardValues);
  if (query) { data = query; }

  let postMethods = {
    method: 'POST', mode: 'cors', body: data, headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  };

  if (data !== '{}') {
    fetch(apiPath + 'audience/count', postMethods)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status === "success") {
          let payload = numberWithPoints(data.payload);
          updateState('count', payload);
        } else {
          updateState('count', 0);
        }
      })
      .catch(function (error) {
        globalErrorHandler(error);
        updateState('count', 0);
      });

    for (let i in cardValues) { // for operators < >
      // if (typeof cardValues[i][0] === 'object') {
      if (typeof cardValues[i][0] === 'string' && (cardValues[i][0].includes("<") || cardValues[i][0].includes(">"))) {
        cardValues[i][0] = JSON.stringify(cardValues[i][0]);
      }
    }
  } else {
    updateState('count', 0);
  }
}

const InnerCardBody = (props) => {

  let query = props.tick.targetGroupQuery;
  let queryLength = Object.keys(query).length;
  let urlquery = false;

  const regex = /\["|"]/gm;

  if (queryLength > 0) {
    if ("urls" in query) {
      if (Array.isArray(query.urls)) {
        urlquery = query.urls[0];
      } else {
        urlquery = query.urls.replace(regex, '');
      }
    }
    if ("input" in query || query.hasOwnProperty('input')) {
      urlquery = [];
      if (typeof query.input === 'string') {
        query.input = JSON.parse(query.input);
        for (let i in query.input) {
          urlquery[query.input[i].field[0]] = query.input[i].value[0];
        }
      }
    }
  }

  let personalized = props.personalized;
  let cardName = props.cardName;
  let cardSelection = props.cardSelection
  const updateState = props.updateState;

  for (let i in cardSelection) { // for operators < >
    if (typeof cardSelection[i].id === 'object') {
      cardSelection[i].id = JSON.stringify(cardSelection[i].id);
    }
  }

  let InnerCardBodySplit = cardSelection.map((selection) => {

    let special = false;
    if (selection.query === 'anonym' || selection.query === 'total_newsletters') {
      special = true;
    }

    return (
      <div
        key={selection.name}
        id={selection.query.replace(/\s/g, '') + '-' + selection.id.replace(/\s/g, '')}
      >
        <div
          className="single-selection input-group mb-3"
          id={special ? ('special-' + selection.query) : undefined}
        >
          <label>
            <div className="input-group-prepend">
              <div className="input-group-text">
                <ProtoCheckbox
                  selection={selection}
                  cardname={cardName}
                  personalized={personalized}
                  updateState={props.updateState}
                  tick={props.tick}
                />
              </div>
              <span className="selection-name">{selection.name}</span>
              {(selection.id === 'inputurls' || selection.id === 'input') ?
                <div>
                  {(selection.name === "Seite aufgerufen") ?
                    <div>
                      {urlquery && !typeof urlquery === 'object' ?
                        <input id={selection.id} value={urlquery} disabled className="audience-selection-input"></input>
                        :
                        <input id={selection.id} placeholder="https://" className="audience-selection-input"
                          onChange={(e) => { createCardsObject(cardName, selection, (e.target.value.length === 0) ? false : true, updateState) }}>
                        </input>
                      }
                    </div>
                    :
                    <div>
                      {selection.id === 'input' ?
                        <div>
                          {urlquery && typeof urlquery === 'object' ?
                            <input id={selection.id} style={{ 'marginLeft': 'unset', 'marginTop': 'unset' }} value={urlquery[selection.query]} disabled className="audience-selection-input"></input>
                            :
                            <input
                              id={selection.query}
                              className="audience-selection-input"
                              style={{ 'marginLeft': 'unset', 'marginTop': 'unset' }}
                              placeholder="Abfrage"
                            // onChange={(e) => { createCardsObject(cardName, selection, (e.target.value.length === 0) ? false : true, updateState) }}
                            ></input>
                          }
                        </div>
                        : null}
                    </div>
                  }
                </div>
                :
                null}
            </div>
          </label>
        </div>
      </div>
    )
  });
  return <div>{InnerCardBodySplit}</div>;
};

const CardHead = (props) => {

  const longname = props.longname;

  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <span>{longname}</span>
        </div>
      </div>
    </div>
  );

}

const CardBody = (props) => {

  const personalized = props.personalized;
  const cardName = props.cardName;
  const cardSelection = props.cardSelection;

  return (
    <div className="card-inner-body">
      <InnerCardBody
        cardName={cardName}
        cardSelection={cardSelection}
        personalized={personalized}
        updateState={props.updateState}
        tick={props.tick}
      />
    </div>
  );
}

function CreateCardsDraggable(props) {

  let personalized = props.personalized;
  let cardSelections = props.cardSelections;
  let cardLongnames = props.cardLongnames;
  let cardNames = props.cardNames;
  let tick = props.tick
  let cardsKeys = getKeys(cardSelections);


  const CardsDraggable = cardsKeys.map((card) =>
    <Droppable droppableId={card.toString()}
      index={card}
      key={card.toString()}
      direction="horizontal">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <Draggable
            key={card.toString()}
            draggableId={card.toString()}
            index={card}
            direction="horizontal"
            isDragDisabled={true}
          >
            {(provided, snapshot) => (
              <div className="col card-body shadow"
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <div className="card-head"
                  {...provided.dragHandleProps}
                >
                  <CardHead
                    longname={cardLongnames[card]} // single long name of the card
                    cardname={cardNames[card]} // single short name of the card
                  />
                </div>
                <CardBody
                  value={card.toString()}
                  cardLongname={cardLongnames[card]} // single long name of the card
                  cardName={cardNames[card]} // single short name of the card
                  cardSelection={cardSelections[card]} // single selection of the card (checkboxes)
                  personalized={personalized}
                  updateState={props.updateState}
                  tick={tick}
                />
              </div>
            )}
          </Draggable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <div className="row">{CardsDraggable}</div>
  );

}

export class CardBoard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      personalized: this.props.personalized,
      tick: this.props.tick
    };
  };

  render() {
    return (
      <CreateCardsDraggable
        cardSelections={this.props.cardSelections}
        cardLongnames={this.props.cardLongnames}
        cardNames={this.props.cardNames}
        personalized={this.props.personalized}
        updateState={this.props.updateState}
        tick={this.props.tick}
      />
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

function cardLogic(cardname, selectionName, Checkbox, personalized) {

  // let usertypes = cardValues.hasOwnProperty('usertypes');

  Checkbox.setState({ disabled: false });// set to init state

  /**** For development commented out - not delete ****/

  // if (personalized) {
  //   if (cardname === 'usertypes') {
  //     if (selectionName === 'Marktpotenzial' || selectionName === 'Anonyme Interessenten') {
  //       Checkbox.setState({
  //         disabled: true
  //       });
  //     }
  //   } else {
  //     if (usertypes) {
  //       Checkbox.setState({
  //         disabled: false
  //       });
  //     } else {
  //       Checkbox.setState({
  //         disabled: true
  //       });
  //     }
  //   }
  // } else {
  //   if (cardname === 'usertypes') {
  //     if (selectionName === 'Bekannte Interessenten' || selectionName === 'Neukunden' ||
  //       selectionName === 'Bestehende Kunden' || selectionName === 'Ex-Kunden' ||
  //       selectionName === 'Newsletter Opt-In' || selectionName === 'Test User') {
  //       Checkbox.setState({
  //         disabled: true
  //       });
  //     }
  //   } else {
  //     if (usertypes) {
  //       Checkbox.setState({
  //         disabled: false
  //       });
  //     } else {
  //       Checkbox.setState({
  //         disabled: true
  //       });
  //     }
  //   }
  // }

}

export let cardValues = {};
function createCardsObject(cardname, selection, checked, updateState) {

  let query = selection.query;
  let id = selection.id;

  let disabled = false; // dont let select anonym and known users
  if (query === 'anonym') {
    disabled = document.querySelector('#special-total_newsletters');
  } else if (query === 'total_newsletters') {
    disabled = document.querySelector('#special-anonym');
  }
  if (disabled) {
    if (checked) {
      disabled.classList.toggle('disabled', true);
    } else {
      disabled.classList.toggle('disabled', false);
    }
  }

  if (checked === true) { // if checkbox is checked

    if (cardValues[query]) { // if array already exists
      cardValues[query].push(id);
    } else { // if not, create Array
      cardValues[query] = [id];
    }

    if (selection.id === 'input') {

      let input = document.getElementById(query);
      if (checked) {
        // cardValues[query] = [input.value];
        delete cardValues[query];

        if (!cardValues['input']) {
          cardValues['input'] = [{ 'field': [query], 'value': [input.value] }];
        } else {
          cardValues['input'].push({ 'field': [query], 'value': [input.value] });
        }

      } else {
        delete cardValues[query];
      }
    }

  } else { // if checkbox is unchecked
    if (!selection.id === 'input') {
      removeArrayValue(cardValues[query], id);
      if (cardValues[query]) {
        if (cardValues[query].length === 0) { // if last item in Array is deleted
          delete cardValues[query];
        }
      }
    } else {
      delete cardValues[query];
    }
  }
  if (cardValues[query]) {
    if (selection.query === 'urls') {
      if (selection.id === 'inputurls') {
        let input = document.getElementById('inputurls');
        if (checked) {
          cardValues[query] = [input.value];
        } else {
          delete cardValues[query];
        }
      }
    }

  }
  getCount(updateState);
}

class ProtoCheckbox extends Component {

  selectionName = this.props.selection.name;
  cardname = this.props.cardname;
  selectionNum = this.props.selection.id;
  selection = this.props.selection;

  state = {
    disabled: false,
    personalized: true,
    usertypes: false
  };

  componentWillReceiveProps = () => {
    cardLogic(this.cardname, this.selectionName, this, this.props.personalized);
  }

  componentDidUpdate = () => {
    if (this.state.personalized !== this.props.personalized) {
      this.setState({ personalized: this.props.personalized });
      cardLogic(this.cardname, this.selectionName, this, this.props.personalized);
    }
  }

  componentWillMount = () => {
    cardLogic(this.cardname, this.selectionName, this, this.props.personalized);
  }

  toggle = () => {
    this.setState((state) => ({ disabled: !state.disabled }));
  }

  onChange = (e) => {
    createCardsObject(this.cardname, this.selection, e.target.checked, this.props.updateState); // new count
    this.props.updateState('personalized', this.props.personalized);
  }

  render() {
    let tick = this.props.tick.targetGroupQuery;
    let query = this.selection.query;
    let id = this.selection.id;

    for (let i in tick) { // for operators < >
      if (typeof tick[i] === 'object') {
        tick[i] = JSON.stringify(tick[i]);
      }
    }

    if (Object.keys(tick).length !== 0) {
      if (tick[query] !== undefined) {
        if (tick[query].includes(id) || (tick.hasOwnProperty('urls') && id === 'inputurls')) {
          return (
            <Checkbox
              name="my-checkbox"
              disabled={this.state.disabled}
              checked={1}
            />
          );
        } else {
          return (
            <Checkbox
              name="my-checkbox"
              disabled={this.state.disabled}
              checked={0}
            />
          );
        }
      } else {
        return (
          <Checkbox
            name="my-checkbox"
            disabled={this.state.disabled}
            checked={0}
          />
        );
      }
    }

    return (
      <Checkbox
        name="my-checkbox"
        onChange={this.onChange}
        disabled={this.state.disabled}
      />
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}