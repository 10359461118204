import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import Select from 'react-select';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RetargetingBannerSelects from './RetargetingBannerSelect';
import DirectPrintUpload from './DirectPrintUpload';

import {
  apiPath,
  globalErrorHandler,
  messages,
  isLocalhost,
  selectTheme
} from '../../reusableFunctions';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faMobile, faAt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope, faMobile, faAt, faPaperPlane);

let channelSelectInputHeader = <FormattedMessage id="Journey.builder.channel.selectInputHeader" defaultMessage="Kommunikations-Channel" />;
// let decisionSelectInputHeader = <FormattedMessage id="Journey.builder.decision.selectInputHeader" defaultMessage="Decision" />;
let decisionDelayHeaderText = <FormattedMessage id="Journey.builder.decision.delayHeaderText" defaultMessage="Verzögerung" />;

let notifyChannelText = <FormattedMessage id="Journey.builder.notify.channelText" defaultMessage="Bitte wählen Sie einen Channel" />;
let notifyTemplateText = <FormattedMessage id="Journey.builder.notify.templateText" defaultMessage="Bitte wählen Sie ein Template" />;
let notifyDecisionText = <FormattedMessage id="Journey.builder.notify.decisionText" defaultMessage="Bitte wählen Sie eine Decision" />;
let notifyNoDecisionText = <FormattedMessage id="Journey.builder.notify.noDecisionText" defaultMessage="Keine Decision verfügbar für gewählten Channel" />;

const journeyChannels = [
  { type: 'channel', disabled: false, value: 'newsletter', label: messages['Journey.builder.channel.availableChannels.newsletter'] },
  { type: 'channel', disabled: false, value: 'banner', label: messages['Journey.builder.channel.availableChannels.banner'] },
  // { type: 'channel', disabled: false, value: 'retargetingbanner', label: messages['Journey.builder.channel.availableChannels.retargetingBanner'] },
  // { type: 'channel', disabled: false, value: 'facebook', label: 'Facebook (Beta)' },
  { type: 'channel', disabled: false, value: 'sms', label: messages['Journey.builder.channel.availableChannels.sms'] + ' (Beta)' },
  { type: 'channel', disabled: false, value: 'print', label: messages['Journey.builder.channel.availableChannels.print'] },
  { type: 'channel', disabled: false, value: 'confirmOptin', label: 'Double Optin Newsletter' }
];

const journeyDecisions = [
  { kind: 'newsletter', type: 'decision', value: 'click', label: messages['Journey.builder.decisionOptions.click'] },
  { kind: 'newsletter', type: 'decision', value: 'open', label: messages['Journey.builder.decisionOptions.open'] },
  { kind: 'newsletter', type: 'decision', value: 'not_open', label: messages['Journey.builder.decisionOptions.not_open'] },
  { kind: 'newsletter', type: 'decision', value: 'any', label: 'Any' },
  { kind: 'banner', type: 'decision', value: 'open', label: messages['Journey.builder.decisionOptions.seen'] },
  { kind: 'banner', type: 'decision', value: 'click', label: messages['Journey.builder.decisionOptions.clicked'] },
  { kind: 'retargetingbanner', type: 'decision', value: 'open', label: messages['Journey.builder.decisionOptions.seen'] },
  { kind: 'retargetingbanner', type: 'decision', value: 'click', label: messages['Journey.builder.decisionOptions.clicked'] },
  { kind: 'confirmOptin', type: 'decision', value: 'any', label: 'Any' },
];

export function notifyChannel() {
  toast(notifyChannelText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  })
};

export function notifyTemplate() {
  toast(notifyTemplateText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  })
};

function getFullChannelName(short) {
  let name = '';
  switch (short) {
    case 'nl':
    case 'newsletter':
      name = <FormattedMessage id="Journey.builder.channel.availableChannels.newsletter" defaultMessage="Newsletter" />;
      break;
    case 'banner':
      name = <FormattedMessage id="Journey.builder.channel.availableChannels.banner" defaultMessage="Banner" />;
      break;
    case 'retargetingbanner':
      name = <FormattedMessage id="Journey.builder.channel.availableChannels.retargetingBanner" defaultMessage="Retargeting Banner" />;
      break;
    case 'd2p':
    case 'print':
      name = <FormattedMessage id="Journey.builder.channel.availableChannels.print" defaultMessage="Direct-2-Print" />;
      break;
    case 'sms':
      name = <FormattedMessage id="Journey.builder.channel.availableChannels.sms" defaultMessage="SMS" />;
      break;
    case 'facebook':
      name = 'Facebook (Beta)';
      break;
    case 'confirmOptin':
      name = 'Double Optin Newsletter';
      break;
    default:
      name = <FormattedMessage id="Journey.builder.channel.selectInputHeader" defaultMessage="Kommunikations-Channel" />;
      break;
  }
  return name;
}

export class JourneyBuilder extends Component {

  createJourney = this.props.createJourney;
  selectedJourney = this.props.selectedJourney;

  notifyTemplate = () => toast(notifyTemplateText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });

  notifyDecision = () => toast(notifyDecisionText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });

  notifyNoDecision = () => toast(notifyNoDecisionText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });

  numbers = [
    { type: 'delay-time', value: 0, label: 0 },
    { type: 'delay-time', value: 1, label: 1 },
    { type: 'delay-time', value: 2, label: 2 },
    { type: 'delay-time', value: 3, label: 3 },
    { type: 'delay-time', value: 4, label: 4 },
    { type: 'delay-time', value: 5, label: 5 },
    { type: 'delay-time', value: 6, label: 6 },
    { type: 'delay-time', value: 7, label: 7 },
    { type: 'delay-time', value: 8, label: 8 },
    { type: 'delay-time', value: 9, label: 9 },
    { type: 'delay-time', value: 10, label: 10 },
    { type: 'delay-time', value: 11, label: 11 },
    { type: 'delay-time', value: 12, label: 12 },
    { type: 'delay-time', value: 48, label: 48 }
  ]

  hourDayOptions = [
    { type: 'delay-options', value: 'hours', label: messages['Journey.builder.decision.selectHours'] },
    { type: 'delay-options', value: 'days', label: messages['Journey.builder.decision.selectDays'] },
  ]

  constructor(props) {
    super(props);
    this.state = {
      availableChannels: journeyChannels,
      allTemplates: [],
      templateInfo: false,
      availableTemplates: [],
      allDecisionOptions: journeyDecisions,
      availableDecisionOptions: [],
      channels: [], // save selected channels and templates
      decisions: {},
      states: { // save the states for the front-end
        0: { channel: true, decision: false },
        1: { channel: false, decision: false },
        2: { channel: false, decision: false },
        3: { channel: false, decision: false },
        4: { channel: false, decision: false }
      },
      selectedJourneyBuilder: false,
      selectedJourneyId: 0,
      smsText: 'Text',
      smsTextLength: 0,
      personCount: 0,
      selectedTargetgroup: false,
      abTest: false
    };
  };

  componentDidMount = () => {
    this.getTemplates();
    // this.checkAvailabilityChannel();
  }

  createNewSelectObject(object, kind, type, value, label) {
    let add = { kind: kind, type: type, value: value, label: label };
    object.push(add);
  }

  checkAvailabilityChannel = () => {
    const originalChannels = journeyChannels;

    let newsletterChannel, bannerChannel, retargetingbannerChannel, smsChannel, printChannel;
    let availableChannels = originalChannels;

    // for (let i in availableChannels) {
    //   if (availableChannels[i].value !== 'retargetingbanner') {
    //     checkChannel(availableChannels[i].value)
    //       .then(function () {
    //         disableEnableChannel(availableChannels[i].value, false);
    //       })
    //       .catch(function () {
    //         disableEnableChannel(availableChannels[i].value, true);
    //       });
    //   }
    // }

    function checkChannel(channel) {
      return new Promise(function (resolve, rejected) {
        fetch(apiPath + 'delivery/' + channel, {
          method: 'GET',
          dataType: 'json',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          resolve('success');
        }).catch(function (error) {
          rejected('error');
        });
      });
    }

    function disableEnableChannel(channel, disabled) {
      switch (channel) {
        case 'newsletter':
          newsletterChannel = availableChannels[0];
          if (disabled) {
            newsletterChannel.disabled = true;
          } else {
            newsletterChannel.disabled = false;
          }
          availableChannels[0] = newsletterChannel;
          break;
        case 'sms':
          smsChannel = availableChannels[4];
          if (disabled) {
            smsChannel.disabled = true;
          } else {
            smsChannel.disabled = false;
          }
          availableChannels[4] = smsChannel;
          break;
        case 'banner':
          bannerChannel = availableChannels[1];
          retargetingbannerChannel = availableChannels[2];
          if (disabled) {
            bannerChannel.disabled = true;
            retargetingbannerChannel.disabled = true;
          } else {
            bannerChannel.disabled = false;
            retargetingbannerChannel.disabled = false;
          }
          availableChannels[1] = bannerChannel;
          availableChannels[2] = retargetingbannerChannel;
          break;
        case 'print':
          printChannel = availableChannels[5];
          if (disabled) {
            printChannel.disabled = true;
          } else {
            printChannel.disabled = false;
          }
          availableChannels[5] = printChannel;
          break;
        default:
          newPersonCount = this.props.personCount;
          break;
      }
    }
    this.setState({
      availableChannels: availableChannels
    });
  }

  getTemplates = () => {
    let intermediateTemplates = [];
    for (let i in this.state.availableChannels) {
      let that = this;
      fetch(apiPath + 'templates/' + this.state.availableChannels[i].value, {
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function (response) {
        return response.json()
      }).then(function (data) {
        for (let i in data) {
          if (data[i].type.includes('banner')) {
            data[i].name = data[i].name + (data[i].size ? ' - ' + data[i].size + 'px' : '');
          }
          that.createNewSelectObject(intermediateTemplates, data[i].type, 'template', data[i].id, data[i].name);
        }
      }).catch(function (error) {
        globalErrorHandler(error);
      });
    }
    this.setState({ allTemplates: intermediateTemplates });
  }

  templatePreview = (id, channel) => {
    fetch(apiPath + 'templates/' + channel, {
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      for (let i in data) {
        if (data[i].id === id) {
          window.open(data[i].preview_url, '_blank');
        }
      }
    }).catch(function (error) {
      globalErrorHandler(error);
    });
  }

  templatePreviewSelected = (name, channel) => {
    let templates = this.state.allTemplates;
    let result = [];

    for (let key in templates) {
      if (templates[key].label === name) {
        result.push(templates[key]);
      }
    }
    if (channel === 'Website Banner') { // @max
      channel = 'banner';
    }
    if (result[0]) {
      this.templatePreview(result[0].value, channel);
    }
  }


  returnChannel = (props) => { // Prototype Channel
    let num = props.num;
    let currentChannel = false;

    if (this.state.channels[num]) {
      currentChannel = this.state.channels[num].channel;
    } else {
      if (this.state.selectedJourneyBuilder) {
        if (this.state.selectedJourneyBuilder[num].channel) {
          currentChannel = this.state.selectedJourneyBuilder[num].channel;
        }
      }
    }

    let nextDecision = this.state.states[num].decision;
    let showClass = '';
    let showClassHandle = '';

    let channelTags = ['1', '2', '3', '4', '5'];

    if (nextDecision) {
      showClass = 'collapse';
      showClassHandle = 'collapsed';
    } else {
      showClass = 'collapse show';
      showClassHandle = '';
    }

    const getSmsTextValue = (e) => {
      this.getChange('template-sms', e, num);
      this.setState({
        smsText: e,
        smsTextLength: e.length
      });
    }

    const numbersChannel = (targetgroup) => {

      const originalChannels = journeyChannels;

      let newsletterChannel, bannerChannel, retargetingbannerChannel, smsChannel, printChannel;
      let availableChannels = originalChannels;
      let newPersonCount = 0;

      if (num === 0) {
        this.setState({
          availableChannels: availableChannels
        }, () => {
          for (let i in availableChannels) {
            switch (availableChannels[i].value) {
              case 'newsletter':
                newPersonCount = targetgroup.newsletter_count;
                newsletterChannel = availableChannels[0];
                newsletterChannel.label += " (" + newPersonCount + " erreichbare Personen)"; // @max
                if (newPersonCount === 0) {
                  newsletterChannel.disabled = true;
                } else {
                  newsletterChannel.disabled = false;
                }
                availableChannels[0] = newsletterChannel;
                break;
              case 'sms':
                newPersonCount = targetgroup.sms_count;
                smsChannel = availableChannels[2];
                smsChannel.label += " (" + newPersonCount + " erreichbare Personen)"; // @max
                if (newPersonCount === 0) {
                  smsChannel.disabled = true;
                } else {
                  smsChannel.disabled = false;
                }
                availableChannels[4] = smsChannel;
                break;
              case 'print':
                newPersonCount = targetgroup.print_count;
                printChannel = availableChannels[3];
                printChannel.label += " (" + newPersonCount + " erreichbare Personen)"; // @max
                if (newPersonCount === 0) {
                  printChannel.disabled = true;
                } else {
                  printChannel.disabled = false;
                }
                availableChannels[5] = printChannel;
                break;
              default:
                newPersonCount = this.props.personCount;
                break;
            }
          }
          this.setState({
            availableChannels: availableChannels,
            personCount: this.props.personCount
          });
        })
      }
    }

    if (this.props.selectedTargetgroup) {
      if (this.state.selectedTargetgroup !== this.props.selectedTargetgroup.id) {
        this.setState({
          selectedTargetgroup: this.props.selectedTargetgroup.id
        }, () => {
          // numbersChannel(this.props.selectedTargetgroup);
          this.getChange('channel', false, num);
        });
      }
    }

    return (
      <div className="journey-channel-select" >
        <a data-toggle="collapse" data-target={"#collapseChannel" + num} className={showClassHandle}>
          <div className="journey-channel-select-handle" onClick={() => this.collapse('collapseChannel' + num)}>
            <div className="channel-tag-wrapper">
              <span className="channel-tag">{channelTags[num]}</span>
            </div>
            <span>Channel </span>
            <i className="fas fa-chevron-up"></i>
            <i className="fas fa-chevron-down"></i>
          </div>
        </a>
        <div className={showClass} id={"collapseChannel" + num}>
          <div
            className={
              "journey-channel-select-body journey-channel-select-body-" +
              (currentChannel ? currentChannel :
                (this.state.selectedJourneyBuilder ? this.state.selectedJourneyBuilder[num].channel
                  : '')) + ' j' + num
            }
          >
            <span>{channelSelectInputHeader}:</span>
            {!this.state.selectedJourneyBuilder ?
              <div key={this.state.availableChannels[0].disabled}>
                <Select
                  id={"channel-" + num}
                  key={this.state.availableChannels[0].label}
                  theme={selectTheme}
                  classNamePrefix="journey-channel-select"
                  options={this.state.availableChannels}
                  isSearchable={false}
                  placeholder="Channel"
                  onChange={(e) => { this.getChange(e.type, e.value, num) }}
                  isOptionDisabled={(option) => option.disabled}
                />
                {(currentChannel === 'sms' || currentChannel === 'print' || currentChannel === 'confirmOptin') ?
                  <div>
                    {currentChannel === 'sms' ?
                      <div>
                        <input
                          id="journey-template-sms-input"
                          type="text"
                          onChange={(e) => getSmsTextValue(e.target.value)}
                          autoFocus
                          placeholder={this.state.smsText}
                          maxLength="160"
                        ></input>
                        <span id="journey-template-sms-count">{this.state.smsTextLength}/160</span>
                      </div>
                      :
                      currentChannel === 'print' ?
                        <DirectPrintUpload
                          getChange={this.getChange}
                          num={num}
                          selectTheme={selectTheme}
                        />
                        : null}
                  </div>
                  :
                  <div>
                    {(currentChannel === 'retargetingbanner') ?
                      <RetargetingBannerSelects
                        theme={selectTheme}
                        getChange={this.getChange}
                        num={num}
                      />
                      : null}
                    {(currentChannel === 'newsletter' && num === 0) ?
                      <div className="ab-test-check">
                        <label htmlFor="ab-test-checkbox">AB-Test (Beta)</label>
                        <input
                          onChange={(e) => this.setState({ abTest: e.target.checked })}
                          type="checkbox"
                          id="ab-test-checkbox"
                          name="ab-test-checkbox"
                        ></input>
                      </div>
                      : null}
                    {(currentChannel !== 'facebook') ?
                      <Select
                        id={"template-" + num}
                        onSelectResetsInput
                        theme={selectTheme}
                        classNamePrefix="journey-channel-select"
                        options={this.state.availableTemplates[num]}
                        isSearchable={true}
                        placeholder="Template"
                        onChange={(e) => { this.getChange(e.type, e.value, num) }}
                      />
                      : null}
                    {this.state.channels && this.state.channels[num] && this.state.channels[num].template_id ?
                      <button onClick={() => this.templatePreview(this.state.channels[num].template_id, this.state.channels[num].channel)} className="journey-template-preview">
                        <FormattedMessage
                          id="Journey.builder.channel.previewButton"
                          defaultMessage="Vorschau"
                        />
                      </button>
                      : null}
                    {(currentChannel === 'newsletter' && this.state.abTest && num === 0) ?
                      <div>
                        <Select
                          id={"template-ab-" + num}
                          onSelectResetsInput
                          theme={selectTheme}
                          classNamePrefix="journey-channel-select"
                          options={this.state.availableTemplates[num]}
                          isSearchable={true}
                          placeholder="Template 2"
                          onChange={(e) => { this.getChange('template-ab', e.value, num) }}
                        />
                        {this.state.channels && this.state.channels[num] && this.state.channels[num].template_ab_id ?
                          <button onClick={() => this.templatePreview(this.state.channels[num].template_ab_id, this.state.channels[num].channel)} className="journey-template-preview">
                            <FormattedMessage
                              id="Journey.builder.channel.previewButton"
                              defaultMessage="Vorschau"
                            />
                          </button>
                          : null}
                      </div>
                      : null}
                  </div>
                }
              </div>
              :
              <div>
                <Select
                  id={"channel-" + num}
                  theme={selectTheme}
                  classNamePrefix="journey-channel-select"
                  isDisabled={true}
                  placeholder={getFullChannelName(this.state.selectedJourneyBuilder[num].channel)}
                />
                {(this.state.selectedJourneyBuilder[num].channel === 'retargetingbanner' || this.state.selectedJourneyBuilder[num].channel === 'Retargeting Banner') ?
                  <RetargetingBannerSelects
                    selectedRetargetingOptions={this.state.selectedJourneyBuilder[num].retargetingOptions}
                    theme={selectTheme}
                    getChange={this.getChange}
                    num={num}
                  />
                  : null}
                <div>
                  {this.state.selectedJourneyBuilder[num].channel === 'sms' ?
                    <div>
                      <input
                        id="journey-template-sms-input"
                        type="text"
                        disabled
                        placeholder={this.state.selectedJourneyBuilder[num].template}
                      ></input>
                    </div>
                    : null}
                  {this.state.selectedJourneyBuilder[num].channel !== 'print' &&
                    this.state.selectedJourneyBuilder[num].channel !== 'sms' &&
                    this.state.selectedJourneyBuilder[num].channel !== 'facebook' &&
                    this.state.selectedJourneyBuilder[num].channel !== 'confirmOptin' ?
                    <div>
                      <div>
                        <Select
                          id={"template-" + num}
                          onSelectResetsInput
                          theme={selectTheme}
                          classNamePrefix="journey-channel-select"
                          isDisabled={true}
                          placeholder={this.state.selectedJourneyBuilder[num].template}
                        />
                        <button
                          onClick={() => this.templatePreviewSelected(this.state.selectedJourneyBuilder[num].template, this.state.selectedJourneyBuilder[num].channel)}
                          className="journey-template-preview"
                        >
                          <FormattedMessage
                            id="Journey.builder.previewButton"
                            defaultMessage="Vorschau"
                          />
                        </button>
                      </div>
                      {this.state.selectedJourneyBuilder[num].template_ab ?
                        <div>
                          <Select
                            id={"template-ab-" + num}
                            onSelectResetsInput
                            theme={selectTheme}
                            classNamePrefix="journey-channel-select"
                            isDisabled={true}
                            placeholder={this.state.selectedJourneyBuilder[num].template_ab}
                          />
                          <button
                            onClick={() => this.templatePreviewSelected(this.state.selectedJourneyBuilder[num].template_ab, this.state.selectedJourneyBuilder[num].channel)}
                            className="journey-template-preview"
                          >
                            <FormattedMessage
                              id="Journey.builder.previewButton"
                              defaultMessage="Vorschau"
                            />
                          </button>
                        </div>
                        : null}
                    </div>
                    : null}
                </div>
              </div>
            }
          </div>
        </div>
      </div >
    );
  }

  collapse = (target) => {
    let body = document.getElementById(target);
    let handle = document.querySelector('[data-target="#' + target + '"]');

    if (body.classList.contains('show')) {
      body.classList.remove('show');
      handle.classList.add('collapsed');
    } else {
      body.classList.add('show');
      handle.classList.remove('collapsed');
    }
  }

  returnDecision = (props) => { // Prototype Decision

    let num = props.num;
    let showClass = '';
    let showClassHandle = '';
    let nextChannel = this.state.states[num + 1].channel;

    const decisionTags = ['a', 'b', 'c', 'd', 'e'];

    if (nextChannel) {
      showClass = 'collapse';
      showClassHandle = 'collapsed';
    } else {
      showClass = 'collapse show';
      showClassHandle = '';
    }

    let channelName = <FormattedMessage id="Journey.builder.channel.channelName" defaultMessage="Channel" />;
    if (this.state.channels[num]) {
      channelName = getFullChannelName(this.state.channels[num].channel);
    }

    const getFullDecisionName = (short) => {
      let name = '';
      switch (short) {
        case 'not_open':
          name = messages['Journey.builder.decisionOptions.not_open'];
          break;
        case 'open':
          name = messages['Journey.builder.decisionOptions.open'];
          break;
        case 'click':
          name = messages['Journey.builder.decisionOptions.clicked'];
          break;
        case 'any':
          name = 'Any';
          break;
        default:
          name = short;
          break;
      }
      return name;
    }

    return (
      <div className="journey-channel-select">
        <a data-toggle="collapse" data-target={"#collapseDecision" + num} className={showClassHandle}>
          <div className="journey-channel-select-handle" onClick={() => this.collapse('collapseDecision' + num)}>
            <div className="decision-tag-wrapper">
              <span className="decision-tag">{decisionTags[num]}</span>
            </div>
            <span>
              <FormattedMessage
                id="Journey.builder.decision.decision"
                defaultMessage="Decision"
              />
            </span>
            <i className="fas fa-chevron-up"></i>
            <i className="fas fa-chevron-down"></i>
          </div>
        </a>
        <div className={showClass} id={"collapseDecision" + num}>
          {!this.state.selectedJourneyBuilder ?
            <div className="journey-channel-select-body">
              <span>{channelName}:</span>
              <Select
                id={"journey-decision-select-" + num}
                theme={selectTheme}
                classNamePrefix="journey-channel-select"
                options={this.state.availableDecisionOptions[num]}
                isSearchable={false}
                placeholder={<FormattedMessage
                  id="Journey.builder.decision.select"
                  defaultMessage="Decision"
                />}
                onChange={(e) => { this.getChange(e.type, e.value, num) }}
              />
              {this.state.decisions[num] ?
                <div className="journey-decision-delay-wrapper">
                  <span style={{ marginTop: '10px', position: 'relative', display: 'block' }}>{decisionDelayHeaderText}:</span>
                  <div className="row">
                    <div className="col-4">
                      <Select
                        theme={selectTheme}
                        classNamePrefix="journey-channel-select"
                        options={this.numbers}
                        isSearchable={false}
                        defaultValue={this.numbers[0]}
                        onChange={(e) => { this.getChange(e.type, e.value, num) }}
                      />
                    </div>
                    <div className="col-8">
                      <Select
                        theme={selectTheme}
                        classNamePrefix="journey-channel-select"
                        options={this.hourDayOptions}
                        isSearchable={false}
                        defaultValue={this.hourDayOptions[0]}
                        onChange={(e) => { this.getChange(e.type, e.value, num) }}
                      />
                    </div>
                  </div>
                </div>
                : null}
            </div>
            :
            <div className="journey-channel-select-body">
              <span>{channelName}:</span>
              <Select
                theme={selectTheme}
                classNamePrefix="journey-channel-select"
                isDisabled={true}
                placeholder={getFullDecisionName(this.state.selectedJourneyBuilder[num].event)}
              />
              <div className="journey-decision-delay-wrapper">
                <span>{decisionDelayHeaderText}:</span>
                <div className="row">
                  <div className="col-4">
                    <Select
                      theme={selectTheme}
                      classNamePrefix="journey-channel-select"
                      isDisabled={true}
                      placeholder={this.state.selectedJourneyBuilder[num].delay / 60}
                    />
                  </div>
                  <div className="col-8">
                    <div className="journey-channel-select hours"><span>
                      <FormattedMessage
                        id="Journey.builder.decision.selectHours"
                        defaultMessage="Stunden"
                      />
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div >
    );
  }

  availableDecisionOptions = []
  availableTemplates = []
  decisions = []

  getChange = (type, value, id) => { // get changes of the <Selects>
    if (type === 'channel') {
      if (!value) {
        let channelInput = document.querySelector('#channel-' + id + ' .journey-channel-select__single-value');
        if (channelInput) {
          channelInput.innerText = messages["Journey.builder.channel.channelName"];
        }
        return false;
      }
      this.availableTemplates[id] = [];
      this.availableDecisionOptions[id] = [];

      for (let i in this.state.allTemplates) {
        if (this.state.allTemplates[i].kind === value) {
          this.availableTemplates[id].push(this.state.allTemplates[i]); // push only the templates available for selected channel
        }
      }

      for (let i in this.state.allDecisionOptions) {
        if (this.state.allDecisionOptions[i].kind === value) {
          this.availableDecisionOptions[id].push(this.state.allDecisionOptions[i]); // push only the decision-options available for selected channel
        }
      }

      let templateSelect = document.querySelector('.journey-channel-select-body #template-' + id + ' .journey-channel-select__single-value');
      // remove selected Template onChannelChange
      if (templateSelect) {
        templateSelect.innerText = 'Template';
        if (this.state.channels[id].template_id) {
          this.state.channels[id].template_id = false;
        }
      }

      let decisionSelectInput = document.querySelector('#journey-decision-select-' + id + ' .journey-channel-select__single-value');
      // remove the selected Decision if Channel is changed
      if (decisionSelectInput) {
        decisionSelectInput.innerText = <FormattedMessage id="Journey.builder.decision.selectInputHeader" defaultMessage="Decision" />;
        let currentDecisions = this.state.decisions;
        delete currentDecisions[id];

        this.setState({ decision: currentDecisions });
      }

      let currentChannel = this.state.channels[id];

      if (currentChannel === undefined) {
        this.state.channels[id] = { channel: value, template_id: '' } // create channel object to save the selected channel
      } else {
        this.state.channels[id].channel = value;
      }

      this.setState({
        availableTemplates: this.availableTemplates,
        availableDecisionOptions: this.availableDecisionOptions
      });


    } else if (type === 'template') {

      this.state.channels[id].template_id = value; // set the selected template

    } else if (type === 'template-ab') {

      this.state.channels[id].template_ab_id = value;

    } else if (type === 'template-sms') {

      this.state.channels[id] = { channel: 'sms', custom_template: value }

    } else if (type === 'template-print') {

      if (this.state.channels[id].size) {
        this.state.channels[id].custom_template = value;
      } else {
        this.state.channels[id] = { channel: 'print', custom_template: value, size: 'A4SXLD' }
      }

    } else if (type === 'template-print-size') {

      if (this.state.channels[id].custom_template) {
        this.state.channels[id].size = value;
      } else {
        this.state.channels[id] = { channel: 'print', custom_template: false, size: value }
      }

    } else if (type === 'decision') {

      this.decisions[id] = { event: value, delay: 0, delay_option: 'hours' };

      this.setState({ decisions: this.decisions });

    } else if (type === 'delay-time') {
      this.decisions[id].delay = value;

      this.setState({ decisions: this.decisions });

    } else if (type === 'delay-options') { // Hours vs Days

      this.decisions[id].delay_option = value;

      this.setState({ decisions: this.decisions });

    } else if (type === 'retargetingoptions') {
      for (let i in value) {
        this.state.channels[id][i] = value[i];
      }
    }
    this.createJourney('decisions', this.state.decisions);
    this.createJourney('channels', this.state.channels);
  }

  handlePlusClick = () => { // add channel or decision to front-end

    let currentStates = this.state.states;
    let currentStatesLength = Object.keys(currentStates).length;

    for (let i = 0; i < currentStatesLength; i++) {
      if (currentStates[i].channel && currentStates[i].decision) { // if channel and decision is true in this iteration
        if (!currentStates[i + 1].channel) { // if the channel of the next iteration is false, set it true and stop loop
          if (this.state.decisions[i]) { // if decision is selected set next channel true
            currentStates[i + 1].channel = true;
          } else {
            this.notifyDecision();
          }
          break;

        } else { // if the channel of the next iteration is true, keep it true and continue loop

          currentStates[i + 1].channel = true;
          continue;

        }
      } else if (currentStates[i].channel && currentStates[i].decision === false) { // if the channel is true but decision not in this iteration, set decision true

        if (this.state.channels[i]) { // if channel is set
          if (this.state.channels[i].template_id !== '') { // if template is set
            if (this.state.availableDecisionOptions[i].length === 0) {
              this.notifyNoDecision();
            } else {
              if (this.state.abTest) { // if AB testing is selected, check for second template
                if (this.state.channels[i].template_ab_id) {
                  currentStates[i].decision = true;
                } else {
                  notifyTemplate(); // if no template selected
                }
              } else {
                currentStates[i].decision = true; // if channel && template are true, set decision true
              }
            }
          } else {
            if (this.state.channels[i].channel !== 'confirmOptin') {
              notifyTemplate(); // if no template selected
            } else {
              this.state.channels[i].template_id = this.state.allTemplates[0].value;
              currentStates[i].decision = true;
            }
          }
        } else {
          notifyChannel(); // if channel is not set
        }

      }
    }
    this.setState({ states: currentStates });
  }

  handleDeleteClick = () => { // remove latest channel or decision from front-end

    let currentStates = this.state.states;
    let currentStatesLength = Object.keys(currentStates).length;

    let channel = 0;
    let decision = 0;

    for (let i = 0; i < currentStatesLength; i++) {
      if (currentStates[i].channel) {
        channel++;
      }
      if (currentStates[i].decision) {
        decision++;
      }
    }

    if (decision >= channel) {
      currentStates[decision - 1].decision = false;
    } else if (decision < channel) {
      if (channel > 1) {
        currentStates[channel - 1].channel = false;
      }
    }

    this.setState({ states: currentStates });
  }

  NextButton = () => {
    return (
      <div className="plus-button-wrapper text-center">
        <button onClick={() => this.handlePlusClick()}>
          <i className="fas fa-plus-circle"></i>
        </button>
      </div>
    )
  }

  DeleteButton = () => {
    return (
      <div className="plus-button-wrapper text-center">
        <button onClick={() => this.handleDeleteClick()}>
          <i className="fas fa-minus-circle"></i>
        </button>
      </div>
    )
  }

  gatherJourneyBuilderInfo = (steps) => { // recreate Channels and Decisions for JourneyBuilder
    let currentState = this.state.states;
    let selectedJourneyBuilder = [];
    if (steps) {
      for (let i = 0; i < steps.length; i++) {

        selectedJourneyBuilder[i] = [];
        currentState[i].channel = true;

        let channel = steps[i].channel;
        let template;
        let template_ab = false;

        if (steps[i].template !== null && steps[i].template !== undefined) {
          template = steps[i].template.name;
        }

        if (steps[i].template_ab) {
          if (steps[i].template_ab !== null && steps[i].template_ab !== undefined) {
            template_ab = steps[i].template_ab.name;
            if (i > 0) {
              if (steps[i - 1].template_ab !== null && steps[i - 1].template_ab !== undefined) {
                delete steps[i];
                currentState[i].channel = false;
                continue;
              }
            }
          }
        }

        if (steps[i].channel === 'retargetingbanner') {

          let mobile = true;
          let desktop = true;
          if (steps[i].mobile === 'false') { mobile = false; }
          if (steps[i].desktop === 'false') { desktop = false; }

          let retargetingOptions = {
            budget: steps[i].budget,
            day: steps[i].day,
            days: steps[i].days,
            desktop: desktop,
            frequency_capping: steps[i].frequency_capping,
            mobile: mobile,
            state: steps[i].state,
            time_from: steps[i].time_from,
            time_to: steps[i].time_to,
            weather: steps[i].weather
          };

          selectedJourneyBuilder[i] = { channel: '', template: '', event: '', delay: 0, retargetingOptions: retargetingOptions };

        } else {
          selectedJourneyBuilder[i] = { channel: '', template: '', event: '', delay: 0 };
        }

        selectedJourneyBuilder[i].channel = channel;
        selectedJourneyBuilder[i].template = template;

        template_ab ? selectedJourneyBuilder[i].template_ab = template_ab : null;

        if (steps[i].channel === 'sms') {
          selectedJourneyBuilder[i].template = steps[i].custom_template;
        }

        if (steps[i].event !== null) {
          currentState[i].decision = true;

          let event = steps[i].event;
          let delay = steps[i].delay;

          selectedJourneyBuilder[i].event = event;
          selectedJourneyBuilder[i].delay = delay;
        }
      }
    }
    this.setState({ selectedJourneyBuilder: selectedJourneyBuilder });
  }

  removeSelectedJourneyBuilder = () => {
    this.setState({ selectedJourneyBuilder: false });
  }

  componentDidUpdate = () => {
    if (this.props.selectedJourney && !this.state.selectedJourneyBuilder) {
      this.gatherJourneyBuilderInfo(this.props.selectedJourney.steps);
      this.setState({ selectedJourneyId: this.props.selectedJourney.id });
    }
  }

  render() {
    return (
      <div>
        <div className="journey-builder-graphic-wrapper text-center">
          <JourneyBuilderGraphic
            states={this.state.states}
            handlePlusClick={this.handlePlusClick}
            selectedJourneyBuilder={this.state.selectedJourneyBuilder}
            channels={this.state.channels}
          />
        </div>
        <div className="journey-builder" id={this.state.selectedJourneyBuilder ? 'oldjourney' : 'newjourney'}>
          {this.state.states[0].channel ? <this.returnChannel num={0} /> : null}
          {this.state.states[0].decision ? <this.returnDecision num={0} /> : null}
          {this.state.states[1].channel ? <this.returnChannel num={1} /> : null}
          {this.state.states[1].decision ? <this.returnDecision num={1} /> : null}
          {this.state.states[2].channel ? <this.returnChannel num={2} /> : null}
          {this.state.states[2].decision ? <this.returnDecision num={2} /> : null}
          {this.state.states[3].channel ? <this.returnChannel num={3} /> : null}
          {this.state.states[3].decision ? <this.returnDecision num={3} /> : null}
          {!this.state.selectedJourneyBuilder ?
            <div>
              <this.NextButton />
              <this.DeleteButton />
            </div>
            : null}
        </div>
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


export class JourneyBuilderGraphic extends Component {

  // show different picture for different states of channels and decisions

  constructor(props) {
    super(props);
    this.state = {
      states: this.props.states,
      imgSrc: '',
      tag0: false,
      tag1: false,
      tag2: false,
      tag3: false,
      tag4: false,
    };
  };

  handlePlusClick = this.props.handlePlusClick;

  channels = this.props.channels;

  selectedJourneyBuilder = this.props.selectedJourneyBuilder;

  componentDidUpdate = () => {
    this.showCurrentStateGraphic();
    this.updateMedium();
  }

  showCurrentStateGraphic = () => {
    let currentStates = this.state.states;
    let channelCount = 0;
    let decisionCount = 0;
    let imgSrc = '';

    for (let i in currentStates) {
      if (currentStates[i].channel) {
        channelCount++;
      }
      if (currentStates[i].decision) {
        decisionCount++;
      }
    }

    if (channelCount > decisionCount) {
      switch (channelCount) {
        case 1:
          imgSrc = 'verhaltensbasiert_step_1.png';
          break;
        case 2:
          imgSrc = 'verhaltensbasiert_step_3.png';
          break;
        case 3:
          imgSrc = 'verhaltensbasiert_step_5.png';
          break;
        case 4:
          imgSrc = 'verhaltensbasiert_step_7.png';
          break;
        default:
          imgSrc = 'verhaltensbasiert_step_1.png';
      }
    } else {
      switch (decisionCount) {
        case 1:
          imgSrc = 'verhaltensbasiert_step_2.png';
          break;
        case 2:
          imgSrc = 'verhaltensbasiert_step_4.png';
          break;
        case 3:
          imgSrc = 'verhaltensbasiert_step_6.png';
          break;
        case 4:
          imgSrc = 'verhaltensbasiert_step_7.png';
          break;
        default:
          imgSrc = 'verhaltensbasiert_step_1.png';
      }
    }

    if (this.state.imgSrc !== imgSrc) {
      this.setState({ imgSrc: imgSrc });
    }
  }

  mail = 'fas fa-envelope journey-builder';
  mobile = 'fas fa-mobile journey-builder';
  d2p = 'fas fa-paper-plane journey-builder';
  banner = 'fas fa-at journey-builder';
  journeyMediumTagsStyle = {};

  updateMedium = () => {

    for (let i in this.channels) {

      let stateObject = {};
      let tag = '';

      let channel = this.channels[i].channel;

      switch (channel) {
        case 'newsletter':
          tag = this.mail + ' journey-builder' + i;
          break;
        case 'print':
          tag = this.d2p + ' journey-builder' + i;
          break;
        case 'sms':
          tag = this.mobile + ' journey-builder' + i;
          break;
        case 'banner':
          tag = this.banner + ' journey-builder' + i;
          break;
        default:
      }

      stateObject['tag' + i] = tag;

      if (this.state['tag' + i] !== tag) { this.setState(stateObject); }

      let imgDiv = document.getElementsByClassName('journey-builder-img');
      let imgDivWidth = imgDiv[0].clientWidth;
      let imgDivHeigth = imgDiv[0].clientHeight;

      this.journeyMediumTagsStyle = {
        width: imgDivWidth,
        height: imgDivHeigth,
        position: 'absolute',
        top: 0,
        paddingTop: '20px'
      }
    }

    if (this.props.selectedJourneyBuilder) { // for already existing Journies

      for (let i in this.props.selectedJourneyBuilder) {

        let tag = '';
        let stateObject = {};

        let channel = this.props.selectedJourneyBuilder[i].channel;
        switch (channel) {
          case 'nl':
          case 'newsletter':
          case 'Newsletter':
            tag = this.mail + ' journey-builder' + i;
            break;
          case 'Direct-2-Print':
          case 'd2p':
          case 'print':
            tag = this.d2p + ' journey-builder' + i;
            break;
          case 'SMS':
          case 'sms':
            tag = this.mobile + ' journey-builder' + i;
            break;
          case 'Website Banner':
          case 'Retargeting Banner':
          case 'banner':
          case 'retargetingbanner':
            tag = this.banner + ' journey-builder' + i;
            break;
          default:
        }
        stateObject['tag' + i] = tag;

        if (this.state['tag' + i] !== tag) {
          this.setState(stateObject);
        }

        let imgDiv = document.getElementsByClassName('journey-builder-img');
        let imgDivWidth = imgDiv[0].clientWidth;
        let imgDivHeigth = imgDiv[0].clientHeight;

        this.journeyMediumTagsStyle = {
          width: imgDivWidth,
          height: imgDivHeigth,
          position: 'absolute',
          top: 0,
          paddingTop: '20px'
        }

      }
    }
  }

  render() {
    return (
      <div>
        <div className="journey-builder-graphics-img">
          {this.state.imgSrc ?
            !this.props.selectedJourneyBuilder ?
              <img className="journey-builder-img" onClick={() => { this.handlePlusClick() }} src={require("./builder-graphics/" + this.state.imgSrc)} alt="journey-builder" />
              :
              <img className="journey-builder-img" src={require("./builder-graphics/" + this.state.imgSrc)} alt="journey-builder" />
            :
            null}
          <div className="journey-medium-tags" style={this.journeyMediumTagsStyle}>
            {this.state.tag0 ?
              <i key={this.state.tag0} className={this.state.tag0}></i> : null
            }
            {this.state.tag1 ?
              <i key={this.state.tag1} className={this.state.tag1}></i> : null
            }
            {this.state.tag2 ?
              <i key={this.state.tag2} className={this.state.tag2}></i> : null
            }
            {this.state.tag3 ?
              <i key={this.state.tag3} className={this.state.tag3}></i> : null
            }
          </div>
        </div>

      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default JourneyBuilder;