import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import { DataLineChart, DataProgressBarChart } from './prototypeCharts';
import { getDataProgressChartSet } from './ChartOptions';
import { getChartData } from './fetchData';
import { getLabelsAndData, messages } from '../../../reusableFunctions';
import {
  createChartsDatasetsProgressCircle,
  SingleChartWrapper
} from '../Charts';

class BannerCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      send: false,
      returnCharts: false,
      dateFrom: null,
      dateTo: null,
      finishedLoading: false,
      monthly: false
    };
  };

  componentWillMount = () => { this.getData(); }

  componentDidUpdate = () => { this.changeDate(); }

  changeMonthly = (bool) => {
    if (bool !== this.state.monthly) {
      this.setState({
        monthly: bool,
      }, () => this.reloadData());
    }
  }

  changeDate = () => {
    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      });
    } else {
      if (
        (this.props.dateFrom.format('YYYY-MM-DD') !== this.state.dateFrom.format('YYYY-MM-DD')) ||
        (this.props.dateTo.format('YYYY-MM-DD') !== this.state.dateTo.format('YYYY-MM-DD'))
      ) {
        this.setState({
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo
        }, () => this.reloadData());
      }
    }
  }

  reloadData = () => {
    this.setState({
      finishedLoading: false
    }, () => this.getData());
  }

  getData = () => {
    let dateFrom = this.props.dateFrom;
    let dateTo = this.props.dateTo;
    if (!this.state.finishedLoading) {
      NProgress.start();
      getChartData('banner', 'open', dateFrom, dateTo, this.state.monthly).then(
        response => this.setState(
          { send: getLabelsAndData(response) },
          () => this.constructCharts()
        )
      );
      NProgress.set(0.4);
    }
  }

  constructCharts = () => {
    let currentDataProgressChartProto = getDataProgressChartSet();
    currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.send.data,
      <FormattedMessage id="Data.charts.banner.sentBanner" defaultMessage="Versendete Banner" />,
      currentDataProgressChartProto
    );

    let returnCharts = <div>

      <SingleChartWrapper
        rightChart={
          <DataProgressBarChart
            topText={<FormattedMessage id="Data.charts.banner.shownBanner" defaultMessage="Ausgespielte Banner" />}
            data={currentDataProgressChartProto}
          />
        }
        lineChart={
          <DataLineChart
            labels={[this.state.send.labels]}
            names={[messages['Data.charts.banner.shownBanner']]}
            data={[this.state.send.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
    </div>;

    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {(this.state.finishedLoading || this.state.dateFrom !== null) ?
          <div>
            <div>{this.state.returnCharts}</div>
          </div>
          :
          <div className="text-center">
            <span className="bold">
              <FormattedMessage
                id="Data.charts.banner.loadingCharts"
                defaultMessage="Charts werden geladen"
              />
            </span>
          </div>
        }
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default BannerCharts;