import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import {
  DataLineChart,
  DataProgressBarChart,
  CategoriesPieChart
} from './prototypeCharts';
import { getDataProgressChartSet } from './ChartOptions';
import { getChartData } from './fetchData';
import { getLabelsAndData, globalErrorHandler, removeTokenSession, messages } from '../../../reusableFunctions';
import {
  createChartsDatasetsProgressCircle,
  SingleChartWrapper
} from '../Charts';

import InfoTextsModal from '../../Main/InfoTextsModal';

class WebsiteCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      knownUsers: false,
      unknownUsers: false,
      uniqueUsers: false,
      desktopUsers: false,
      mobileUsers: false,
      basketKnown: false,
      basketUnknown: false,
      returnCharts: false,
      dateFrom: null,
      dateTo: null,
      finishedLoading: false,
      categoriesDataKnown: false,
      categoriesDataUnkown: false,
      monthly: false
    };
  };

  componentWillMount = () => { this.getData(); }

  componentDidUpdate = () => { this.changeDate(); }

  changeMonthly = (bool) => {
    if (bool !== this.state.monthly) {
      this.setState({
        monthly: bool,
      }, () => this.reloadData());
    }
  }

  changeDate = () => {
    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      });
    } else {
      if (
        (this.props.dateFrom.format('YYYY-MM-DD') !== this.state.dateFrom.format('YYYY-MM-DD')) ||
        (this.props.dateTo.format('YYYY-MM-DD') !== this.state.dateTo.format('YYYY-MM-DD'))
      ) {
        this.setState({
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo
        }, () => this.reloadData());
      }
    }
  }

  reloadData = () => {
    this.setState({
      finishedLoading: false
    }, () => this.getData());
  }

  getData = async () => {
    let dateFrom = this.props.dateFrom;
    let dateTo = this.props.dateTo;

    if (!this.state.finishedLoading) {
      NProgress.start();
      let monthly = this.state.monthly;
      if (this.props.monthly) {
        monthly = this.props.monthly;
      }

      await getChartData('website', 'known', dateFrom, dateTo, monthly).then(
        response => this.setState({
          knownUsers: getLabelsAndData(response)
        })
      ).catch(error => {
        globalErrorHandler(error);
        removeTokenSession();
      });

      await getChartData('website', 'unknown', dateFrom, dateTo, monthly).then(
        response => this.setState({
          unknownUsers: getLabelsAndData(response)
        })
      ).catch(error => {
        globalErrorHandler(error);
        removeTokenSession();
      });

      await getChartData('website', 'unique', dateFrom, dateTo, monthly).then(
        response => this.setState({
          uniqueUsers: getLabelsAndData(response)
        }, () => NProgress.inc(0.2))
      ).catch(error => {
        globalErrorHandler(error);
        removeTokenSession();
      });

      await getChartData('website', 'desktop', dateFrom, dateTo, monthly).then(
        response => this.setState({
          desktopUsers: getLabelsAndData(response)
        })
      ).catch(error => {
        globalErrorHandler(error);
        removeTokenSession();
      });

      await getChartData('website', 'mobile', dateFrom, dateTo, monthly).then(
        response => this.setState({
          mobileUsers: getLabelsAndData(response)
        })
      ).catch(error => {
        globalErrorHandler(error);
        removeTokenSession();
      });

      await getChartData('basket', 'known', dateFrom, dateTo, monthly).then(
        response => this.setState({
          basketKnown: getLabelsAndData(response)
        })
      ).catch(error => {
        globalErrorHandler(error);
        removeTokenSession();
      });

      await getChartData('basket', 'unknown', dateFrom, dateTo, monthly).then(
        response => this.setState({
          basketUnknown: getLabelsAndData(response)
        })
      ).catch(error => {
        globalErrorHandler(error);
        removeTokenSession();
      });

      this.setState({
        finishedLoading: true
      }, () => { this.getCategoryData() && NProgress.inc(0.4); });

    }
  }

  getCategoryData = async () => {
    let categories = this.props.categories;

    let dateFrom = this.props.dateFrom;
    let dateTo = this.props.dateTo;

    this.intermediate = {};

    let monthly = this.state.monthly;
    if (this.props.monthly) {
      monthly = this.props.monthly;
    }

    if (categories) {

      categories = categories.sort((a, b) => {
        if (a < b) return -1;
        else if (a > b) return 1;
        return 0;
      });

      for (let i in categories) {
        await getChartData('website', 'known/' + categories[i], dateFrom, dateTo, monthly).then(
          response => this.saveIntermediate(response, categories[i], categories.length, 'known')
        ).catch(function (error) {
          globalErrorHandler(error);
          removeTokenSession();
        });
      }

      for (let i in categories) {
        await getChartData('website', 'unknown/' + categories[i], dateFrom, dateTo, monthly).then(
          response => this.saveIntermediate(response, categories[i], categories.length, 'unknown')
        ).catch(function (error) {
          globalErrorHandler(error);
          removeTokenSession();
        });
      }

    } else {
      this.constructCharts();
      NProgress.inc(0.8);
    }
  }

  intermediateKnown = [];
  intermediateUnknown = [];

  saveIntermediate = (response, category, catLength, kind) => {

    if (kind === 'known') {

      this.intermediateKnown[category] = getLabelsAndData(response);
      const intermediateLength = Object.keys(this.intermediateKnown).length;

      if (intermediateLength === catLength) {
        if (this.state.categoriesDataKnown !== this.intermediateKnown) {
          this.setState({
            categoriesDataKnown: this.intermediateKnown
          }, () => { this.constructCharts() && NProgress.inc(0.6) });
        }
      }

    } else if (kind === 'unknown') {

      this.intermediateUnknown[category] = getLabelsAndData(response);
      const intermediateLength = Object.keys(this.intermediateUnknown).length;

      if (intermediateLength === catLength) {
        if (this.state.categoriesDataKnown !== this.intermediateUnknown) {
          this.setState({
            categoriesDataUnknown: this.intermediateUnknown
          }, () => { this.constructCharts() && NProgress.inc(0.8) });
        }
      }

    }

  }

  constructCharts = () => {
    if (this.props.categories) {
      if (!this.state.categoriesDataKnown || !this.state.categoriesDataUnknown) {
        return false;
      }
    }

    let currentDataProgressChartProto = getDataProgressChartSet();
    currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.knownUsers.data,
      <FormattedMessage id="Data.charts.website.knownUsers" defaultMessage="Bekannte Interessenten" />,
      currentDataProgressChartProto
    );
    currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.unknownUsers.data,
      <FormattedMessage id="Data.charts.website.unknownUsers" defaultMessage="Unbekannte Interessenten" />,
      currentDataProgressChartProto
    );
    let currentDataProgressChartProtoAll = getDataProgressChartSet();
    currentDataProgressChartProtoAll = createChartsDatasetsProgressCircle(
      this.state.uniqueUsers.data,
      <FormattedMessage id="Data.charts.website.uniqueUser" defaultMessage="Unique User" />,
      currentDataProgressChartProtoAll
    );

    let currentDataProgressChartProtoDevice = getDataProgressChartSet();
    currentDataProgressChartProtoDevice = createChartsDatasetsProgressCircle(
      this.state.desktopUsers.data,
      <div><i className="fas fa-desktop"></i><span>{'  - ' + messages['Data.charts.website.desktop']}</span></div>,
      currentDataProgressChartProtoDevice
    );
    currentDataProgressChartProtoDevice = createChartsDatasetsProgressCircle(
      this.state.mobileUsers.data,
      <div><i className="fas fa-mobile-alt"></i><span>{'  - ' + messages['Data.charts.website.mobile']}</span></div>,
      currentDataProgressChartProtoDevice
    );

    let currentDataProgressChartProtoBasketAbort = getDataProgressChartSet();
    currentDataProgressChartProtoBasketAbort = createChartsDatasetsProgressCircle(
      this.state.basketKnown.data,
      <span>Bekannte</span>,
      currentDataProgressChartProtoBasketAbort
    );
    currentDataProgressChartProtoBasketAbort = createChartsDatasetsProgressCircle(
      this.state.basketUnknown.data,
      <span>Unbekannte</span>,
      currentDataProgressChartProtoBasketAbort
    );

    let returnChartsWebsite = <div>
      <SingleChartWrapper
        rightChart={
          <div>
            <InfoTextsModal
              info="Data:website:activeuser"
            />
            <DataProgressBarChart
              topText={<FormattedMessage id="Data.charts.website.activeWebsiteUsers" defaultMessage="Alle aktiven Website User" />}
              data={currentDataProgressChartProto}
            />
          </div>
        }
        lineChart={
          <DataLineChart
            labels={[this.state.unknownUsers.labels, this.state.knownUsers.labels]}
            names={[messages['Data.charts.website.unknownUsers'], messages['Data.charts.website.knownUsers']]}
            data={[this.state.unknownUsers.data, this.state.knownUsers.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      <SingleChartWrapper
        rightChart={
          <div>
            <InfoTextsModal
              info="Data:website:enddevice"
            />
            <DataProgressBarChart
              topText={<FormattedMessage id="Data.charts.website.endDevice" defaultMessage="Endgerät" />}
              data={currentDataProgressChartProtoDevice}
            />
          </div>
        }
        lineChart={
          <DataLineChart
            labels={[this.state.desktopUsers.labels, this.state.mobileUsers.labels]}
            names={[messages['Data.charts.website.desktop'], messages['Data.charts.website.mobile']]}
            data={[this.state.desktopUsers.data, this.state.mobileUsers.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      {this.state.categoriesDataKnown ?
        <SingleChartWrapper
          rightChart={
            <div>
              <InfoTextsModal
                info="Data:website:category"
              />
              <CategoriesPieChart
                // topText={<FormattedMessage id="Data.charts.website.userInterestByCategories" defaultMessage="Userinteresse nach Kategorien" />}
                topText={'Bekannte User - Interesse nach Kategorien'}
                data={this.state.categoriesDataKnown}
              />
            </div>
          }
          lineChart={
            <DataLineChart
              dataArray={this.state.categoriesDataKnown}
              stateMonthly={this.state.monthly}
              changeMonthly={this.changeMonthly}
            />
          }
        />
        :
        null}
      {this.state.categoriesDataUnknown ?
        <SingleChartWrapper
          rightChart={
            <div>
              <CategoriesPieChart
                // topText={<FormattedMessage id="Data.charts.website.userInterestByCategories" defaultMessage="Userinteresse nach Kategorien" />}
                topText={'Unbekannte User - Interesse nach Kategorien'}
                data={this.state.categoriesDataUnknown}
              />
            </div>
          }
          lineChart={
            <DataLineChart
              dataArray={this.state.categoriesDataUnknown}
              stateMonthly={this.state.monthly}
              changeMonthly={this.changeMonthly}
            />
          }
        />
        :
        null}
      <SingleChartWrapper
        rightChart={
          <div>
            <InfoTextsModal
              info="Data:website:uniqueuser"
            />
            <DataProgressBarChart
              topText={<FormattedMessage id="Data.charts.website.uniqueUser" defaultMessage="Unique User" />}
              data={currentDataProgressChartProtoAll}
            />
          </div>
        }
        lineChart={
          <DataLineChart
            labels={[this.state.uniqueUsers.labels]}
            names={[messages['Data.charts.website.uniqueUser']]}
            data={[this.state.uniqueUsers.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      {this.state.basketKnown ?
        <SingleChartWrapper
          rightChart={
            <div>
              {/* <InfoTextsModal
                info="Data:website:activeuser"
              /> */}
              <DataProgressBarChart
                topText="Warenkorbabbrecher"
                data={currentDataProgressChartProtoBasketAbort}
              />
            </div>
          }
          lineChart={
            <DataLineChart
              labels={[this.state.basketKnown.labels, this.state.basketUnknown.labels]}
              names={['Bekannte Warenkorbabbrecher', 'Unbekannte Warenkorbabbrecher']}
              data={[this.state.basketKnown.data, this.state.basketUnknown.data]}
              stateMonthly={this.state.monthly}
              changeMonthly={this.changeMonthly}
            />
          }
        />
        : null}
    </div>;

    this.setState({
      returnCharts: returnChartsWebsite
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {(this.state.finishedLoading && this.state.dateFrom !== null) ?
          <div>
            <div>{this.state.returnCharts}</div>
          </div>
          :
          <div className="text-center">
            <span className="bold">
              <FormattedMessage
                id="Data.charts.website.loadingCharts"
                defaultMessage="Charts werden geladen"
              />
            </span>
          </div>
        }
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default WebsiteCharts;