import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import { Transition, config } from 'react-spring'
import { NavLink } from 'react-router-dom';

import { getElementWidth, getElementHeight } from '../../reusableFunctions';

import './Connector.css';
import SVGLogo from '../Main/pictures/logo_login.svg';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
library.add(faBolt);

class ConnectorSpider extends Component {

  render() {
    return (
      <div className="droid-module connector">
        <div className="inner-connector-spider">
          <ConnectorAnimation />
        </div>
        <Lines />
      </div>
    )
  }
}

class ConnectorAnimation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: ['center', 'website', 'geo'],
      center: true,
      website: true, // endpoint
      geo: false,
      mobile: false,
      messages: true, // endpoint
      newsletter: true, // endpoint
      retargeting: false,
      products: true,
      social: false,
      enrichment: false,
      order: true, // endpoint
      crm: true // endpoint
    }
  }

  componentWillMount() { this.startAnimation(); }

  componentDidMount = () => {
    window.onresize = function () {
      let hash = window.location.hash;
      if (hash.startsWith('#/connector')) {
        ConnectorAnimation.adjustLinesAfterResize();
      }
    };
  }

  startAnimation = () => {
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile'] }), 100)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages'] }), 200)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter'] }), 300)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter', 'retargeting'] }), 400)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter', 'retargeting', 'products'] }), 500)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter', 'retargeting', 'products', 'social'] }), 600)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter', 'retargeting', 'products', 'social', 'enrichment'] }), 700)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter', 'retargeting', 'products', 'social', 'enrichment', 'order'] }), 800)
    setTimeout(() => this.setState({ items: ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter', 'retargeting', 'products', 'social', 'enrichment', 'order', 'crm'] }), 900)
  }

  adjustLines = (item) => {
    document.querySelector('.item-' + item).style.transform = 'unset';
    if (item !== 'center') {

      var line = document.getElementById(item + '-line');
      var div1 = document.getElementById('connector-icon-' + item);
      var div2 = document.getElementById('connector-icon-center-img');

      if (!this.state[item]) {
        document.getElementById(item + '-line').classList.add('inactive');
      } else {
        document.getElementById(item + '-line').classList.remove('inactive');
      }

      var x1 = div1.getBoundingClientRect().left + (getElementWidth(div1) / 2);
      var y1 = div1.getBoundingClientRect().top + (getElementHeight(div1) / 2);
      var x2 = div2.getBoundingClientRect().left + (getElementWidth(div2) / 2);
      var y2 = div2.getBoundingClientRect().top + (getElementHeight(div2) / 2);

      line.setAttribute('x1', x1);
      line.setAttribute('y1', y1);
      line.setAttribute('x2', x2);
      line.setAttribute('y2', y2);
    }
  }

  static adjustLinesAfterResize = () => {
    let items = ['center', 'website', 'geo', 'mobile', 'messages', 'newsletter', 'retargeting', 'products', 'social', 'enrichment', 'order', 'crm'];

    for (let i in items) {
      document.querySelector('.item-' + items[i]).style.transform = 'unset';
      if (items[i] !== 'center') {
        var line = document.getElementById(items[i] + '-line');
        var div1 = document.getElementById('connector-icon-' + items[i]);
        var div2 = document.getElementById('connector-icon-center-img');

        var x1 = div1.getBoundingClientRect().left + (getElementWidth(div1) / 2);
        var y1 = div1.getBoundingClientRect().top + (getElementHeight(div1) / 2);
        var x2 = div2.getBoundingClientRect().left + (getElementWidth(div2) / 2);
        var y2 = div2.getBoundingClientRect().top + (getElementHeight(div2) / 2);

        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);
      }
    }
  }


  render() {
    return (
      <div>
        <Transition
          items={this.state.items}
          from={{ position: 'absolute', opacity: 0, transform: 'translate3d(0,-800px,0)', }}
          enter={[{ opacity: 1, height: '10vh', width: '10vw', zIndex: 10 }, { transform: 'translate3d(0,0px,0)', }]}
          config={{ ...config.default, precision: 0.01 }}
          onRest={item => this.adjustLines(item)}>
          {item => props => <div style={props} className={"item-connector item-" + item + " " + (this.state[item] ? 'active' : 'inactive')}><Nodes kind={item} active={this.state[item]} /></div>}
        </Transition>
      </div >
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


class Nodes extends Component {
  render() {
    let returnNode = false;
    let falseToLink = window.location.hash.replace('#', '');
    switch (this.props.kind) {
      case 'website':
        returnNode =
          <NavLink
            className="connector-icon"
            id="connector-icon-website"
            activeClassName="active"
            to={this.props.active ? { pathname: '/connector/website' } : { pathname: falseToLink }}
          >
            <i className="icon-website"></i>
            <span className="uppercase bold">
              <FormattedMessage
                id="Connector.spider.website"
                defaultMessage="Website"
              />
              {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
            </span>
          </NavLink>
        break;
      case 'geo':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-geo"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/geo' } : { pathname: falseToLink }}
        >
          <i className="icon-location"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.geoData"
              defaultMessage="Geodaten"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'mobile':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-mobile"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/mobile' } : { pathname: falseToLink }}
        >
          <i className="icon-apps"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.mobileApps"
              defaultMessage="Mobile Apps"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'messages':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-messages"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/messages' } : { pathname: falseToLink }}
        >
          <i className="icon-sms"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.messages"
              defaultMessage="Nachrichten"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'newsletter':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-newsletter"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/newsletter' } : { pathname: falseToLink }}
        >
          <i className="icon-newsletter"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.newsletter"
              defaultMessage="Newsletter"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'retargeting':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-retargeting"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/retargeting' } : { pathname: falseToLink }}
        >
          <i className="icon-retargeting"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.retargeting"
              defaultMessage="Retargeting"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'products':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-products"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/products' } : { pathname: falseToLink }}
        >
          <i className="icon-produktdaten"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.productData"
              defaultMessage="Produktdaten"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'social':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-social"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/social' } : { pathname: falseToLink }}
        >
          <i className="icon-socialmedia"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.socialMedia"
              defaultMessage="Social Media"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'enrichment':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-enrichment"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/enrichment' } : { pathname: falseToLink }}
        >
          <i className="icon-dataenrichment"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.dataEnrichment"
              defaultMessage="Datenanreicherung"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'order':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-order"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/order' } : { pathname: falseToLink }}
        >
          <i className="icon-kaufdaten"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.purchaseData"
              defaultMessage="Kaufdaten"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'crm':
        returnNode = <NavLink
          className="connector-icon"
          id="connector-icon-crm"
          activeClassName="active"
          to={this.props.active ? { pathname: '/connector/crm' } : { pathname: falseToLink }}
        >
          <i className="icon-crm"></i>
          <span className="uppercase bold">
            <FormattedMessage
              id="Connector.spider.crmData"
              defaultMessage="CRM-Daten"
            />
            {this.props.active ? '' : <i className="connector-bolt fas fa-bolt"></i>}
          </span>
        </NavLink>
        break;
      case 'center':
        returnNode = <NavLink
          className="connector-icon connector-icon-center"
          id="connector-icon-center"
          activeClassName="active"
          to={this.props.active ? { pathname: '/' } : { pathname: falseToLink }}
        >
          <img id="connector-icon-center-img" src={SVGLogo} alt="droid-logo" />
        </NavLink>
        break;
      default:
        returnNode = false;
        break;
    }
    return (
      <div className="connector-node">{returnNode}</div>
    )
  }
}

const Lines = () => {
  return (
    <div className="connector-lines">
      <svg>
        <line className="connector-line" id="website-line" />
        <line className="connector-line" id="geo-line" />
        <line className="connector-line" id="mobile-line" />
        <line className="connector-line" id="messages-line" />
        <line className="connector-line" id="newsletter-line" />
        <line className="connector-line" id="retargeting-line" />
        <line className="connector-line" id="products-line" />
        <line className="connector-line" id="social-line" />
        <line className="connector-line" id="enrichment-line" />
        <line className="connector-line" id="order-line" />
        <line className="connector-line" id="crm-line" />
      </svg>
    </div>
  )
}

export default ConnectorSpider;