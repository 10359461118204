import React, { Component } from 'react';



import NProgress from 'nprogress';

import { DataLineChart, DataProgressBarChart } from '../../Data/Charts/prototypeCharts';
import { getDataProgressChartSet } from '../../Data/Charts/ChartOptions';
import { getChartDataReports } from '../../Data/Charts/fetchData';
import { getLabelsAndData, messages } from '../../../reusableFunctions';
import { createChartsDatasetsProgressCircle, SingleChartWrapperReports } from '../../Data/Charts';

class RetargetingBannerChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthly: true,
      open: false,
      click: false,
      send: false,
      returnCharts: []
    };
  };

  componentWillMount = () => {
    this.collectData();
  }

  currentDataProgressChartProto = [];
  currentDataLineChartProto = [];

  collectData = () => {

    getChartDataReports('banner', 'click', this.props.step.id).then(
      response => this.setState(
        { click: getLabelsAndData(response) },
        () => getChartDataReports('banner', 'open', this.props.step.id).then(
          response => this.setState(
            {
              open: getLabelsAndData(response),
            }, () => getChartDataReports('banner', 'send', this.props.step.id).then(
              response => this.setState(
                {
                  send: getLabelsAndData(response),
                  finishedLoading: true
                }, () => { this.constructChart() && NProgress.set(0.4) }
              )
            )
          )
        )
      )
    );
  }

  constructChart = () => {
    this.currentDataProgressChartProto = [];
    this.currentDataProgressChartProto = getDataProgressChartSet();
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.send.data,
      messages['Reports.charts.banner.displayed'],
      this.currentDataProgressChartProto
    );
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.click.data,
      messages['Reports.charts.banner.click'],
      this.currentDataProgressChartProto
    );

    let returnCharts = <SingleChartWrapperReports
      rightChart={<DataProgressBarChart
        topText={"Retargetingbanner" + ((this.props.step.template !== null) ? (" - " + this.props.step.template.name) : '')} // @max
        data={this.currentDataProgressChartProto}
        hiddenProgress={true}
        reports={true}
      />}
      lineChart={
        <DataLineChart
          labels={[this.state.send.labels, this.state.click.labels]}
          names={[messages['Reports.charts.banner.displayed'], messages['Reports.charts.banner.click']]}
          data={[this.state.send.data, this.state.click.data]}
        />
      }
    />

    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {this.state.returnCharts}
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default RetargetingBannerChart;