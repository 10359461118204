import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import { DataLineChart, DataProgressBarChart, SingleTile } from './prototypeCharts';
import { getDataProgressChartSet } from './ChartOptions';
import { getChartData } from './fetchData';
import { getLabelsAndData, messages, apiPath } from '../../../reusableFunctions';
import {
  createChartsDatasetsProgressCircle,
  SingleChartWrapper
} from '../Charts';

class SMSCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      send: false,
      optin: false,
      optout: false,
      phoneData: false,
      returnCharts: false,
      dateFrom: null,
      dateTo: null,
      finishedLoading: false,
      monthly: false
    };
  };

  componentWillMount = () => this.getData();

  componentDidUpdate = () => this.changeDate();

  changeMonthly = (bool) => {
    if (bool !== this.state.monthly) {
      this.setState({
        monthly: bool,
      }, () => this.reloadData());
    }
  }

  changeDate = () => {
    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      });
    } else {
      if (
        (this.props.dateFrom.format('YYYY-MM-DD') !== this.state.dateFrom.format('YYYY-MM-DD')) ||
        (this.props.dateTo.format('YYYY-MM-DD') !== this.state.dateTo.format('YYYY-MM-DD'))
      ) {
        this.setState({
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo
        }, () => this.reloadData());
      }
    }
  }

  reloadData = () => {
    this.setState({
      finishedLoading: false
    }, () => this.getData());
  }

  getData = () => {
    let dateFrom = this.props.dateFrom;
    let dateTo = this.props.dateTo;
    let monthly = this.state.monthly;
    if (!this.state.finishedLoading) {
      NProgress.start();
      getChartData('sms', 'send', dateFrom, dateTo, monthly).then(
        response => this.setState({
          send: getLabelsAndData(response),
        }, () => {
          getChartData('reachable', 'sms', dateFrom, dateTo, monthly).then(
            response => this.setState({
              optin: getLabelsAndData(response),
              finishedLoading: true
            }, () => {
              this.constructCharts();
              NProgress.set(0.4);
            })
          )
        })
      );
    }
  }

  constructCharts = () => {
    let currentDataProgressChartProto = getDataProgressChartSet();
    currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.send.data,
      <FormattedMessage id="Data.charts.sms.sentSMS" defaultMessage="Versendete SMS" />,
      currentDataProgressChartProto
    );

    let returnCharts = <div>
      <SingleChartWrapper
        rightChart={
          <DataProgressBarChart
            topText={<FormattedMessage id="Data.charts.sms.sentSMS" defaultMessage="Versendete SMS" />}
            data={currentDataProgressChartProto}
          />
        }
        lineChart={
          <DataLineChart
            labels={[this.state.send.labels]}
            names={[messages['Data.charts.sms.sentSMS']]}
            data={[this.state.send.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      {/* <PhoneDemographics /> */}
    </div>;
    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {(this.state.finishedLoading && this.state.returnCharts) ?
          <div>
            <div>{this.state.returnCharts}</div>
            <div className="row crm-card-wrapper shadow">
              <SingleTile
                text="SMS-Optins"
                number={this.state.optin.data[0] + ' Personen'}
              />
            </div>
          </div>
          :
          <div className="text-center">
            <span className="bold">
              <FormattedMessage
                id="Data.charts.sms.loadingCharts"
                defaultMessage="Charts werden geladen"
              />
            </span>
          </div>
        }
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class PhoneDemographics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: false,
      error: false,
      returnCharts: <div className="little-loading-droid" style={{ display: 'block' }}></div>
    };
  };

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    let that = this;
    fetch(apiPath + 'data/landline', {
      method: 'GET',
      mode: 'cors',
      dataType: 'json',
      headers: {
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (!data.error) {
        that.setState({
          data: data,
          total: data.total,
          mobile: data.mobile,
          landline: data.landline,
          others: data.others,
          error: false
        }, () => that.constructChart());
      } else {
        that.setState({ error: true });
      }
    }).catch(function (error) {
      that.setState({ error: true });
      console.log(error);
    });
  }

  constructChart = () => {
    let returnCharts = [];

    returnCharts.push(
      <div key={0}>
        <div className="row crm-card-wrapper card-sms shadow">
          <SingleTile text={messages['Data.charts.sms.total']} number={this.state.total} />
          <SingleTile text={messages['Data.charts.sms.landline']} number={this.state.landline.total} />
          <SingleTile text={messages['Data.charts.sms.mobile']} number={this.state.mobile.total} />
          <SingleTile text={messages['Data.charts.sms.unmatched']} number={this.state.others} />
        </div>
      </div>
    );

    this.setState({
      returnCharts: returnCharts
    });
  }

  render() {
    return (
      <div>
        {!this.state.error ?
          <div>
            {this.state.returnCharts}
          </div>
          : null}
      </div>
    )
  }
}

export default SMSCharts;