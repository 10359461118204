import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import { apiPath, getPercent, messages } from '../../../reusableFunctions';
import { SingleTile } from './prototypeCharts';

import GoogleMap from './Maps';

class CRMCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      returnCharts: false,
      finishedLoading: false,
      birthday: false,
      country: false,
      gender: false,
      optin: false,
      error: false
    };
  };

  componentWillMount = () => this.getData();

  reloadData = () => {
    this.setState({
      finishedLoading: false
    }, () => this.getData());
  }

  getData = () => {
    let that = this;
    if (!this.state.finishedLoading) {
      NProgress.start();
      fetch(apiPath + 'data/crm', {
        method: 'GET',
        mode: 'cors',
        dataType: 'json',
        headers: {
          'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          that.setState({
            birthday: getPercent(data.birthday),
            country: data.country,
            gender: getPercent(data.gender),
            optin: getPercent(data.optin),
            finishedLoading: true,
            error: false
          }, () => that.constructCharts());
        })
        .catch(function (error) {
          console.log(error);
          that.setState({
            error: true,
            finishedLoading: true
          }, () => that.constructCharts());
        });
    }
    NProgress.set(0.4);
  }

  constructCharts = () => {

    let country = this.state.country;
    let mapData = [['Region', 'Count']];
    for (let i in country) {
      if (country[i][1]) {
        country[i][1] = Number(country[i][1]);
        if (country[i][1] > 0) {
          mapData.push(country[i]);
        }
      }
    }

    let returnCharts = <div>
      <Birthday data={this.state.birthday} />
      <Gender data={this.state.gender} />
      <Optin data={this.state.optin} />
      {mapData.length > 1 ? <GoogleMap data={this.state.country} /> : null}
    </div>;

    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {this.state.finishedLoading ?
          <div>
            {this.state.error ?
              <div style={{ textAlign: 'center', fontSize: '1.5em', padding: '20px' }}>
                <FormattedMessage id="Data.error.charts" defaultMessage="Probleme mit den Charts" />
              </div>
              :
              <div>{this.state.returnCharts}</div>
            }
          </div>
          :
          <div className="text-center">
            <span className="bold">
              <FormattedMessage
                id="Data.charts.banner.loadingCharts"
                defaultMessage="Charts werden geladen"
              />
            </span>
          </div>
        }
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class Birthday extends Component {

  constructor(props) {
    super(props);
    this.state = {
      returnCharts: false,
      data: this.props.data
    };
  };

  componentWillMount = () => this.createInfoTable();

  createInfoTable = () => {
    let returnCharts = [];
    (Object.entries(this.state.data).map((obj, index) => {
      let headerText = '';
      switch (obj[0]) {
        case 'children':
          headerText = messages['Data.charts.crm.age.children'];
          break;
        case 'young_adult':
          headerText = messages['Data.charts.crm.age.youngAdult'];
          break;
        case 'adult':
          headerText = messages['Data.charts.crm.age.adult'];
          break;
        case 'old_adult':
          headerText = messages['Data.charts.crm.age.oldAdult'];
          break;
        case 'aged':
          headerText = messages['Data.charts.crm.age.aged'];
          break;
        default:
          headerText = '';
          break;
      }
      returnCharts.push(
        <SingleTile
          key={index}
          text={headerText}
          number={obj[1].toString()}
          percent={true}
        />
      );
    }));
    this.setState({
      returnCharts: returnCharts
    });
  }

  render() {
    return (
      <div>
        <span className="crm-card-header-text">
          <FormattedMessage
            id="Data.charts.crm.age.headerText"
            defaultMessage="Alter"
          />:
        </span>
        <div className="row crm-card-wrapper shadow">
          {this.state.returnCharts}
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class Country extends Component { // is right now not in view

  constructor(props) {
    super(props);
    this.state = {
      returnCharts: false,
      data: this.props.data
    };
  };

  componentWillMount = () => this.createInfoTable();

  createInfoTable = () => {
    let returnCharts = [];
    (Object.entries(this.state.data).map((obj, index) => {
      let headerText = '';
      switch (obj[0]) {
        case 'austria':
          headerText = messages['Data.charts.crm.country.austria'];
          break;
        case 'germany':
          headerText = messages['Data.charts.crm.country.germany'];
          break;
        case 'switzerland':
          headerText = messages['Data.charts.crm.country.switzerland'];
          break;
        default:
          headerText = '';
          break;
      }
      returnCharts.push(
        <SingleTile
          key={index}
          text={headerText}
          number={obj[1].toString()}
          percent={true}
        />
      );
    }));
    this.setState({
      returnCharts: returnCharts
    });
  }

  render() {
    return (
      <div>
        <span className="crm-card-header-text">
          <FormattedMessage
            id="Data.charts.crm.country.headerText"
            defaultMessage="Land"
          />:
        </span>
        <div className="row crm-card-wrapper shadow">
          {this.state.returnCharts}
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class Gender extends Component {

  constructor(props) {
    super(props);
    this.state = {
      returnCharts: false,
      data: this.props.data
    };
  };

  componentWillMount = () => this.createInfoTable();

  createInfoTable = () => {
    let returnCharts = [];
    (Object.entries(this.state.data).map((obj, index) => {
      let headerText = '';
      switch (obj[0]) {
        case 'male':
          headerText = messages['Data.charts.crm.gender.males'];
          break;
        case 'female':
          headerText = messages['Data.charts.crm.gender.females'];
          break;
        case 'other':
          headerText = messages['Data.charts.crm.gender.others'];
          break;
        default:
          headerText = '';
          break;
      }
      returnCharts.push(
        <SingleTile
          key={index}
          text={headerText}
          number={obj[1].toString()}
          percent={true}
        />
      );
    }));
    this.setState({
      returnCharts: returnCharts
    });
  }

  render() {
    return (
      <div>
        <span className="crm-card-header-text">
          <FormattedMessage
            id="Data.charts.crm.gender.headerText"
            defaultMessage="Geschlecht"
          />:
        </span>
        <div className="row crm-card-wrapper shadow">
          {this.state.returnCharts}
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class Optin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      returnCharts: false,
      data: this.props.data
    };
  };

  componentWillMount = () => this.createInfoTable();

  createInfoTable = () => {
    let returnCharts = [];
    (Object.entries(this.state.data).map((obj, index) => {
      let headerText = '';
      switch (obj[0]) {
        case 'email':
          headerText = messages['Data.charts.crm.optins.mail'];
          break;
        case 'print':
          headerText = messages['Data.charts.crm.optins.print'];
          break;
        case 'sms':
          headerText = messages['Data.charts.crm.optins.sms'];
          break;
        default:
          headerText = '';
          break;
      }
      returnCharts.push(
        <SingleTile
          key={index}
          text={headerText}
          number={obj[1].toString()}
          percent={true}
        />
      );
    }));
    this.setState({
      returnCharts: returnCharts
    });
  }

  render() {
    return (
      <div>
        <span className="crm-card-header-text">
          <FormattedMessage
            id="Data.charts.crm.optins.headerText"
            defaultMessage="Optins"
          />:
        </span>
        <div className="row crm-card-wrapper shadow">
          {this.state.returnCharts}
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default CRMCharts;