import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import moment from 'moment';
import 'moment/locale/de-at';

import { CustomerInfo } from './UserProfile';

import { capitalizeFirst, messages, language } from './../../reusableFunctions';
import '../Data/Data.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDesktop, faLaptop, faEnvelope } from '@fortawesome/free-solid-svg-icons';
library.add(faDesktop, faLaptop, faEnvelope);

export class Timeline extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timelineTabs: false,
      selectedTimeline: 'web',
      showUserSearch: false
    };
  };

  getTimelineTabs = () => {
    // console.log(this.props.timeline);
  }

  componentWillMount = () => {
    this.getTimelineTabs();
  }

  changeSelectedTimeline = (value) => {
    NProgress.start();
    this.setState({
      selectedTimeline: value
    });
  }

  render() {
    return (
      <div>
        <TimelineTabs
          changeSelectedTimeline={this.changeSelectedTimeline}
          selectedTimeline={this.state.selectedTimeline}
        />
        <CustomerInfo
          data={this.props.customer}
          mail={this.props.mail}
          shop={this.props.shop}
          timeline={this.props.timeline}
          showGDPR={this.props.showGDPR}
          generateGDPR={this.props.generateGDPR}
        />
        <TimelineWrapper
          selectedTimeline={
            this.state.selectedTimeline !== 'orders' ?
              this.props.timeline[this.state.selectedTimeline] :
              this.props.customer.orders ? this.props.customer.orders : []
          }
          channel={this.state.selectedTimeline}
        />
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}



const TimelineTabs = (props) => {
  return (
    <div className="timeline-tabs-wrapper">
      <div className="row">
        <div
          className={"col-12 col-sm tab shadow " + ((props.selectedTimeline === 'web') ? 'active' : '')}
          onClick={() => { props.changeSelectedTimeline('web') }}
        >
          <span>{messages['UserSearch.timeline.web']}</span>
        </div>
        <div
          className={"col-12 col-sm tab shadow " + ((props.selectedTimeline === 'mail') ? 'active' : '')}
          onClick={() => { props.changeSelectedTimeline('mail') }}
        >
          <span>{messages['UserSearch.timeline.newsletter']}</span>
        </div>
        <div
          className={"col-12 col-sm tab shadow " + ((props.selectedTimeline === 'sms') ? 'active' : '')}
          onClick={() => { props.changeSelectedTimeline('sms') }}
        >
          <span>{messages['UserSearch.timeline.sms']}</span>
        </div>
        <div
          className={"col-12 col-sm tab shadow " + ((props.selectedTimeline === 'banner') ? 'active' : '')}
          onClick={() => { props.changeSelectedTimeline('banner') }}
        >
          <span>{messages['UserSearch.timeline.banner']}</span>
        </div>
        <div
          className={"col-12 col-sm tab shadow " + ((props.selectedTimeline === 'print') ? 'active' : '')}
          onClick={() => { props.changeSelectedTimeline('print') }}
        >
          <span>{messages['UserSearch.timeline.print']}</span>
        </div>
        <div
          className={"col-12 col-sm tab shadow " + ((props.selectedTimeline === 'orders') ? 'active' : '')}
          onClick={() => { props.changeSelectedTimeline('orders') }}
        >
          <span>{messages['UserSearch.timeline.purchaseData']}</span>
        </div>
      </div>
    </div>
  )
}


class TimelineWrapper extends Component {

  timeline = <div className="single-timeline">
    <FormattedMessage
      id="UserSearch.timeline.noInformation"
      defaultMessage="Keine {channelName} Informationen"
      values={{
        channelName: capitalizeFirst(this.props.channel)
      }}
    />
  </div>;

  constructor(props) {
    super(props);
    this.state = {
      timeline: this.timeline,
      selectedTimeline: this.props.selectedTimeline
    }
  }

  componentDidUpdate = () => {
    if (this.props.selectedTimeline !== this.state.selectedTimeline) {
      this.setState({
        selectedTimeline: this.props.selectedTimeline
      }, () => this.createTimeline());
    }
  }

  componentDidMount = () => {
    this.createTimeline();
  }

  createTimeline = () => {
    NProgress.inc(0.5);
    let selectedTimeline = this.state.selectedTimeline;

    if (selectedTimeline.length > 0) {
      if (this.props.channel === 'mail') {
        this.timeline = (selectedTimeline.map((tim, index) => {
          let timestamp = false;
          if (tim.created_at) {
            timestamp = moment(tim.created_at).add('1', 'hours').format("YYYY-MM-DD HH:mm:ss");
          } else if (tim.updated_at) {
            timestamp = moment(tim.updated_at).add('1', 'hours').format("YYYY-MM-DD HH:mm:ss");
          }

          return (
            <div key={index} className="single-timeline">
              <i className="fas fa-envelope"></i><span></span>
              {timestamp ? <span className="italic">{timestamp}</span> : null}
              {tim.action ? <span className="italic bold">{tim.action}</span> : null}
              {tim.mailingname ? <span className="category">{tim.mailingname}</span> : null}
              {tim.linkname ?
                <span className="category">Linkname: {tim.linkname}</span>
                : tim.link_url ?
                  <span className="category">{tim.link_url}</span>
                  : null}
              <hr />
            </div>
          )
        }));
      } else if (this.props.channel === 'sms') {
        this.timeline = (selectedTimeline.map((tim, index) =>
          <div key={index} className="single-timeline">
            <i className="fas fa-mobile"></i><span></span>
            <span className="italic bold">{tim.action}</span>
            <span className="category italic">{tim.phone}</span>
            {tim.mailingname ? <span className="category">{tim.mailingname}</span> : ''}
            <hr />
          </div>
        ));
      } else if (this.props.channel === 'orders') {
        this.timeline = (selectedTimeline.map((tim, index) => {
          let timestamp = false;
          if (tim.date) {
            timestamp = moment(tim.created_at).add('1', 'hours').format("YYYY-MM-DD HH:mm:ss");
          }
          return (
            <div key={index} className="single-timeline">
              {timestamp ? <span className="italic">{timestamp}</span> : null}
              {tim.product ? <span className="category bold">{tim.product.name}</span> : null}
              {tim.order_number ? <span className="category">{tim.order_number}</span> : null}
              {tim.price ? <span className="url bold">{tim.price + ' €'}</span> : (tim.sum ? <span className="url bold">{tim.sum + ' €'}</span> : null)}
              <hr />
            </div >
          )
        }
        ));
      } else { // Website/Other
        this.timeline = (selectedTimeline.map((tim, index) => {
          let timestamp = false;
          if (tim.created_at) {
            timestamp = moment(tim.created_at).add('1', 'hours').format("YYYY-MM-DD HH:mm:ss");
          } else if (tim.updated_at) {
            timestamp = moment(tim.updated_at).add('1', 'hours').format("YYYY-MM-DD HH:mm:ss");
          }
          return (
            <div key={index} className="single-timeline">
              <span>{(tim.device === "Mobile") ? <i className="fas fa-mobile"></i> : <i className="fas fa-desktop"></i>}</span>
              <span className="italic">{timestamp}</span>
              <span className="category bold">{(tim.category !== null && tim.category !== undefined && tim.category !== 'false') ? tim.category : 'Other'}</span>
              <span className="url">{tim.url}</span>
              <hr />
            </div>
          )
        }
        ));
      }
    } else {
      let channelName = this.props.channel;

      if (channelName === 'orders' && !language.includes('en')) {
        channelName = 'kaufdaten';
      }
      this.timeline = <div className="single-timeline">
        <FormattedMessage
          id="UserSearch.timeline.noInformation"
          defaultMessage="Keine {channelName} Informationen"
          values={{
            channelName: capitalizeFirst(channelName)
          }}
        />
      </div>;
    }

    this.setState({
      timeline: this.timeline
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        <div className="timeline-header">
          <span className="bold">
            <FormattedMessage
              id="UserSearch.timeline.header"
              defaultMessage="Timeline"
            />:
          </span>
        </div>
        <div className="timeline-wrapper shadow">
          {this.state.timeline}
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Timeline;