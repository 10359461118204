import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import moment from 'moment';

import JourneyInsight from '../Reports/JourneyInsight';

import WebsiteCharts from '../Data/Charts/Website';
import NewsletterCharts from '../Data/Charts/Newsletter';

class ReportsCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      returnChart: false,
      channel: false,
      dateFrom: null,
      dateTo: null,
      unique: null, // specific id to filter the chart ex: journey id
	key:null,
    };
  };

  returnCorrectChart = () => {
    switch (this.state.channel) {
      case 'journey_insight':
        this.setState({
          returnChart: <JourneyInsight
            dateFrom={this.props.dateFrom}
            dateTo={this.props.dateTo}
            unique={this.props.unique}
            categories={this.props.categories}
	    key={Math.random()}
          />
        });
        break;
      case 'reports_general':
        this.setState({
          returnChart: <GeneralCharts
            dateFrom={this.props.dateFrom}
            dateTo={this.props.dateTo}
            categories={this.props.categories}
          />
        });
        break;
      default:
        this.setState({
          returnChart: <div>
            <FormattedMessage
              id="Reports.charts.chartProblems"
              defaultMessage="Probleme mit den Charts"
            />
          </div >
        })
        break;
    }
  }

  componentDidMount = () => {
    this.changeChannel();
    this.changeDate();
  }

  componentDidUpdate = () => {
    this.changeChannel();
    this.changeDate();
  }

  changeChannel = () => {
    if (this.state.channel !== this.props.channel || this.props.unique !== this.state.unique) {
      this.setState({
        channel: this.props.channel,
        unique: this.props.unique
      }, () => this.returnCorrectChart());
    }
  }

  changeDate = () => {
    if (this.props.dateFrom !== this.state.dateFrom || this.props.dateTo !== this.state.dateTo) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      }, () => this.returnCorrectChart());
    }
  }

  render() {
    return (
      <div>
        {this.state.returnChart ?
          <div>{this.state.returnChart}</div>
          :
          <div>
            <FormattedMessage
              id="Reports.charts.chartProblems"
              defaultMessage="Probleme mit den Charts"
            />
          </div>
        }
      </div>
    )
  }
}


class GeneralCharts extends Component {
  constructor(props) {
    super(props);
    let dateFrom = moment().subtract(6, 'month');
    let dateTo = moment();
    this.state = {
      dateFrom: dateFrom,
      dateTo: dateTo
    };
  };

  categories = this.props.categories;

  render() {
    return (
      <div>
        <WebsiteCharts
          dateFrom={this.state.dateFrom}
          dateTo={this.state.dateTo}
          categories={this.categories}
          monthly={true}
        />
        <NewsletterCharts
          dateFrom={this.state.dateFrom}
          dateTo={this.state.dateTo}
          categories={this.categories}
          monthly={true}
        />
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default ReportsCharts;
