import React, { Component } from 'react';


import { messages, selectTheme, apiPath, language } from '../../reusableFunctions';

import { FormattedMessage } from 'react-intl';

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

let journeyName = messages['Journey.name.name'];
let journeyNameHeader = messages['Journey.name.header'];
let journeyTypeSelectName = messages['Journey.type.selectName'];
// let journeyTypeSelectPlaceholder = messages['Journey.type.selectPlaceholder'];
let journeyCategorySelectName = messages['Journey.category.selectName'];
// let journeyCategorySelectPlaceholder = messages['Journey.category.selectPlaceholder'];
let journeyTargetgroupSelectName = messages['Journey.targetgroup.selectName'];
let journeyStartSelectName = messages['Journey.journeyStart.selectName'];
let journeyIntervallSelectName = messages['Journey.interval.selectName'];

class JourneyDatepicker extends Component {

  // createJourney function from Journey class
  createJourney = this.props.createJourney;

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      focused: null,
      disabled: false
    }
  }

  componentDidMount = () => {
    this.createJourney('startdate', this.state.date.toString());
  }

  onChange = date => this.setState({ date });

  myChange = (value) => {
    this.setState({ date: value });
    this.createJourney('startdate', value.toString());
  }

  componentWillReceiveProps = () => {
    if (this.props.selectedDate !== undefined) {
      this.setState({
        date: new Date(this.props.selectedDate),
        disabled: true
      });
    }
  }

  polyfillMaxLengthInput = () => {
    // polyfill to not input more than 2 values, max and maxlength attributes not working in browsers
    let hourInput = document.getElementsByClassName('react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__hour')[0];
    let minuteInput = document.getElementsByClassName('react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__minute')[0];
    if (hourInput && minuteInput) {
      hourInput.setAttribute('maxlength', '2');
      hourInput.setAttribute('onfocus', "this.value = ''");
      hourInput.setAttribute('oninput', "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(this.maxLength, this.value.length);");
      minuteInput.setAttribute('maxlength', '2');
      minuteInput.setAttribute("onfocus", "this.value = ''");
      minuteInput.setAttribute('oninput', "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(this.maxLength, this.value.length);");
    }
  }

  render() {
    this.polyfillMaxLengthInput();
    let locale = 'DE-de';
    if (language.includes('en')) {
      locale = 'en-de';
    }
    return (
      <div className="journey-datepicker-wrapper">
        <DateTimePicker
          onChange={(value) => { this.myChange(value) }}
          value={this.state.date}
          disabled={this.state.disabled}
          showLeadingZeros={false}
          clearIcon={null}
          calendarIcon={null}
          disableClock={true}
          locale={locale}
        />
        {!this.state.disabled ?
          <button className="btn-reset-datepicker" onClick={() => { this.myChange(new Date()) }}>
            <FormattedMessage
              id="Journey.journeyStart.now"
              defaultMessage="Jetzt"
            />
          </button>
          :
          null}
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class JourneySettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedInterval: false,
      fromAddresses: false
    };
  };

  journeyTypes = this.props.journeyTypes;
  journeyCategories = this.props.journeyCategories;
  targetgroups = this.props.targetgroups;
  journeyIntervals = this.props.journeyIntervals;

  // add settings to journey class state
  createJourney = this.props.createJourney;

  // add journey name to journey class state
  setJourneyName = this.props.setJourneyName;

  // the journey to recreate
  selectedJourney = this.props.selectedJourney;
  selectedJourneySettings = this.props.selectedJourneySettings;

  conversion = this.props.conversion;

  getCorrectInterval = (intervalValue) => {
    for (let i in this.journeyIntervals) {
      if (this.journeyIntervals[i].value === intervalValue) {
        this.setState({
          selectedInterval: i
        });
      }
    }
  }

  createNewSelectObject(object, type, value, label) {
    let add = { type: type, value: value, label: label };
    object.push(add);
  }

  componentWillMount = () => {
    this.getFromAddress();
  }

  getFromAddress = () => {
    if (!this.state.fromAddresses) {
      let that = this;
      fetch(apiPath + 'journey/getaddress', {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (!data.error) {
          let address = [];
          for (let i in data) {
            that.createNewSelectObject(
              address,
              'from-address',
              [data[i].name, data[i].mail_address],
              data[i].mail_address
            );
          }
          that.createJourney('from-address', [data[0].name, data[0].mail_address])
          that.setState({
            fromAddresses: address
          });
        }
      }).catch(function (error) {
        // console.log(error);
      });
    }
  }

  componentWillReceiveProps = () => {
    if (this.props.selectedJourney) {
      this.getCorrectInterval(this.props.selectedJourney.interval.toString());
    }
  }

  render() {
    return (
      <div className="journey-settings-wrapper" >
        <div className="row">
          <div className="col">
            <div className="journey-title-wrapper">
              <label className="journey-select-label">{journeyNameHeader}:</label><br />
              {!this.props.selectedJourney ?
                <input
                  onChange={(e) => { this.setJourneyName(e.target.value) }}
                  id="journey-title-input"
                  placeholder={journeyName}
                ></input>
                :
                <input
                  disabled
                  onChange={(e) => { this.setJourneyName(e.target.value) }}
                  id="journey-title-input"
                  placeholder={this.props.selectedJourney.name}
                ></input>
              }
            </div>
          </div>
        </div>
        {this.journeyTypes ?
          <div className="row" >
            <div className="col">
              <label className="journey-select-label">{journeyTypeSelectName}:</label>
              <div className="journey-select">
                {!this.props.selectedJourney ?
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    options={this.journeyTypes}
                    isSearchable={false}
                    placeholder={this.journeyTypeSelectPlaceholder}
                    onChange={(e) => { this.createJourney(e.type, e.value) }}
                  />
                  :
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    isDisabled={true}
                    isSearchable={false}
                    placeholder={this.props.selectedJourneySettings.type}
                  />
                }
              </div>
            </div>
          </div>
          : null}
        {this.journeyCategories ?
          <div className="row">
            <div className="col">
              <label className="journey-select-label">{journeyCategorySelectName}:</label>
              <div className="journey-select">
                {!this.props.selectedJourney ?
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    options={this.journeyCategories}
                    isSearchable={false}
                    placeholder={this.journeyCategorySelectPlaceholder}
                    onChange={(e) => { this.createJourney('category_id', e.value) }}
                  />
                  :
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    isDisabled={true}
                    isSearchable={false}
                    placeholder={this.props.selectedJourneySettings.category}
                  />
                }
              </div>
            </div>
          </div>
          : null}
        {this.targetgroups ?
          <div className="row">
            <div className="col">
              <label className="journey-select-label">{journeyTargetgroupSelectName}:</label>
              <div className="journey-select">
                {(this.props.selectedTargetgroup === false) ?
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    options={this.targetgroups}
                    placeholder={this.journeyTargetgroupSelectName}
                    onChange={(e) => { this.createJourney(e.type, e.value) }}
                    maxMenuHeight="200"
                  />
                  :
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    isDisabled={true}
                    classNamePrefix="journey-select"
                    placeholder={this.props.selectedTargetgroup}
                  />
                }
              </div>
            </div>
          </div>
          : null}
        <div className="row">
          <div className="col">
            <label className="journey-select-label">{journeyStartSelectName}:</label>
            <div className="journey-select">
              <div className="journey-start-select shadow text-left">
                <JourneyDatepicker
                  createJourney={this.createJourney}
                  selectedDate={this.props.selectedJourney.start}
                />
              </div>
            </div>
          </div>
        </div>
        {this.journeyIntervals ?
          <div className="row">
            <div className="col">
              <label className="journey-select-label">{journeyIntervallSelectName}:</label>
              <div className="journey-select">
                {(!this.props.selectedJourney && !this.state.selectedInterval) ?
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    options={this.journeyIntervals}
                    isSearchable={false}
                    placeholder={journeyIntervallSelectName}
                    onChange={(e) => { this.createJourney(e.type, e.value) }}
                  />
                  :
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    value={this.journeyIntervals[this.state.selectedInterval]}
                    isDisabled={true}
                  />
                }
              </div>
            </div>
          </div>
          : null}
        {this.conversion ?
          <div className="row">
            <div className="col">
              <label className="journey-select-label">Conversion:</label>
              <div className="journey-select">
                {!this.props.selectedJourney ?
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    options={this.conversion}
                    isSearchable={false}
                    placeholder={'Off'}
                    onChange={(e) => {
                      this.createJourney('conversion', e.value);
                      this.setState({ conversionDays: e.value === 'on' ? true : false });
                    }}
                  />
                  :
                  <Select
                    theme={selectTheme}
                    className="shadow"
                    classNamePrefix="journey-select"
                    isDisabled={true}
                    isSearchable={false}
                    placeholder={this.props.selectedJourney.conversion.capitalize()}
                  />
                }
                {this.state.conversionDays ?
                  <div className="journey-title-wrapper">
                    <label className="journey-select-label">Conversion Interval (days):</label><br />
                    <input
                      onChange={(e) => { this.createJourney('conversion_days', e.target.value) }}
                      id="journey-title-input"
                      type="number"
                      placeholder="0"
                    ></input>
                  </div>
                  : null}
                {this.props.selectedJourney.conversion === 'on' && this.props.selectedJourney.conversion_days ?
                  <div className="journey-title-wrapper">
                    <label className="journey-select-label">Conversion Interval (days):</label><br />
                    <input
                      value={this.props.selectedJourney.conversion_days}
                      id="journey-title-input"
                      type="number"
                      disabled
                    ></input>
                  </div>
                  : null}
              </div>
            </div>
          </div>
          : null}
        {this.state.fromAddresses ?
          <div className="row">
            <div className="col">
              {!this.props.selectedJourney ?
                <div>
                  <label className="journey-select-label">
                    <FormattedMessage
                      id="Journey.fromAddress"
                      defaultMessage="Von der E-Mail Adresse"
                    />:
                  </label>
                  <div className="journey-select">
                    <Select
                      theme={selectTheme}
                      className="shadow"
                      classNamePrefix="journey-select"
                      options={this.state.fromAddresses}
                      isSearchable={false}
                      defaultValue={this.state.fromAddresses[0]}
                      onChange={(e) => { this.createJourney(e.type, e.value) }}
                    />
                  </div>
                </div>
                :
                <div>
                  {this.props.selectedJourney.from_address ?
                    <div>
                      <label className="journey-select-label">
                        <FormattedMessage
                          id="Journey.fromAddress"
                          defaultMessage="Von der E-Mail Adresse"
                        />:</label>
                      <div className="journey-select">
                        <Select
                          theme={selectTheme}
                          className="shadow"
                          classNamePrefix="journey-select"
                          placeholder={this.props.selectedJourney.from_address}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                    : null}
                </div>
              }
            </div>
          </div>
          : null}
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


export default JourneySettings;