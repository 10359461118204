import React, { Component } from 'react';

import Select from 'react-select';

import { FormattedMessage } from 'react-intl';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { capitalizeFirst, selectTheme, messages } from './../../reusableFunctions';

import Salesforce from '../../pictures/interface-pictures/salesforce.svg';
import as400 from '../../pictures/interface-pictures/as400.jpg';
import Dynamics from '../../pictures/interface-pictures/microsoft.png';
import Facebook from '../../pictures/interface-pictures/facebook.svg';
import Rest from '../../pictures/interface-pictures/rest.png';

class AddInterface extends Component {

  constructor(props) {
    super(props);
    this.state = {
      interface: [
        { type: 'interface', value: 'Salesforce', label: 'Salesforce' },
        { type: 'interface', value: 'as400', label: 'IBM AS/400' },
        { type: 'interface', value: 'Dynamics', label: 'Microsoft Dynamics' },
        { type: 'interface', value: 'Facebook', label: 'Facebook' },
        { type: 'interface', value: 'Rest', label: 'REST API' },
      ],
      selectedInterface: Salesforce
    }
  }

  notifySave = () => toast('Anbindung erfolgreich gespeichert!', {
    className: 'login-notify',
    autoClose: 3000,
    progressClassName: 'login-notify-progressbar'
  });

  changeInterface = (selection) => {
    let image;
    switch (selection) {
      case 'Salesforce':
        image = Salesforce;
        break;
      case 'as400':
        image = as400;
        break;
      case 'Dynamics':
        image = Dynamics;
        break;
      case 'Facebook':
        image = Facebook;
        break;
      case 'Rest':
        image = Rest;
        break;
    }

    this.setState({
      selectedInterface: image
    });
  }

  render() {
    return (
      <div className="add-interface-wrapper">
        <div className="add-interface-header">
          <span className="bold">
            {messages['Connector.apiInterface.addInterface'] + ' ' + capitalizeFirst(this.props.channel)}
          </span>
        </div>
        <div className="add-interface-body">
          <div className="row api-selection">
            <div className="col-4">
              <div className="add-interface-body-channel">
                <i className={'icon-' + this.props.channel}></i><br />
                <span className="bold">{capitalizeFirst(this.props.channel)}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="add-interface-body-arrow">
                <i className="fas fa-long-arrow-alt-left"></i>
              </div>
            </div>
            <div className="col-4">
              <div className="add-interface-img-wrapper">
                <img className="add-interface-img" src={this.state.selectedInterface} />
              </div>
              <Select
                id="interface-select"
                theme={selectTheme}
                classNamePrefix="add-interface-select"
                options={this.state.interface}
                isSearchable={false}
                defaultValue={this.state.interface[0]}
                onChange={(e) => { this.changeInterface(e.value) }}
                isOptionDisabled={(option) => option.disabled}
              />
            </div>
          </div>
          <hr />
          <div className="row api-credentials-input">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="username">
                    {messages['Connector.apiInterface.username'] + ':'}
                  </label>
                </div>
                <div className="col-6">
                  <input
                    name="username"
                    placeholder={messages['Connector.apiInterface.usernameInput']}
                    className="interface-input"
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="password">
                    {messages['Connector.apiInterface.password'] + ':'}
                  </label>
                </div>
                <div className="col-6">
                  <input
                    name="password"
                    type="password"
                    placeholder={messages['Connector.apiInterface.passwordInput']}
                    className="interface-input"
                  ></input>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="token">
                    {messages['Connector.apiInterface.token'] + ':'}
                  </label>
                </div>
                <div className="col-6">
                  <input
                    name="token"
                    placeholder={messages['Connector.apiInterface.tokenInput']}
                    className="interface-input"
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="url">
                    {messages['Connector.apiInterface.url'] + ':'}
                  </label>
                </div>
                <div className="col-6">
                  <input
                    name="url"
                    placeholder={messages['Connector.apiInterface.urlInput']}
                    className="interface-input"
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-interface-footer">
          <button
            className="add-interface-button-close"
            onClick={this.props.onClose}
          >{messages['Connector.apiInterface.closeButtonText']}</button>
          <button
            className="add-interface-button-save"
            onClick={() => { this.notifySave() }}
          >{messages['Connector.apiInterface.saveButtonText']}</button>
        </div>
      </div >
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default AddInterface;