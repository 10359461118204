import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import { apiPath, globalErrorHandler, messages } from '../../reusableFunctions';

import { Link } from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';

export class UserSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      result: false,
      searchValue: false
    };
  };

  componentDidUpdate = () => {
    if (this.props.show !== this.state.show) {
      this.setState({
        show: this.props.show
      });
    }
  }

  showClass = 'out';

  handleUserSearch = (kind, value) => {
    if (kind === 'name') {

    } else if (kind === 'email') {
      this.searchByEmail(value);
    }
  }

  searchByEmail = (value) => {
    let that = this;

    if (typeof value === 'string') {
      fetch(apiPath + 'customers/search/email/' + value, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        that.setState({
          result: data
        });
      }).catch(function (error) {
        globalErrorHandler(error);
      });
    }
  }

  removeResult = () => {
    this.setState({
      result: false,
      searchValue: false
    });
  }

  getSearchValue = (e, value) => {
    this.setState({
      searchValue: value
    }, () => this.handleUserSearch('email', value));
  }

  render() {
    return (
      <CSSTransition
        classNames="fade"
        timeout={10}
        in={this.state.show}
      >
        <div className={(this.state.show ? 'in' : 'out') + " user-search-wrapper"}>
          <div className="menu-back-button" onClick={() => this.props.showHideUserSearch()}>
            <i className="fas fa-arrow-right"></i>
          </div>
          <span className="bold">
            <FormattedMessage
              id="UserSearch.userSearch.personSearch"
              defaultMessage="Personensuche"
            />:
            </span>
          <form action="javascript:void(0);">
            <label>
              <FormattedMessage
                id="UserSearch.userSearch.viaMail"
                defaultMessage="Per E-Mail Adresse"
              />
            </label>
            <SearchInput
              getSearchValue={this.getSearchValue}
              className="user-search-input"
            />
            {this.state.result ?
              <div>
                {(this.state.result.length > 0) ?
                  <ShowResult
                    result={this.state.result}
                  />
                  :
                  <div className="text-center" style={{ margin: '10px 0' }}>
                    <FormattedMessage
                      id="UserSearch.userSearch.noSearchResult"
                      defaultMessage="Kein Ergebnis zu Ihrer Suche"
                    />
                  </div>
                }
              </div>
              :
              <div>
                {this.state.searchValue ?
                  <div className="little-loading-droid"></div>
                  :
                  null}
              </div>
            }
          </form>
        </div>
      </CSSTransition >
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  interval = 1000;
  enterKey = 13;

  componentWillMount() {
    this.timer = null;
  }

  handleChange(value) {
    clearTimeout(this.timer);
    this.setState({ value }, () => {
      this.timer = setTimeout(() => this.triggerChange(this.state.value), this.interval);
    });
  }

  handleKeyDown(e) {
    if (e.keyCode === this.enterKey) {
      this.triggerChange(e.target.value);
    }
  }

  triggerChange(value) {
    this.setState({ value }, () => this.props.getSearchValue('email', this.state.value));
  }

  render() {
    const { className } = this.props;
    return (
      <input
        className={className}
        placeholder={messages['UserSearch.userSearch.mailPlaceholder']}
        value={this.state.value}
        onChange={(e) => this.handleChange(e.target.value)}
        onKeyDown={(e) => this.handleKeyDown(e)}
      />
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class ShowResult extends Component {

  constructor(props) {
    super(props);
    this.state = {
      result: [],
      resultReturn: 'no result',
      resultLimit: 10
    };
  };

  onLoadMore() {
    this.setState({
      resultLimit: this.state.resultLimit + 10
    }, () => this.gatherInformation());
  }

  gatherInformation = () => {
    let result = this.state.result;

    let resultReturn = (result.slice(0, this.state.resultLimit).map((res, index) =>
      <Link
        key={index}
        to={{
          pathname: '/user-profile/' + res.email.trim() + '/' + (res.shop ? res.shop : null)
        }}
      >
        <div key={index}>
          <span className="user-search-result-email">{res.email}</span>
          {res.shop ?
            <span className="user-search-result-shop">{', ' + res.shop}</span>
            :
            null}
          <br /><hr />
        </div>
      </Link>
    ));

    this.setState({
      resultReturn: resultReturn
    });
  }

  componentDidUpdate = () => {
    if (this.props.result !== this.state.result) {
      this.setState({
        result: this.props.result,
        resultLimit: 10
      }, () => this.gatherInformation());
    }
  }

  componentDidMount = () => {
    if (this.props.result !== this.state.result) {
      this.setState({
        result: this.props.result
      }, () => this.gatherInformation());
    }
  }


  render() {
    return (
      <div className="result-wrapper">
        {this.state.resultReturn}
        <div className="text-center">
          {(this.state.resultLimit < this.state.result.length) ?
            <div>
              <span>
                <FormattedMessage
                  id="UserSearch.userSearch.result"
                  defaultMessage="{resultLimit} von {resultLength} werden angezeigt"
                  values={{
                    resultLimit: this.state.resultLimit,
                    resultLength: this.state.result.length
                  }}
                />
              </span><br />
              <button className="user-search-button" onClick={() => this.onLoadMore()}>
                <FormattedMessage
                  id="UserSearch.userSearch.loadMore"
                  defaultMessage="Mehr laden"
                />
              </button>
            </div>
            :
            null}
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


export default UserSearch;