import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import {
  DataLineChart,
  DataProgressCircleChart,
  CategoriesPieChart,
  DataProgressBarChart,
  SingleTile
} from './prototypeCharts';
import { getDataProgressChartSet } from './ChartOptions';
import { getChartData } from './fetchData';
import {
  getLabelsAndData,
  globalErrorHandler,
  messages,
  apiPath,
  decimalAdjust,
} from '../../../reusableFunctions';
import { createChartsDatasetsProgressCircle, SingleChartWrapper } from '../Charts';

class NewsletterCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      click: false,
      send: false,
      optout: false,
      optin: false,
      optins: false,
      hardbounce: false,
      softbounce: false,
      optinsPercent: false,
      categoriesDataOpen: false,
      categoriesDataClick: false,
      dateFrom: null,
      dateTo: null,
      finishedLoading: false,
      returnCharts: false,
      monthly: false
    };
  };

  componentDidMount = () => { this.getData(); }

  componentDidUpdate = () => { this.changeDate(); }

  getData = () => {
    NProgress.start();
    let dateFrom = this.props.dateFrom;
    let dateTo = this.props.dateTo;
    let monthly = this.state.monthly;
    if (this.props.monthly) {
      monthly = this.props.monthly;
    }

    if (!this.state.finishedLoading) {
      this.getOptinStatusData();
      getChartData('newsletter', 'click', dateFrom, dateTo, monthly).then(
        response => this.setState({
          click: getLabelsAndData(response)
        }, () => {
          getChartData('newsletter', 'open', dateFrom, dateTo, monthly).then(
            response => this.setState({
              open: getLabelsAndData(response),
            }, () => {
              getChartData('newsletter', 'send', dateFrom, dateTo, monthly).then(
                response => this.setState({
                  send: getLabelsAndData(response),
                }, () => {
                  NProgress.set(0.2);
                  getChartData('newsletter', 'optin', dateFrom, dateTo, monthly).then(
                    response => this.setState({
                      optin: getLabelsAndData(response)
                    }, () => {
                      getChartData('newsletter', 'optout', dateFrom, dateTo, monthly).then(
                        response => this.setState({
                          optout: getLabelsAndData(response),
                        }, () => {
                          NProgress.set(0.4);
                          getChartData('bounces', 'hardbounce', dateFrom, dateTo, monthly).then(
                            response => this.setState({
                              hardbounce: getLabelsAndData(response)
                            }, () => {
                              getChartData('bounces', 'softbounce', dateFrom, dateTo, monthly).then(
                                response => this.setState({
                                  softbounce: getLabelsAndData(response),
                                  finishedLoading: true
                                }, () => {
                                  this.getCategoryData();
                                  NProgress.set(0.6);
                                })
                              ).catch(function (error) {
                                globalErrorHandler(error);
                                removeTokenSession();
                              })
                            })
                          ).catch(function (error) {
                            globalErrorHandler(error);
                            removeTokenSession();
                          })
                        })
                      ).catch(function (error) {
                        globalErrorHandler(error);
                        removeTokenSession();
                      })
                    })
                  ).catch(function (error) {
                    globalErrorHandler(error);
                    removeTokenSession();
                  })
                })
              ).catch(function (error) {
                globalErrorHandler(error);
                removeTokenSession();
              })
            })
          ).catch(function (error) {
            globalErrorHandler(error);
            removeTokenSession();
          })
        })
      ).catch(function (error) {
        globalErrorHandler(error);
        removeTokenSession();
      });
    }
  }

  changeMonthly = (bool) => {
    if (bool !== this.state.monthly) {
      this.setState({
        monthly: !this.state.monthly,
      }, () => this.reloadData());
    }
  }

  getOptinStatusData = () => {
    let that = this;
    fetch(apiPath + 'data/newsletteroptin', {
      method: 'GET',
      mode: 'cors',
      dataType: 'json',
      headers: {
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.message !== '') {
        let percentage = decimalAdjust('floor', (data.optin / data.total) * 100, -1);
        that.setState({
          optins: data,
          optinsPercent: percentage
        });
      } else {
        that.setState({ optins: false })
      }
    }).catch(function (error) {
      console.log(error);
      that.setState({ optins: false })
    });
  }

  getCategoryData = () => {
    let categories = this.props.categories;

    let dateFrom = this.props.dateFrom;
    let dateTo = this.props.dateTo;

    this.intermediate = [];

    let monthly = this.state.monthly;
    if (this.props.monthly) {
      monthly = this.props.monthly;
    }

    categories = false; // for development

    if (categories) {
      for (let i in categories) {
        getChartData('newsletter', 'open/' + categories[i], dateFrom, dateTo, monthly).then(
          response => this.saveIntermediateOpen(response, categories[i], i, categories.length)
        );
        getChartData('newsletter', 'click/' + categories[i], dateFrom, dateTo, monthly).then(
          response => this.saveIntermediateClick(response, categories[i], i, categories.length)
        );
      }
    } else {
      this.constructCharts();
    }
  }

  intermediateOpen = [];
  intermediateClick = [];

  saveIntermediateOpen = (response, category, i, catLength) => {
    this.intermediateOpen[category] = getLabelsAndData(response);
    let intermediateLength = Object.keys(this.intermediateOpen).length;

    if (intermediateLength === catLength) {
      if (this.state.categoriesDataOpen !== this.intermediateOpen) {
        this.setState({
          categoriesDataOpen: this.intermediateOpen
        }, () => this.doConstructCharts());
      }
    }
  }

  saveIntermediateClick = (response, category, i, catLength) => {
    this.intermediateClick[category] = getLabelsAndData(response);
    let intermediateLength = Object.keys(this.intermediateClick).length;

    if (intermediateLength === catLength) {
      if (this.state.categoriesDataClick !== this.intermediateClick) {
        this.setState({
          categoriesDataClick: this.intermediateClick
        }, () => this.doConstructCharts());
      }
    }
  }

  doConstructCharts = () => {
    if (this.state.categoriesDataClick !== false && this.state.categoriesDataOpen !== false) {
      this.constructCharts();
      NProgress.inc(0.8);
    }
  }

  changeDate = () => {
    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      });
    } else {
      if (
        (this.props.dateFrom.format('YYYY-MM-DD') !== this.state.dateFrom.format('YYYY-MM-DD')) ||
        (this.props.dateTo.format('YYYY-MM-DD') !== this.state.dateTo.format('YYYY-MM-DD'))
      ) {
        this.setState({
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo
        }, () => this.reloadData());
      }
    }
  }

  reloadData = () => {
    this.intermediateOpen = [];
    this.intermediateClick = [];
    this.setState({
      finishedLoading: false,
      categoriesDataOpen: false,
      categoriesDataClick: false
    }, () => this.getData());
  }

  constructCharts = () => {
    let currentDataProgressChartOpen = getDataProgressChartSet();
    currentDataProgressChartOpen = createChartsDatasetsProgressCircle(
      this.state.open.data,
      <FormattedMessage id="Data.charts.newsletter.open" defaultMessage="Öffnen" />,
      currentDataProgressChartOpen
    );
    currentDataProgressChartOpen = createChartsDatasetsProgressCircle(
      this.state.send.data,
      <FormattedMessage id="Data.charts.newsletter.sent" defaultMessage="Versendet" />,
      currentDataProgressChartOpen
    );

    let currentDataProgressChartClick = getDataProgressChartSet();
    currentDataProgressChartClick = createChartsDatasetsProgressCircle(
      this.state.click.data,
      <FormattedMessage id="Data.charts.newsletter.click" defaultMessage="Klick" />,
      currentDataProgressChartClick
    );
    currentDataProgressChartClick = createChartsDatasetsProgressCircle(
      this.state.send.data,
      <FormattedMessage id="Data.charts.newsletter.sent" defaultMessage="Versendet" />,
      currentDataProgressChartClick
    );

    let currentDataProgressChartProtoAllSend = getDataProgressChartSet();
    currentDataProgressChartProtoAllSend = createChartsDatasetsProgressCircle(
      this.state.send.data,
      <FormattedMessage id="Data.charts.newsletter.sentNewsletter" defaultMessage="Versendete Newsletter" />,
      currentDataProgressChartProtoAllSend
    );

    let currentDataProgressChartCatClick = getDataProgressChartSet();
    for (let i in this.state.categoriesDataClick) {
      currentDataProgressChartCatClick = createChartsDatasetsProgressCircle(
        this.state.categoriesDataClick[i].data,
        i,
        currentDataProgressChartCatClick
      );
    }

    let currentDataProgressChartProtoOptStatus = getDataProgressChartSet();
    currentDataProgressChartProtoOptStatus = createChartsDatasetsProgressCircle(
      this.state.optin.data,
      <FormattedMessage id="Data.charts.newsletter.optins" defaultMessage="Optins" />,
      currentDataProgressChartProtoOptStatus
    );
    currentDataProgressChartProtoOptStatus = createChartsDatasetsProgressCircle(
      this.state.optout.data,
      <FormattedMessage id="Data.charts.newsletter.optouts" defaultMessage="Optouts" />,
      currentDataProgressChartProtoOptStatus
    );

    let currentDataProgressChartBounces = getDataProgressChartSet();
    currentDataProgressChartBounces = createChartsDatasetsProgressCircle(
      this.state.hardbounce.data,
      'Hardbounce',
      currentDataProgressChartBounces
    );
    currentDataProgressChartBounces = createChartsDatasetsProgressCircle(
      this.state.softbounce.data,
      'Softbounce',
      currentDataProgressChartBounces
    );

    let returnChartsNewsletter = <div>
      <SingleChartWrapper
        rightChart={
          <DataProgressCircleChart
            topText={<FormattedMessage id="Data.charts.newsletter.averageOpenRate" defaultMessage="Durchschnittliche Öffnungsrate" />}
            data={currentDataProgressChartOpen}
            bottomText={<FormattedMessage id="Data.charts.newsletter.averageOpenRate" defaultMessage="Durchschnittliche Öffnungsrate" />}
          />
        }
        lineChart={
          <DataLineChart
            labels={[this.state.open.labels, this.state.send.labels]}
            names={[messages['Data.charts.newsletter.open'], messages['Data.charts.newsletter.sent']]}
            data={[this.state.open.data, this.state.send.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      <SingleChartWrapper
        rightChart={
          <DataProgressCircleChart
            topText={<FormattedMessage id="Data.charts.newsletter.averageClickRate" defaultMessage="Durchschnittliche Klickrate" />}
            data={currentDataProgressChartClick}
            bottomText={<FormattedMessage id="Data.charts.newsletter.averageClickRate" defaultMessage="Durchschnittliche Klickrate" />}
          />
        }
        lineChart={
          <DataLineChart
            labels={[this.state.click.labels, this.state.send.labels]}
            names={[messages['Data.charts.newsletter.click'], messages['Data.charts.newsletter.sent']]}
            data={[this.state.click.data, this.state.send.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      {this.state.categoriesDataOpen ?
        <SingleChartWrapper
          rightChart={
            <CategoriesPieChart
              topText={<FormattedMessage id="Data.charts.newsletter.averageOpenByCategories" defaultMessage="Durchschnittliche Öffnungen nach Kategorien" />}
              data={this.state.categoriesDataOpen}
            />
          }
          lineChart={
            <DataLineChart
              dataArray={this.state.categoriesDataOpen}
              stateMonthly={this.state.monthly}
              changeMonthly={this.changeMonthly}
            />
          }
        />
        : null}
      {this.state.categoriesDataClick ?
        <SingleChartWrapper
          rightChart={
            <CategoriesPieChart
              topText={<FormattedMessage id="Data.charts.newsletter.averageClicksByCategories" defaultMessage="Durchschnittliche Klicks nach Kategorien" />}
              data={this.state.categoriesDataClick}
            />
          }
          lineChart={
            <DataLineChart
              dataArray={this.state.categoriesDataClick}
              stateMonthly={this.state.monthly}
              changeMonthly={this.changeMonthly}
            />
          }
        />
        : null}
      <SingleChartWrapper
        rightChart={
          <DataProgressBarChart
            topText={<FormattedMessage id="Data.charts.newsletter.altogetherSentNewsletter" defaultMessage="Insgesamt versendete Newsletter" />}
            data={currentDataProgressChartProtoAllSend}
          />
        }
        lineChart={
          <DataLineChart
            labels={[this.state.send.labels]}
            names={[messages['Data.charts.newsletter.sentNewsletter']]}
            data={[this.state.send.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      {this.state.optout.data[0] !== 'error' ?
        <SingleChartWrapper
          rightChart={
            <DataProgressBarChart
              topText={[messages['Data.charts.newsletter.optinOptoutStatus']]}
              data={currentDataProgressChartProtoOptStatus}
            />
          }
          lineChart={
            <DataLineChart
              labels={[this.state.optin.labels, this.state.optout.labels]}
              names={['Data.charts.newsletter.optins', 'Data.charts.newsletter.optouts']}
              data={[this.state.optin.data, this.state.optout.data]}
              stateMonthly={this.state.monthly}
              changeMonthly={this.changeMonthly}
            />
          }
        />
        : null}
      <SingleChartWrapper
        rightChart={
          <DataProgressBarChart
            topText={'Bounces'}
            data={currentDataProgressChartBounces}
          />
        }
        lineChart={
          <DataLineChart
            labels={[this.state.hardbounce.labels, this.state.softbounce.labels]}
            names={['Hardbounce', 'Softbounce']}
            data={[this.state.hardbounce.data, this.state.softbounce.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
    </div>;

    this.setState({
      returnCharts: returnChartsNewsletter
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {(this.state.finishedLoading && this.state.dateFrom !== null) ?
          <div>
            <div>{this.state.returnCharts}</div>
            {this.state.optins ?
              <OptinCount data={this.state.optins} percent={this.state.optinsPercent} />
              : null}
          </div>
          :
          <div className="text-center">
            <span className="bold">
              <FormattedMessage
                id="Data.charts.newsletter.loadingCharts"
                defaultMessage="Charts werden geladen"
              />
            </span>
          </div>
        }
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

const OptinCount = (props) => {
  return (
    <div>
      <span className="crm-card-header-text">
        <FormattedMessage
          id="Data.charts.newsletter.optinStatus.headerText"
          defaultMessage="Optin Status"
        />:
      </span>
      <div className="row crm-card-wrapper shadow">
        <SingleTile
          text={[messages['Data.charts.newsletter.optinStatus.allCustomers']]}
          number={props.data.total}
        />
        <SingleTile
          text={[messages['Data.charts.newsletter.optinStatus.customersWithOptin']]}
          number={
            props.data.optin + ' (' + props.percent + '%)'
          }
        />
      </div>
    </div>
  )
}

export default NewsletterCharts;