import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';
import Modal from 'react-modal';
import Switch from 'rc-switch';

import {
  apiPath,
  globalErrorHandler,
  capitalizeFirst,
  messages,
  dateFromDatettime
} from '../../reusableFunctions';
import { Timeline } from './Timeline';
import { DownloadGDPR } from './GDPR';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../Data/Data.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDesktop, faLaptop, faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
library.add(faDesktop, faLaptop, faArrowLeft, faTimes);

class UserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mail: false,
      shop: false,
      customer: false,
      timeline: false,
      showGDPR: false
    };
  };

  componentWillUnmount = () => this.setState({ showGDPR: false });

  componentWillMount = () => {
    if (this.props.match.params) {
      if (this.props.match.params.email !== undefined) { // && this.props.match.params.shop !== undefined
        this.setState({
          mail: this.props.match.params.email,
          shop: this.props.match.params.shop
        }, () => this.getUserInformation());
      }
    }
  }

  getUserInformation = () => {
    let that = this;
    NProgress.start();

    if (!this.state.customer && !this.state.timeline) {
      fetch(apiPath + 'customers/profile/' + this.state.mail + '/' + this.state.shop, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        that.setState({
          customer: data.customer,
          timeline: data.timeline,
        }, () => NProgress.done());
      }).catch(function (error) {
        globalErrorHandler(error);
      });
    }
  }

  generateGDPR = () => {
    this.setState({
      showGDPR: true
    });
  }

  render() {
    return (
      <div>
        {!this.state.mail && !this.state.shop ?
          <span>
            <FormattedMessage
              id="UserSearch.userProfile.noUserSelected"
              defaultMessage="No User Selected"
            />
          </span>
          :
          <div className="user-profile">
            {!this.state.customer ? '' :
              <div>
                <UserProfileHeader
                  customer={this.state.customer}
                  timeline={this.state.timeline}
                  showUserSearch={this.state.showUserSearch}
                  showHideUserSearch={this.showHideUserSearch}
                />
              </div>
            }
            {!this.state.timeline ?
              ''
              :
              <div>
                <Timeline
                  timeline={this.state.timeline}
                  customer={this.state.customer}
                  mail={this.state.mail}
                  shop={this.state.shop}
                  showGDPR={this.state.showGDPR}
                  generateGDPR={this.generateGDPR}
                />
              </div>
            }
          </div>
        }
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}



const UserProfileHeader = (props) => {

  const notKnown = <FormattedMessage id="UserSearch.userProfile.notKnown" defaultMessage="Nicht bekannt" />;
  let customer = props.customer;

  let address = (customer.country ? customer.country + ', ' : '') + (customer.city ? customer.city + ', ' : '') + (customer.zip ? customer.zip + ', ' : '') + (customer.street ? customer.street : '') + (customer.address ? customer.address + ', ' : '');
  (address.length === 0) ? address = notKnown : address = address;

  return (
    <div className="user-profile-header-wrapper">
      <div className="row">
        <div className="col-10 col-sm-11">
          {(customer.lastname && customer.firstname) ?
            <div className="user-profile-firstname-lastname">
              <span>{customer.lastname}</span><span>{' ' + customer.firstname}</span>
            </div>
            :
            <div className="user-profile-firstname-lastname">
              <span>{customer.email}</span>
            </div>
          }
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="user-profile-header-other-info">
                <dl className="row">
                  <dt className="col-sm-12 col-md-4">
                    <span className="bold">
                      <FormattedMessage
                        id="UserSearch.userProfile.mail"
                        defaultMessage="E-Mail"
                      />:
                    </span>
                  </dt>
                  <dd className="col-sm-12 col-md-8"><span>{customer.email}</span></dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-12 col-md-4"><span className="bold">
                    <FormattedMessage
                      id="UserSearch.userProfile.newsletterOptin"
                      defaultMessage="Newsletter Optin"
                    />:
                    </span>
                  </dt>
                  <dd className="col-sm-12 col-md-8">
                    <span>{(customer.optin === '1') ? messages['UserSearch.userProfile.yes'] : messages['UserSearch.userProfile.no']}</span>
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-12 col-md-4"><span className="bold">
                    <FormattedMessage
                      id="UserSearch.userProfile.birthday"
                      defaultMessage="Geburtstag"
                    />:
                    </span>
                  </dt>
                  <dd className="col-sm-12 col-md-8">
                    <span>{customer.birthday ? customer.birthday : notKnown}</span>
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-12 col-md-4"><span className="bold">
                    <FormattedMessage
                      id="UserSearch.userProfile.residence"
                      defaultMessage="Wohnort"
                    />:
                    </span>
                  </dt>
                  <dd className="col-sm-12 col-md-8">
                    <span>{address}</span>
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-12 col-md-4"><span className="bold">
                    <FormattedMessage
                      id="UserSearch.userProfile.company"
                      defaultMessage="Firma"
                    />:
                    </span>
                  </dt>
                  <dd className="col-sm-12 col-md-8">
                    <span>{customer.firma ? customer.firma : customer.company ? customer.company : notKnown}</span>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="user-profile-header-other-info">
                <dl className="row">
                  <dt className="col-sm-12 col-md-4"><span className="bold">
                    <FormattedMessage
                      id="UserSearch.userProfile.telephoneNumber"
                      defaultMessage="Telefonnummer"
                    />:
                    </span>
                  </dt>
                  <dd className="col-sm-12 col-md-8">
                    <span>{customer.phone ? customer.phone : notKnown}</span>
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-12 col-md-4"><span className="bold">
                    <FormattedMessage
                      id="UserSearch.userProfile.smsOptin"
                      defaultMessage="SMS Optin"
                    />:
                    </span>
                  </dt>
                  <dd className="col-sm-12 col-md-8">
                    <span> {(customer['optin-sms'] === '1') ? messages['UserSearch.userProfile.yes'] : messages['UserSearch.userProfile.no']}</span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 col-sm-1 text-right">
          <i onClick={() => window.history.back()} className="fas fa-arrow-left"></i><br />
        </div>
      </div>
    </div >
  )
}


export const CustomerInfo = (props) => {
  let data = props.data;
  let interests = <FormattedMessage id="UserSearch.userProfile.noInformation" defaultMessage="Keine Information" />;
  if (data.interests) {
    interests = (data.interests.map((interest, index) => {
      if (interest !== "false") {
        return (
          <li key={index} className="single-interest">
            <span>{interest}</span>
          </li>
        )
      }
    }
    ));
  }

  let optoutDate = data['optout-date'];
  if (optoutDate === "0000-00-00 00:00:00" || optoutDate === "" || !optoutDate) {
    optoutDate = false;
  } else {
    optoutDate = dateFromDatettime(optoutDate);
  }

  let customerSince = '';
  if (data.created_at) {
    customerSince = dateFromDatettime(data.created_at);
  }

  return (
    <div className="user-info-float-wrapper shadow">
      <span className="bold" stlye={{ fontSize: '1.3em' }}>
        <FormattedMessage
          id="UserSearch.userProfile.informations"
          defaultMessage="Informationen"
        />:
      </span>
      <br />
      <div>
        {data.shop ?
          <dl className="row">
            <dt className="col-sm-6"><span>
              <FormattedMessage
                id="UserSearch.userProfile.shop"
                defaultMessage="Shop"
              />:
              </span>
            </dt>
            <dd className="col-sm-6"><span>{data.shop}</span></dd>
          </dl>
          :
          null}
      </div>
      <div>
        {customerSince ?
          <dl className="row">
            <dt className="col-sm-6"><span>
              <FormattedMessage
                id="UserSearch.userProfile.customerSince"
                defaultMessage="Kunde seit"
              />:
              </span>
            </dt>
            <dd className="col-sm-6"><span>{customerSince}</span></dd>
          </dl>
          :
          null}
      </div>
      <div>
        {data.bonus ?
          <dl className="row">
            <dt className="col-sm-6"><span>
              <FormattedMessage
                id="UserSearch.userProfile.bonus"
                defaultMessage="Bonus"
              />:
              </span>
            </dt>
            <dd className="col-sm-6"><span>{data.bonus}</span></dd>
          </dl>
          :
          null}
      </div>
      <div>
        {data.kundengruppe ?
          <dl className="row">
            <dt className="col-sm-6"><span>
              <FormattedMessage
                id="UserSearch.userProfile.customerGroup"
                defaultMessage="Kundengruppe"
              />:
              </span>
            </dt>
            <dd className="col-sm-6"><span>{data.kundengruppe}</span></dd>
          </dl>
          :
          null}
        {optoutDate ?
          <dl className="row">
            <dt className="col-sm-6"><span>
              Optout-Datum:
              </span>
            </dt>
            <dd className="col-sm-6"><span>{optoutDate}</span></dd>
          </dl>
          :
          null}
      </div>
      <UserProfileModal
        customer={data}
        timeline={props.timeline}
      />
      {props.showGDPR ?
        <DownloadGDPR
          customer={props.data}
          timeline={props.timeline}
          showGDPR={props.showGDPR}
        />
        :
        <button
          className="user-profile-info-button gdpr"
          onClick={() => { props.generateGDPR() }}
          style={{ pointer: 'cursor' }}
        >
          <FormattedMessage
            id="UserSearch.userProfile.generatingDocument"
            defaultMessage="Generiere DSGVO Dokument"
          />
        </button>
      }
      <UserOptinSwitches
        mail={props.mail}
        shop={props.shop}
        newsletter={Boolean(Number(data.optin))}
        sms={Boolean(Number(data['optin-sms'] ? data['optin-sms'] : data['sms_optin']))}
        banner={Boolean(Number(data['optin-banner'] ? data['optin-banner'] : data['banner_optin']))}
        direct={Boolean(Number(data['optin-direct'] ? data['optin-direct'] : data['direct_optin']))}
        lookalike={Boolean(Number(data['optin-lookalike'] ? data['optin-lookalike'] : data['lookalike_optin']))}
        social={Boolean(Number(data['optin-social'] ? data['optin-social'] : data['social_optin']))}
      />
      <span className="bold">
        <FormattedMessage
          id="UserSearch.userProfile.interests"
          defaultMessage="Interessen"
        />:
      </span>
      <div className="interests">
        <ul>
          {interests}
        </ul>
      </div>
    </div >
  )
}

class UserOptinSwitches extends Component {

  state = {
    disabled: false,
    newsletter: this.props.newsletter,
    sms: this.props.sms,
    banner: this.props.banner,
    print: this.props.print
  }

  setOptinState = (kind, value) => {
    switch (kind) {
      case 'newsletter':
        this.setState({
          newsletter: value
        }, () => this.notifyOptinOut(kind));
        break;
      case 'sms':
        this.setState({
          sms: value
        }, () => this.notifyOptinOut(kind));
        break;
      case 'banner':
        this.setState({
          banner: value
        }, () => this.notifyOptinOut(kind));
        break;
      case 'print':
        this.setState({
          print: value
        }, () => this.notifyOptinOut(kind));
        break;
      default:
        return;
        break;
    }
  }

  notifyOptinOut = (kind) => toast(capitalizeFirst(kind) + ' ' + messages['UserSearch.userProfile.optinSuccessfullyUpdated'], {
    className: 'data-notify',
    autoClose: 3000,
    progressClassName: 'data-notify-progressbar'
  });

  optinOut = (kind, value) => {
    let that = this;

    let status = "";
    if (value) {
      status = '1';
    } else {
      status = '0'
    }

    fetch(apiPath + 'customers/optin/' + kind + '/' + this.props.mail + '/' + this.props.shop + '/' + status, {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      that.setOptinState(kind, value);
    }).catch(function (error) {
      globalErrorHandler(error);
    });
  }

  render() {
    return (
      <div className="switch-wrapper-data">
        <ToastContainer />
        <dl className="row">
          <dt className="col-sm-12 col-md-6">
            <span>
              <FormattedMessage
                id="UserSearch.userProfile.newsletterOptin"
                defaultMessage="Newsletter Optin"
              />:
            </span>
          </dt>
          <dd className="col-sm-12 col-md-6">
            <Switch
              onChange={(e) => { this.optinOut('newsletter', e) }}
              disabled={this.state.disabled}
              defaultChecked={this.state.newsletter}
            />
          </dd>
        </dl>
        <br />
        <dl className="row">
          <dt className="col-sm-12 col-md-6">
            <span>
              <FormattedMessage
                id="UserSearch.userProfile.smsOptin"
                defaultMessage="SMS Optin"
              />:
              </span>
          </dt>
          <dd className="col-sm-12 col-md-6">
            <Switch
              onChange={(e) => { this.optinOut('sms', e) }}
              disabled={this.state.disabled}
              defaultChecked={this.state.sms}
            />
          </dd>
        </dl>
        <br />
        <dl className="row">
          <dt className="col-sm-12 col-md-6">
            <span>
              <FormattedMessage
                id="UserSearch.userProfile.bannerOptin"
                defaultMessage="Banner Optin"
              />:
            </span>
          </dt>
          <dd className="col-sm-12 col-md-6">
            <Switch
              onChange={(e) => { this.optinOut('banner', e) }}
              disabled={this.state.disabled}
              defaultChecked={this.state.banner}
            />
          </dd>
        </dl>
        <br />
        <dl className="row">
          <dt className="col-sm-12 col-md-6">
            <span>
              <FormattedMessage
                id="UserSearch.userProfile.printOptin"
                defaultMessage="Print Optin"
              />:
            </span>
          </dt>
          <dd className="col-sm-12 col-md-6">
            <Switch
              onChange={(e) => { this.optinOut('print', e) }}
              disabled={this.state.disabled}
              defaultChecked={this.state.print}
            />
          </dd>
        </dl>
      </div>
    )
  }
}

class UserProfileModal extends Component {

  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };
  }

  customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80%',
      maxHeight: '80%'
    }
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal = () => {
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  createTable = () => {
    let table = [];
    for (let key in this.props.customer) {
      if (this.props.customer.hasOwnProperty(key)) {
        if (this.props.customer[key] !== null) {
          table.push(
            <tr key={key}>
              <td>{key}</td>
              <td>{this.props.customer[key].toString().substring(0, 120)}</td>
            </tr>
          )
        }
      }
    }
    return table;
  }

  render() {
    return (
      <div>
        <button className="user-profile-info-button" onClick={this.openModal}>
          <FormattedMessage
            id="UserSearch.userProfile.customerInformation.buttonText"
            defaultMessage="Kundeninformationen"
          />
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={this.customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="row">
            <div className="col">
              <h2><FormattedMessage id="UserSearch.userProfile.customerInformation.collectiveCustomerInformation" defaultMessage="Gesamte Kundeninformationen" />
                <i className="fas fa-times" style={{ float: 'right' }} onClick={this.closeModal}></i>
              </h2>
            </div>
          </div>
          <table className="user-profile-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="UserSearch.userProfile.customerInformation.key"
                    defaultMessage="Schlüssel"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="UserSearch.userProfile.customerInformation.value"
                    defaultMessage="Wert"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.createTable()}
            </tbody>
          </table>
        </Modal>
      </div>
    );
  }
}

export default UserProfile;