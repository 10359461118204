import React, { Component } from 'react';


import { apiPath, messages } from '../../reusableFunctions';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import Select from 'react-select';

import A4Example from '../../pictures/d2p-example/a4_briefpapier.jpg';
import A6ExampleFront from '../../pictures/d2p-example/a6_postkarte_front.jpg';
import A6ExampleBack from '../../pictures/d2p-example/a6_postkarte_back.jpg';

class DirectPrintUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uploaded: false,
      loading: false,
      error: false,
      printOptions: [
        { type: 'print-option', disabled: false, value: 'A4SXLD', label: messages['Journey.directPrintUpload.printOption.A4SXLD'] },
        { type: 'print-option', disabled: false, value: 'A4DXLD', label: messages['Journey.directPrintUpload.printOption.A4DXLD'] },
        { type: 'print-option', disabled: false, value: 'A4SXC5', label: messages['Journey.directPrintUpload.printOption.A4SXC5'] },
        { type: 'print-option', disabled: false, value: 'A4DXC5', label: messages['Journey.directPrintUpload.printOption.A4DXC5'] },
        { type: 'print-option', disabled: false, value: 'A5DX', label: messages['Journey.directPrintUpload.printOption.A5DX'] }
      ]
    };
  };

  showPDFExample = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-pdf-example">
            <h1>{messages['Journey.directPrintUpload.examplePDF.headerText']}</h1>
            <h3>{messages['Journey.directPrintUpload.examplePDF.A4'] + ':'}</h3>
            <a href={A4Example} download>
              <img src={A4Example} alt="A4-example"></img>
            </a>
            <h3>{messages['Journey.directPrintUpload.examplePDF.A6frontPage'] + ':'}</h3>
            <a href={A6ExampleFront} download>
              <img src={A6ExampleFront} alt="A6-example"></img>
            </a>
            <h3>{messages['Journey.directPrintUpload.examplePDF.A6backPage'] + ':'}</h3>
            <a href={A6ExampleBack} download>
              <img src={A6ExampleBack} alt="A6-example"></img>
            </a>
            <button className="journey-channel-select__control" onClick={onClose}>{messages['Journey.directPrintUpload.examplePDF.doneButtonText']}</button>
          </div>
        );
      }
    });
  }

  onDrop = (acceptedFiles) => {

    this.setState({ loading: true });

    let senddata = new FormData();
    senddata.append('file', acceptedFiles[0]);

    let that = this;
    fetch(apiPath + 'journey/saveprint', {
      method: 'post',
      headers: {
        'cache-control': 'no-cache',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: senddata
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.success) {
        that.setState({
          loading: false,
          uploaded: true
        }, () => that.props.getChange('template-print', data.path, that.props.num));
      } else {
        that.setState({ error: true });
      }
    }).catch(function (error) {
      console.log(error);
      that.setState({ error: true });
    });
  }

  render() {
    return (
      <div className="pdf-upload-wrapper">
        <button
          className="pdf-example journey-channel-select__control"
          onClick={() => this.showPDFExample()}
        >{messages['Journey.directPrintUpload.examplePDF.buttonText']}</button>
        <Dropzone
          accept="application/pdf"
          onDrop={this.onDrop}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames('dropzone', { 'dropzone--isActive': isDragActive }) + ' pdf-upload-dropzone'}
              >
                <input {...getInputProps()} disabled={this.state.uploaded} />
                {this.state.loading ?
                  <p>{messages['Journey.directPrintUpload.PDFupload.loading'] + '...'}</p>
                  :
                  <div>
                    {this.state.uploaded ?
                      <p style={{ color: '#067572', fontWeight: 600 }}>{messages['Journey.directPrintUpload.PDFupload.uploaded']}</p>
                      :
                      <div>
                        {this.state.error ?
                          <p style={{ color: '#B30943', fontWeight: 600 }}>{messages['Journey.directPrintUpload.PDFupload.error']}</p>
                          :
                          <div>
                            {
                              isDragActive ?
                                <p>{messages['Journey.directPrintUpload.PDFupload.dropFile'] + '...'}</p> :
                                <p>{messages['Journey.directPrintUpload.PDFupload.buttonText']}</p>
                            }
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            )
          }}
        </Dropzone>
        <Select
          id={"print-pdf-options-" + this.props.num}
          theme={this.props.selectTheme}
          classNamePrefix="journey-channel-select"
          options={this.state.printOptions}
          isSearchable={false}
          defaultValue={this.state.printOptions[0]}
          onChange={(e) => { this.props.getChange('template-print-size', e.value, this.props.num) }}
          isOptionDisabled={(option) => option.disabled}
        />
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default DirectPrintUpload;