import React, { Component } from 'react';


import NProgress from 'nprogress';

import { DataLineChart, DataProgressBarChart } from '../../Data/Charts/prototypeCharts';
import { getDataProgressChartSet } from '../../Data/Charts/ChartOptions';
import { getChartDataReports } from '../../Data/Charts/fetchData';
import { getLabelsAndData, messages, decimalAdjust } from '../../../reusableFunctions';
import { createChartsDatasetsProgressCircle, SingleChartWrapperReports } from '../../Data/Charts';

class NewsletterChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      monthly: true,
      open: false,
      click: false,
      send: false,
      dateFrom: false,
      dateTo: false,
      returnCharts: []
    };
  };

  componentWillMount = () => {
    this.changeDate();
  }

  componentDidUpdate = () => { this.changeDate(); }

  currentDataProgressChartProto = [];
  currentDataLineChartProto = [];

  changeDate = () => {

    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      });
    } else {
      if (
        (this.props.dateFrom !== this.state.dateFrom) ||
        (this.props.dateTo !== this.state.dateTo)
      ) {
        this.setState({
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo
        }, () => this.collectData());
      }
    }
  }

  collectData = () => {

    let dateFrom = this.state.dateFrom;
    let dateTo = this.state.dateTo;

    getChartDataReports('newsletter', 'click', dateFrom, dateTo, this.props.step.id).then(
      response => this.setState(
        { click: getLabelsAndData(response) },
        () => getChartDataReports('newsletter', 'open', dateFrom, dateTo, this.props.step.id).then(
          response => this.setState(
            {
              open: getLabelsAndData(response),
            }, () => getChartDataReports('newsletter', 'send', dateFrom, dateTo, this.props.step.id).then(
              response => this.setState(
                {
                  send: getLabelsAndData(response),
                  finishedLoading: true
                }, () => { this.constructChart() && NProgress.set(0.4) }
              )
            ).catch(function (error) {
              globalErrorHandler(error);
              removeTokenSession();
            })
          )
        ).catch(function (error) {
          globalErrorHandler(error);
          removeTokenSession();
        })
      )
    ).catch(function (error) {
      globalErrorHandler(error);
      removeTokenSession();
    });
  }

  constructChart = () => {
    this.currentDataProgressChartProto = [];
    this.currentDataProgressChartProto = getDataProgressChartSet();
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.open.data,
      messages['Reports.charts.newsletter.open'],
      this.currentDataProgressChartProto
    );
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.click.data,
      messages['Reports.charts.newsletter.click'],
      this.currentDataProgressChartProto
    );
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.send.data,
      messages['Reports.charts.newsletter.sent'],
      this.currentDataProgressChartProto
    );

    let values = this.currentDataProgressChartProto.values;
    values.map((value, index) => {
      if (index === 0 || index === 1) {
        let percent = decimalAdjust('floor', (value / values[2]) * 100, -1);
        if (!Number.isNaN(percent)) {
          values[index] = value + ' (' + percent + '%)';
        }
      }
    });

    let templateName = (this.props.step.template !== null) ? (" - " + this.props.step.template.name) : '';
    if (this.props.abJourney && this.props.abJourneyIndex > 0 && this.props.step.template_ab) {
      templateName = (this.props.step.template_ab !== null) ? (" - " + this.props.step.template_ab.name) : '';
    }

    let returnCharts = <SingleChartWrapperReports
      rightChart={<DataProgressBarChart
        topText={"Newsletter" + templateName}
        data={this.currentDataProgressChartProto}
        hiddenProgress={true}
        reports={true}
      />}
      lineChart={
        <DataLineChart
          labels={[this.state.open.labels, this.state.click.labels, this.state.send.labels]}
          names={[messages['Reports.charts.newsletter.open'], messages['Reports.charts.newsletter.click'], messages['Reports.charts.newsletter.sent']]}
          data={[this.state.open.data, this.state.click.data, this.state.send.data]}
        />
      }
    />

    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {this.state.returnCharts}
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default NewsletterChart;