import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import { Line, Doughnut, Bar } from 'react-chartjs-2';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';

import { numberWithPoints, decimalAdjust, colorArray } from '../../../reusableFunctions';
import { LineOptions, PieOptions, BarOptions, getDataPieChartSet, getDataChartProto } from './ChartOptions';
import { createChartsDatasetsPie, createChartsDatasetsLine } from '../Charts';

export class DataLineChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DataChartProto: getDataChartProto(),
      DataChart: false
    };
  };

  componentWillMount = () => { this.createChart(); }

  componentDidUpdate = () => {
    let condition;
    if (this.props.labels) {
      condition = (this.props.stateMonthly !== this.state.monthly) || (this.props.labels[0] !== this.state.firstLabels);
    } else if (this.props.dataArray) {
      let key = Object.keys(this.props.dataArray)[0];
      condition = (this.props.stateMonthly !== this.state.monthly) || (this.props.dataArray[key].labels !== this.state.firstLabels)
    }
    if (condition) {
      this.setState({
        DataChartProto: getDataChartProto(),
        DataChart: false
      }, () => this.createChart());
    }
  }

  createStandardChart = () => {
    let currentDataLineChart;
    for (let i in this.props.labels) {
      currentDataLineChart = createChartsDatasetsLine(
        this.props.labels[i],
        this.props.names[i],
        this.props.color ? this.props.color : colorArray[i],
        this.props.data[i],
        this.state.DataChartProto
      );
    }
    this.setState({
      DataChart: currentDataLineChart,
      monthly: this.props.stateMonthly,
      firstLabels: this.props.labels[0]
    });
  }

  createCategoryChart = () => {
    let currentDataLineChart;
    let x = 0;
    for (let i in this.props.dataArray) {
      currentDataLineChart = createChartsDatasetsLine(
        this.props.dataArray[i].labels,
        i,
        colorArray[x],
        this.props.dataArray[i].data,
        this.state.DataChartProto
      );
      x++;
    }
    let key = Object.keys(this.props.dataArray)[0];
    this.setState({
      DataChart: currentDataLineChart,
      monthly: this.props.stateMonthly,
      firstLabels: this.props.dataArray[key].labels
    });
  }

  createChart = () => {
    if (this.props.labels) {
      this.createStandardChart();
    } else if (this.props.dataArray) {
      this.createCategoryChart();
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="shadow">
        <div className="chart-wrapper line" style={{ padding: 10 + 'px' }}>
          <div className="month-day-filter text-center">
            {this.props.stateMonthly ?
              <div>
                <span className="droid-data-monthday" onClick={() => this.props.changeMonthly(false)}>
                  <FormattedMessage
                    id="Data.day"
                    defaultMessage="Tag"
                  />
                </span>
                <span className="droid-data-monthday active" onClick={() => this.props.changeMonthly(true)}>
                  <FormattedMessage
                    id="Data.month"
                    defaultMessage="Monat"
                  />
                </span>
              </div>
              :
              <div>
                <span className="droid-data-monthday active" onClick={() => this.props.changeMonthly(false)}>
                  <FormattedMessage
                    id="Data.day"
                    defaultMessage="Tag"
                  />
                </span>
                <span className="droid-data-monthday" onClick={() => this.props.changeMonthly(true)}>
                  <FormattedMessage
                    id="Data.month"
                    defaultMessage="Monat"
                  />
                </span>
              </div>
            }
          </div>
          {this.state.DataChart ?
            <Line
              data={this.state.DataChart}
              options={LineOptions}
            />
            :
            null}
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


export class DataPieChart extends Component {
  render() {
    return (
      <div className="shadow">
        <div className="chart-wrapper-top chart-wrapper-pie-top text-center">
          <span className="bold">{this.props.topText}</span>
        </div>
        <div className="chart-wrapper pie" style={{ padding: 10 + 'px' }}>
          <Doughnut
            options={PieOptions}
            data={this.props.data}
          />
        </div>
        <div className="chart-wrapper-bottom chart-wrapper-pie-bottom">
          <span>{this.props.bottomText}</span>
        </div>
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export class DataBarChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bottomText: []
    };
  };

  componentDidUpdate = () => { this.createBottomText(); }

  componentDidMount = () => { this.createBottomText(); }

  createBottomText = () => {
    let bottomText = [];

    bottomText = (this.props.data.labels.map((obj, index) =>
      <div key={index} className="single-bottom-text col">
        <span className="bold">{numberWithPoints(this.props.data.values[index])}</span><br />
        <span>{this.props.data.labels[index]}</span>
      </div>
    ));

    let thisBottomTextNum = bottomText[0] ? bottomText[0].props.children[0].props.children : false;
    let thisStateBottomTextNum = 0;

    if (this.state.bottomText.length !== 0) {
      thisStateBottomTextNum = this.state.bottomText[0].props.children[0].props.children;
    }

    if (thisBottomTextNum !== thisStateBottomTextNum) {
      this.setState({ bottomText: bottomText });
    }
  }


  render() {
    return (
      <div className="shadow">
        <div className="chart-wrapper-pie-top">
          <div className="row">{this.props.topText}</div>
        </div>
        <div className="chart-wrapper pie" style={{ padding: 10 + 'px' }}>
          <Bar
            options={BarOptions}
            data={this.props.data}
          />
        </div>
        <div className="chart-wrapper-bottom chart-wrapper-pie-bottom">
          <div className="row">{this.state.bottomText}</div>
        </div>
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


export class DataProgressBarChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      values: [],
      returnCharts: false,
      bottomText: []
    };
  };

  componentDidUpdate = () => {
    this.percent();
    this.createBottomText();
  }

  componentDidMount = () => {
    this.percent();
    this.createBottomText();
  }

  percent = () => {

    let data = this.props.data.values;
    let sumData = 0;
    let percent = [];

    for (let i in data) {
      sumData += data[i];
    }
    for (let i in data) {
      percent[i] = decimalAdjust('floor', ((data[i] / sumData) * 100), -1);
    }

    if (this.state.values === []) {
      this.setState({
        labels: this.props.data.labels,
        values: percent
      }, () => this.createProgressBars())
    } else {
      if (isNaN(percent[0])) {
        percent[0] = 100;
      }
      if (this.state.values[0] !== percent[0]) {
        this.setState({
          labels: this.props.data.labels,
          values: percent
        }, () => this.createProgressBars())
      }
    }
  }

  createProgressBars = () => {
    let progressReturnCharts = this.state.values.map((value, index) =>
      <div key={index} className="single-progress-bar">
        <div className="progress data-progress-bar">
          <div className="progress-bar" role="progressbar" style={{ maxWidth: value + '%' }} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100">
            <span className="progress-bar-value">{value + "%"}</span>
          </div>
        </div>
      </div >
    );

    this.setState({ returnCharts: progressReturnCharts });
  }

  createBottomText = () => {
    let bottomText = [];
    if (this.props.data.labels.length === 1) {
      bottomText = (this.props.data.labels.map((obj, index) =>
        <div key={index} className="single-bottom-text col">
          <span className="large">{numberWithPoints(this.props.data.values[index])}</span><br />
        </div>
      ));
    } else {
      bottomText = (this.props.data.labels.map((obj, index) =>
        <div key={index} className={"single-bottom-text col" + ((this.props.reports && index < 2) ? '-6' : '')}>
          <span className="bold">{numberWithPoints(this.props.data.values[index])}</span><br />
          <span>{this.props.data.labels[index]}</span>
        </div>
      ));
    }

    let thisBottomTextNum = bottomText[0] ? bottomText[0].props.children[0].props.children : false;
    let thisStateBottomTextNum = 0;

    if (this.state.bottomText.length !== 0) {
      thisStateBottomTextNum = this.state.bottomText[0].props.children[0].props.children;
    }

    if (thisBottomTextNum !== thisStateBottomTextNum) {
      this.setState({ bottomText: bottomText });
    }
  }


  render() {
    let progressbarHidden = '';
    if (this.state.values.length === 1) {
      if (this.state.values[0] === 100) {
        progressbarHidden = 'hidden';
      }
    }
    if (this.props.hiddenProgress) {
      progressbarHidden = 'hidden';
    }

    return (
      <div className="shadow">
        <div className="chart-wrapper-top chart-wrapper-progress-bar-top text-center">
          <span className="bold">{this.props.topText}</span>
        </div>
        <div className={"chart-wrapper chart-wrapper-progress-bar " + progressbarHidden}>
          {this.state.returnCharts}
        </div>
        <div className="chart-wrapper-bottom chart-wrapper-progress-bottom">
          <div className="row">{this.state.bottomText}</div>
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


export class DataProgressCircleChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      value: 0,
      returnCharts: false,
    };
    this.baseState = this.state;
  };

  componentDidUpdate = () => this.percent();

  componentDidMount = () => this.percent();

  componentWillUnmount = () => this.setState(this.baseState);

  percent = () => {
    let data = this.props.data.values;
    let percent = 0;
    if ((data[0] !== 0) && data[1] !== 0) {
      percent = decimalAdjust('floor', (data[0] / data[1]) * 100, -1);
    }

    if ((this.state.value !== percent) && percent !== 0) {
      this.setState({ value: percent });
    }
  }

  render() {
    return (
      <div className="shadow">
        <div className="chart-wrapper-top chart-wrapper-progress-circle-top text-center">
          <span className="bold">{this.props.topText}</span>
        </div>
        <div className="chart-wrapper chart-wrapper-progress-circle">
          <div className="progress-circle">
            <CircularProgressbar
              percentage={this.state.value}
              text={`${this.state.value}%`}
              initialAnimation={true}
            />
          </div>
        </div>
        <div className="chart-wrapper-bottom chart-wrapper-progress-circle-bottom">
          <span className="bold">{`${this.state.value}%`}</span><span>{this.props.bottomText}</span>
        </div>
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export class CategoriesPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      value: 0,
      returnCharts: false,
      currentData: false,
      bottomText: false,
      bottomSwiper: false
    };
  };

  componentDidMount = () => { this.getData(); }

  componentDidUpdate = () => { this.getData(); }

  getData = () => {
    let data = this.props.data;
    let categories = Object.keys(data);
    let dataset = getDataPieChartSet();
    let currentDataPie;
    let x = 0;
    for (let i = 0; i < categories.length; i++) {
      currentDataPie = createChartsDatasetsPie(
        categories[i],
        colorArray[x],
        data[categories[i]].data,
        dataset
      )
      x++;
    }
    if (this.state.currentData.datasets) {
      if (!Object.compareMod(this.state.currentData, currentDataPie)) {
        this.setState({
          currentData: currentDataPie
        }, () => this.createBottomText());
      }
    } else {
      this.setState({
        currentData: currentDataPie
      }, () => this.createBottomText());
    }

  }

  createBottomText = () => {
    let bottomText = [];
    bottomText = (this.state.currentData.labels.map((obj, index) =>
      <div key={index} className="single-bottom-text">
        <span className="bold">{numberWithPoints(this.state.currentData.datasets[0].data[index])}</span><br />
        <span style={{ borderBottom: '2px solid ' + this.state.currentData.datasets[0].backgroundColor[index] }}>{this.state.currentData.labels[index]}</span>
      </div>
    ));

    this.setState({
      bottomText: bottomText
    }, () => this.createSwiper());
  }

  createSwiper = () => {
    let swiperText = <DataSwiper divs={this.state.bottomText} />;
    this.setState({ swiperText: swiperText });
  }

  render() {
    return (
      <div className="shadow">
        {this.state.currentData ?
          <div>
            <div className="chart-wrapper-top chart-wrapper-pie-top text-center">
              <span className="bold">{this.props.topText}</span>
            </div>
            <div className="chart-wrapper pie" style={{ padding: 10 + 'px' }}>
              <Doughnut
                options={PieOptions}
                data={this.state.currentData}
              />
            </div>
            <div className="chart-wrapper-bottom chart-wrapper-pie-bottom">
              {this.state.swiperText}
            </div>
          </div>
          :
          null}
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class DataSwiper extends Component {

  constructor(props) {
    super(props)
    this.state = {
      returnDivs: false,
      currentDivs: false
    }
  }

  createSwiper = () => {
    let divs = this.props.divs;
    let returnDivs = (divs.map((div, index) =>
      <div key={index} className="single-slide">
        {div}
      </div>
    ));

    if (this.state.currentDivs !== this.props.divs) {
      this.setState({
        currentDivs: divs,
        returnDivs: returnDivs
      });
    }

  }

  componentWillMount = () => { this.createSwiper(); }

  componentDidUpdate = () => { this.createSwiper(); }

  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 0,
      slidesPerView: 3
    }

    return (
      <Swiper {...params}>
        {this.state.returnDivs}
      </Swiper>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export const SingleTile = (props) => {
  let headerText = props.text;
  let number = props.number;
  let percent = props.percent ? props.percent : false;
  return (
    <div className="col-12 col-md crm-card-single">
      <span className="crm-card-head">{headerText}:</span><br />
      <span className="crm-card-number">{number + (percent ? ' %' : '')}</span>
    </div>
  )
}