import React, { Component } from 'react';

const showMap = (info) => {
  google.charts.load('current', {
    'packages': ['geochart'],
    // 'mapsApiKey': 'AIzaSyBzka8blk5XgCp-aFElR6kxi__RS98lSMI'
    'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
  });
  google.charts.setOnLoadCallback(drawRegionsMap);



  function drawRegionsMap() {

    let countryData = [['Region', 'Count']];
    for (let i in info) {
      if (info[i][1] !== 0) {
        countryData.push(info[i]);
      }
    }

    var data = google.visualization.arrayToDataTable(countryData);

    var options = {
      region: '150',
      displayMode: 'regions',
      colorAxis: { colors: ['green', 'blue'] },
      resolution: 'countries'
    };

    var chart = new google.visualization.GeoChart(document.getElementById('regions_div'));

    chart.draw(data, options);
  }
}

class GoogleMap extends Component {
  render() {
    showMap(this.props.data);
    return (
      <div
        className="crm-map shadow"
        id="regions_div"
        style={{ margin: '20px auto', width: '800px', height: '500px' }}
      ></div>
    )
  }
}

export default GoogleMap;