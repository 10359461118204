import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { changeThemeColor, apiPath, getLabelsAndData, globalErrorHandler } from '../../reusableFunctions';
import { Menu } from '../Main/Menu';
import DeliveryChart from './DeliveryChart';
import './Delivery.css';

const DeliveryHeaderTop = (props) => {

  const getChannelName = props.getChannelName;
  let channel = props.channel;

  const moveMenu = props.moveMenu;

  return (
    <div className="delivery-header">
      <div className="row">
        <div className="col-8">
          <span className="delivery-header-text">{getChannelName(channel)}</span>
        </div>
        <div className="col-4 text-right">
          <button
            onClick={() => moveMenu()}
            style={{ background: 'transparent', border: 'none', color: 'white', padding: 0 }}
          >
            <i className="fas fa-bars data-menu"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

class Delivery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      channels: ['newsletter', 'sms', 'print', 'banner'],
      channel: '',
      showMenu: false,
      overview: false,
      stats: false,
      returnChart: false,
      error: false
    }
  }

  moveMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  }

  getChannelName = (channel) => {
    let channelName = '';
    switch (channel) {
      case 'newsletter':
        channelName = <FormattedMessage id="Delivery.channelName.newsletter" defaultMessage="Newsletter" />;
        break;
      case 'print':
        channelName = <FormattedMessage id="Delivery.channelName.print" defaultMessage="Direct-2-Print" />;
        break;
      case 'sms':
        channelName = <FormattedMessage id="Delivery.channelName.sms" defaultMessage="SMS" />;
        break;
      case 'banner':
        channelName = <FormattedMessage id="Delivery.channelName.banner" defaultMessage="Banner" />;
        break;
      default:
        channelName = <FormattedMessage id="Delivery.channelName.delivery" defaultMessage="Delivery" />;
    }
    return channelName;
  }

  componentDidMount = () => {
    this.checkParams();
    changeThemeColor('#5B001E');
  }

  componentDidUpdate = () => { this.checkParams(); }

  checkParams = () => {
    if (Object.keys(this.props.match.params).length > 0) {
      if (this.props.match.params.channel !== this.state.channel) {
        if (this.props.match.params.channel === undefined) {
          if (this.state.channel !== 'newsletter') {
            this.setState({
              channel: 'newsletter',
              showMenu: false
            }, () => this.getDeliveryInfo());
          }
        } else {
          this.setState({
            channel: this.props.match.params.channel,
            showMenu: false
          }, () => this.getDeliveryInfo());
        }
      }
    }
  }

  getDeliveryInfo = () => {
    let that = this;

    fetch(apiPath + 'delivery/' + this.state.channel, {
      method: 'GET',
      dataType: 'json',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        let groupByMonth = [];
        Object.keys(data.stats).forEach(function (key, index) {
          groupByMonth[key.split('-')[1] + '-' + key.split('-')[2]] = (groupByMonth[key.split('-')[1] + '-' + key.split('-')[2]] || 0) + data.stats[key];
        })
        that.setState({
          overview: data.overview,
          stats: getLabelsAndData(groupByMonth),
          error: false
        });
      })
      .catch(function (error) {
        that.setState({
          error: true,
          overview: false,
          stats: false
        });
        globalErrorHandler(error);
      });
  }

  render() {
    return (
      <div className="delivery droid-module">
        <Menu
          showMenu={this.state.showMenu}
          moveMenu={this.moveMenu}
          moduleName="delivery"
        />
        <DeliveryHeaderTop
          channel={this.state.channel}
          getChannelName={this.getChannelName}
          moveMenu={this.moveMenu}
        />
        <div className="data-lower-part">
          {this.state.stats ?
            <DeliveryChart
              channel={this.state.channel}
              dateFrom={this.state.dateFrom}
              dateTo={this.state.dateTo}
              stats={this.state.stats}
              overview={this.state.overview}
            />
            :
            <div style={{ textAlign: 'center', paddingTop: '50px' }}>
              {this.state.error ?
                <span style={{ color: 'white', fontSize: '1.5em' }}>
                  <FormattedMessage
                    id="Delivery.error.noPackage"
                    defaultMessage="Kein {channelname} Package angelegt"
                    values={{
                      channelname: this.getChannelName(this.state.channel)
                    }}
                  />
                </span>
                :
                <span style={{ color: 'white', fontSize: '1.5em' }}>
                  <FormattedMessage
                    id="Delivery.loadingCharts"
                    defaultMessage="Charts werden geladen"
                  />
                </span>
              }
            </div>
          }
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {
    this.setState({ error: true });

  }
}

export default Delivery