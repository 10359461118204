import React, { Component } from 'react';

import NProgress from 'nprogress';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { DataLineChart, DataProgressBarChart } from '../../Data/Charts/prototypeCharts';
import { getDataProgressChartSet } from '../../Data/Charts/ChartOptions';
import { getChartDataReports } from '../../Data/Charts/fetchData';
import { getLabelsAndData, apiPath } from '../../../reusableFunctions';
import { createChartsDatasetsProgressCircle, SingleChartWrapperReports } from '../../Data/Charts';

const ConversionDownloadButton = (props) => {

  function checkExport(id, from, to) {
    const currentToken = localStorage.getItem('token');
    const url = apiPath + 'report/conversion-set?journey_id=' + id + '&date_from=' + from + '&date_to=' + to;
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      dataType: 'json',
      headers: {
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + currentToken
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.success) {
        toast('Conversion CSV export will be created in the background', {
          className: 'reports-notify',
          autoClose: 3000,
          progressClassName: 'reports-notify-progressbar'
        });
        getExport();
      } else {
        toast('ERROR: Conversion CSV export', {
          className: 'reports-notify',
          autoClose: 3000,
          progressClassName: 'reports-notify-progressbar'
        });
      }
    }).catch(function (error) {
      console.error(error);
      toast('ERROR: Conversion CSV export', {
        className: 'reports-notify',
        autoClose: 3000,
        progressClassName: 'reports-notify-progressbar'
      });
    });

  }

  function getExport(id) {
    const currentToken = localStorage.getItem('token');
    const url = apiPath + 'report/conversion-download';
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': 'Bearer ' + currentToken
      }
    }).then(function (response) {
      return response.text();
    }).then(function (data) {
      if (data.startsWith('{')) {
        data = JSON.parse(data);
        toast(data.status, {
          className: 'reports-notify',
          autoClose: 1000,
          progressClassName: 'reports-notify-progressbar'
        });
        setTimeout(function () {
          getExport(id);
        }, 1000);
      } else {
        const audienceName = document.querySelector('.reports-journies-right-side h4').textContent;
        const encodedUri = 'data:application/csv;charset=utf-8,' + encodeURIComponent(data);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', audienceName + '.csv');
        document.body.appendChild(link);
        link.click();
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  return (
    <button
      onClick={() => checkExport(props.journeyId, props.dateFrom, props.dateTo)}
      className="droid-btn audience-delete-btn shadow"
    >
      <span className="audience-btn-text">
        Download Conversion
      </span>
      <i className="fas fa-download"></i>
    </button>
  )
}

class ConversionChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      monthly: false,
      conversion: false,
      returnCharts: [],
      dateFrom: false,
      dateTo: false
    };
  };

  componentWillMount = () => {
    this.changeDate();
  }

  componentDidUpdate = () => { this.changeDate(); }

  currentDataProgressChartProto = [];
  currentDataLineChartProto = [];

  changeDate = () => {
    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      });
    } else {
      if (
        (this.props.dateFrom !== this.state.dateFrom) ||
        (this.props.dateTo !== this.state.dateTo)
      ) {
        this.setState({
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo
        }, () => this.collectData());
      }
    }
  }

  collectData = () => {

    const dateFrom = this.state.dateFrom;
    const dateTo = this.state.dateTo;

    getChartDataReports('newsletter', 'conversion', dateFrom, dateTo, this.props.step.id)
      .then(
        response => this.setState({
          conversion: getLabelsAndData(response),
          finishedLoading: true
        },
          () => { this.constructChart() && NProgress.set(0.4) })
      ).catch(function (error) {
        globalErrorHandler(error);
        removeTokenSession();
      });
  }

  constructChart = () => {
    this.currentDataProgressChartProto = [];
    this.currentDataProgressChartProto = getDataProgressChartSet();
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.conversion.data,
      'test',
      this.currentDataProgressChartProto
    );

    let values = this.currentDataProgressChartProto.values;
    let currency = ' €';
    let clientId = localStorage.getItem('client_id');
    if (clientId && clientId === '4') {
      currency = ' CHF';
    }

    values.map((value, index) => {
      values[index] = value.toFixed(2) + currency;
    });

    let returnCharts = <SingleChartWrapperReports
      rightChart={<DataProgressBarChart
        topText={"Conversion"}
        data={this.currentDataProgressChartProto}
        hiddenProgress={true}
        reports={true}
      />}
      conversionDownload={<ConversionDownloadButton
        dateFrom={this.state.dateFrom}
        dateTo={this.state.dateTo}
        journeyId={this.props.journeyId}
      />}
      lineChart={
        <DataLineChart
          labels={[this.state.conversion.labels]}
          names={['Umsatz pro Tag']}
          data={[this.state.conversion.data]}
        />
      }
    />

    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        <ToastContainer />
        {this.state.returnCharts}
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default ConversionChart;