import React, { Component } from 'react';


import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import { language, messages } from '../../reusableFunctions';

import './InfoTexts.css';
import TextsDE from './InfoTextsDE.json';
import TextsEN from './InfoTextsEN.json';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
library.add(faInfoCircle);

class InfoTextsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      info: false
    };
  };

  openInfo = (path) => {

    let Texts = TextsDE;
    if (language.includes('en')) {
      Texts = TextsEN;
    }

    let result = {}, groups = path.split(',');

    let groupStr = groups[0],
      split = groupStr.split(':'),
      groupKey = split[0],
      nodeKey = split[1],
      properties = split[2];

    result[0] = groupKey;
    result[1] = nodeKey;
    result[2] = properties;

    let currentText = Texts[result[0]][result[1]][result[2]];

    confirmAlert({
      customUI: ({ onClose }) => {
        if (currentText) {
          return (
            <div className="info-text-inner">
              <div className="info-text-inner-header">
                <h3 style={{ fontWeight: '600' }}>{currentText.maintitle}</h3>
              </div>
              <h4>{currentText.mainmessage}</h4>
              {currentText.submessage ? currentText.submessage.map((sub, index) => {
                return (
                  <div key={index}>
                    <h5>{currentText.subtitle[index] + ':'}</h5>
                    <h6 style={{ textIndent: '1rem' }}>{currentText.submessage[index]}</h6>
                  </div>
                )
              }) : false}
              <button onClick={onClose}>{messages["Data.evaluationmodal.close"]}</button>
            </div>
          )
        } else {
          return (
            <div className="info-text-inner">
              <div className="info-text-inner-header">
                <h3>{messages["Data.evaluationmodal.noInfo"]}</h3>
              </div>
              <button onClick={onClose}>{messages["Data.evaluationmodal.close"]}</button>
            </div>
          )
        }
      }
    });
  }

  render() {
    return (
      <div
        className="info-text-wrapper"
        onClick={() => this.openInfo(this.props.info)}
      >
        <i className="fas fa-info-circle"></i>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default InfoTextsModal;