export const LineOptions = {
  legend: {
    labels: {
      fontColor: 'white',
      defaultFontSize: 16,
      boxWidth: 20,
      usePointStyle: true
    },
    display: true,
    onHover: function (e) {
      e.target.style.cursor = 'pointer';
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    }
  },
  hover: {
    onHover: function (e) {
      var point = this.getElementAtEvent(e);
      if (point.length) e.target.style.cursor = 'pointer';
      else e.target.style.cursor = 'default';
    }
  },
  scales: {
    yAxes: [{
      ticks:
      {
        fontColor: 'white',
        callback: function (value, index, values) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
      }
    }],
    xAxes: [{ ticks: { fontColor: 'white' } }],
  },
  maintainAspectRatio: false
};

export const PieOptions = {
  legend: { labels: { fontColor: 'white', defaultFontSize: 23 }, display: true },
  maintainAspectRatio: false
}

export const BarOptions = {
  legend: { labels: { fontColor: 'white', defaultFontSize: 23 }, display: false },
  maintainAspectRatio: false,
  scales: {
    yAxes: [{ ticks: { fontColor: 'white', display: false } }],
    xAxes: [{ ticks: { fontColor: 'white', display: false } }],
  },
}

export function getDataLineChartSet() {
  const DataLineChartDataset = {
    label: '',
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: '',
    borderWidth: 5,
    borderCapStyle: 'round',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: 'white',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 5,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '',
    pointHoverBorderColor: 'rgba(250,250,250,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: []
  }
  return DataLineChartDataset;
}

export function getDataPieChartSet() {
  const DataPieChartDataset = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: [],
      hoverBackgroundColor: []
    }]
  }
  return DataPieChartDataset;
}

export function getDataBarChartSet() {
  const DataBarChartSet = {
    labels: [],
    datasets: [
      {
        label: [],
        backgroundColor: [],
        borderColor: 'rgb(255,255,255)',
        borderWidth: 1,
        hoverBackgroundColor: [],
        hoverBorderColor: 'rgb(255,255,255)',
        data: []
      }
    ]
  };
  return DataBarChartSet;
}

export function getDataProgressChartSet() {
  const DataProgressChartSet = {
    labels: [],
    values: []
  }
  return DataProgressChartSet;
}

export function getDataChartProto() {
  const DataChartProto = {
    labels: [],
    datasets: []
  };
  return DataChartProto;
}