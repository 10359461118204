import React, { Component } from 'react';


import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class TermsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checkbox: false,
      error: false
    };
  };

  componentDidMount = () => {
    this.openModal();
  }

  checkboxClick = (value) => {
    this.setState({ checkbox: value });
  }

  handleAccept = (closeModal) => {
    if (!this.state.checkbox) {
      this.setState({ error: true });
      this.notifyCheckbox();
      this.openModal();
    } else {
      this.setState({ error: false });
      closeModal();
      this.props.acceptTerms();
    }
  }

  notifyCheckbox = () => toast('Bitte Häckchen setzten.',
    {
      className: 'login-notify-error',
      autoClose: 3000,
      progressClassName: 'login-notify-progressbar'
    });

  openModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="info-text-inner terms-modal">
            <TermsText />
            {this.props.accepted ?
              <div>
                <button onClick={() => {
                  this.props.showTermsOfUse();
                  onClose();
                }}>Schließen</button>
              </div>
              :
              <div>
                <div className={'row ' + (this.state.error ? 'error' : '')}>
                  <div className="col-11">
                    <label htmlFor="agree_terms">Mit den vorstehenden Vertrags- und Nutzungsbedingungen vollinhaltlich einverstanden</label>
                  </div>
                  <div className="col-1">
                    <input onClick={(e) => this.checkboxClick(e.target.checked)} name="agree_terms" id="agree_terms" type="checkbox"></input><br />
                  </div>
                </div>
                <button onClick={() => this.handleAccept(onClose)}>Akzeptieren</button>
              </div>
            }
          </div>
        );
      }
    });
  }

  render() {
    return (
      <div></div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
};


const TermsText = () => {
  return (
    <div className="terms-text">
      <div className="terms-text-header">
        <h1>VERTRAGS- UND NUTZUNGSBEDINGUNGEN</h1>
        <h3>Droid Marketing Cloud</h3>
      </div>
      <div className="terms-text-inner">
        <ol>
          <li>Gegenstand</li>
          <span>
            Diese Vertrags- und Nutzungsbedingungen regeln die Bedingungen für die Nutzung der Marketing Cloud der Droidmarketing GmbH ("Droid Marketing Cloud"). Mit Setzen des Häkchens am Ende dieser Seite gelten diese Bedingungen des Lizenzgebers als vollinhaltlich akzeptiert.
          </span>
          <li>Definitionen</li>
          <span>
            Es gelten die Definitionen der Auftragsbestätigung für gegenständliche Vertrags- und Nutzungsbedingungen sinngemäß, sowie weiters folgende:
          </span>
          <span>"Upgrade": Das ist die Änderung eines Produkts auf eine höherwertige Konfiguration oder Version.</span><br />
          <span>"Fix/Patch": Das meint die zeitnahe Behebung eines Fehlers in einem Teil des Programmcodes.</span><br />
          <span>"Reverse Engineering": Darunter wird der Vorgang verstanden, aus einem bestehenden, fertigen System durch Untersuchung der Strukturen, Zustände und Verhaltensweisen die Konstruktionselemente zu extrahieren.</span><br />
          <span>"API": (englisch "application programming interface"). Damit ist die Schnittstelle zur Anwendungsprogrammierung gemeint. Das ist der Programmteil, der vom Softwaresystem anderen Programmen zur Anbindung an das System zur Verfügung gestellt wird.</span><br />
          <span>"Open Source Komponente": Damit ist Software gemeint, deren Quelltext öffentlich und von Dritten eingesehen, geändert und genutzt werden kann.</span>
          <li>Einräumung und Umfang der Nutzungsrechte</li>
          <span>
            Der Lizenzgeber stellt dem Lizenznehmer die Droid Marketing Cloud zur Nutzung auf einem Online-Portal zur Verfügung, für welches er dem Lizenznehmer Zugangsdaten bereitstellt.

            Das Recht zur Nutzung umfasst die Software "Droid Marketing Cloud" in den gegenständlichen Bedingungen vereinbarten Umfang der Auftragsbestätigung.
          </span>
          <li>Technische Anforderungen</li>
          <span>
            Der Lizenznehmer hat sicherzustellen, dass die technischen Anforderungen zur Nutzung der vertragsgegenständlichen Software vorhanden sind. Diese stellen sich wie folgt dar:

            Das Web-Portal (Schnittstelle zur Cloud) ist über die meisten gängigen Webbrowser (wie etwa Microsoft Internet Explorer, Mozilla Firefox, Google Chrome oder Apple Safari) in der jeweils aktuellen Version unabhängig vom Betriebssystem auf den meisten gängigen Endgeräten (Desktop-PC, Notebook, Tablet-PC usw.) nutzbar, wobei der Lizenzgeber laufend auf seiner Website die unterstützten Browser- und Betriebssysteme bzw. Kombinationen in den jeweiligen Versionen veröffentlichen wird. Die Verantwortung, ob die beim Lizenznehmer vorhandene Hard- und Software für die Nutzung der vertragsgegenständlichen Software geeignet und der Zugriff auf die Cloud technisch möglich ist, liegt ausschließlich beim Lizenznehmer. Jegliche Haftung des Lizenzgebers für die Tauglichkeit der vom Lizenznehmer eingesetzten Browser- und Betriebssysteme bzw. Endgeräte ist ausgeschlossen.
          </span>
          <li>Sub-Dienstleister</li>
          <span>
            Der Lizenzgeber ist berechtigt zu den bei Vertragsabschluss bestehenden Versandpartnern, künftig weitere Versandpartner hinzuzufügen bzw. bestehende Versandpartner auszutauschen. Änderungen im Stand der vom Lizenzgeber beigezogenen Versandpartner werden dem Lizenznehmer vom Lizenzgeber unverzüglich bekanntgegeben, wobei die jeweils aktuelle Auflistung der Versandpartner ab Bekanntgabe Vertragsbestandteil wird und die jeweils vorhergehende Aufstellung vollinhaltlich ersetzt.

            Der Lizenznehmer ist nicht verpflichtet, sämtliche in der Auftragsbestätigung vereinbarten Versandpartner in Anspruch zu nehmen. Andere Versandpartner, welche vom Lizenznehmer ausdrücklich gewünscht werden, können im Rahmen der Change-Request-Abwicklung ergänzt werden, wobei für diese seitens des Lizenzgebers nicht garantiert werden kann, dass sämtliche Funktionalitäten mit diesem kompatibel sind. Die damit verbundenen Mehrkosten ergeben sich aus der jeweils gültigen Preisliste.

            Für den Fall, dass eine Zusammenarbeit mit einem bestimmten Versandpartner vom Lizenznehmer nicht gewünscht ist, hat der Lizenznehmer dies dem Lizenzgeber unverzüglich schriftlich bekanntzugeben, andernfalls das Einverständnis zu dem jeweiligen Versandpartner als gegeben gilt. Der Lizenznehmer nimmt zur Kenntnis, dass eine automatische Verarbeitung der Auftragsdaten vom Lizenzgeber nur für die vom Lizenzgeber definierten Versandpartner sichergestellt werden kann.
          </span>
          <li>Erweiterungen / Aktualisierungen</li>
          <span>
            Der Lizenzgeber kann nach eigenem Ermessen künftige Upgrades, Aktualisierungen bzw. Erweiterungen des Funktionsumfanges der Droid Marketing Cloud bereitstellen. Ein Anspruch des Lizenznehmers auf die Bereitstellung von Upgrades, Aktualisierungen bzw. Erweiterungen besteht nicht. Die Upgrades, Aktualisierungen bzw. Funktionserweiterungen, sofern vorhanden, enthalten unter Umständen nicht alle vorhandenen Softwarefunktionen oder neuen Funktionen, die der Lizenzgeber für neuere oder andere Betriebssysteme des Lizenznehmers freigibt.

            Dies gilt für jegliche vom Lizenzgeber bereitgestellten Softwareaktualisierungen oder Upgrades, die die Droid Marketing Cloud in ihrer ursprünglichen Release ersetzen und/oder ergänzen, es sei denn, eine solche Aktualisierung oder ein solches Upgrade bzw. eine Funktionserweiterung umfasst eine separate Lizenz. In diesem Fall gelten die dort gesondert vereinbarten Bedingungen.

            Dem Lizenzgeber steht es frei Funktionserweiterungen, die er vorübergehend zusätzlich zur Verfügung stellt, einseitig einzustellen und sie in anderer Form - beispielsweise als Zusatzpakete - anzubieten.
          </span>
          <li>Nutzungsrechte</li>
          <span>
            Der Lizenznehmer ist ausschließlich im Rahmen der geschlossenen Vereinbarung berechtigt die Software zu nutzen. Der Lizenznehmer erwirbt mit Abschluss dieses Vertrages das nicht ausschließliche und nicht übertragbare, weltweite, Recht, die Software für die Zwecke seines Unternehmens zu nutzen und ist ausschließlich berechtigt die Software in dem Umfang zu benutzen, sowie für diejenige Anzahl an Nutzern freizuschalten, welche im vereinbarten Leistungsumfang festgehalten sind. Dieses Nutzungsrecht schließt auch Fixes, Patches und entgeltlich bezogene Entwicklungen, Updates und Upgrades ein.

            Eine Weitergabe der Software bzw. Zugangsberechtigungen an unternehmensfremde Dritte, sei es auch an Unternehmen, an welchen der Lizenznehmer beteiligt ist, oder welche an diesem beteiligt sind (verbundene Unternehmen), ist ohne ausdrückliche schriftliche Zustimmung des Lizenzgebers nicht gestattet. Die Vergabe von Unterlizenzen oder sonstige Weitergabe von Zugangsdaten an Dritte (Miete, Leihe, etc.) sowohl dauerhaft als auch nur vorübergehend ist dem Lizenznehmer daher ausdrücklich untersagt.

            Der Lizenznehmer ist nicht berechtigt, die vom Lizenzgeber zur Verfügung gestellte Dokumentation oder sonstige Unterlagen zu vervielfältigen oder an Dritte weiterzugeben.
          </span>
          <li>Fair Use</li>
          <span>
            Der Lizenznehmer nimmt zur Kenntnis, dass die vom Lizenzgeber im Rahmen dieses Vertrages eingesetzte Technologie (Software, Server und Programmierschnittstelle – API) bestimmten technischen Beschränkungen unterworfen ist, betreffend einschließlich, aber nicht nur, die maximale Anzahl von Signal-Eingängen oder die maximale Menge übertragener Daten. Im Sinne des vorstehenden Satzes verpflichtet sich der Lizenznehmer zum "fair use" der Droid Marketing Cloud. Jegliche Haftung des Lizenzgebers für allenfalls aus einer Überschreitung des Datenvolumens durch den Lizenznehmer oder Dritte resultierende Nachteile ist ausgeschlossen.
          </span>
          <li>Eigentums- und Urheberrecht</li>
          <span>
            Das Eigentums- und Urheberrecht an den Inhalten, die über die Droid Marketing Cloud angezeigt werden bzw. auf die über die Droid Marketing Cloud in den einzelnen Modulen zugegriﬀen wird, liegt beim Lizenzgeber. Davon ausgenommen sind die Rechte an den vom Lizenznehmer in die Droid Marketing Cloud eingespielten Daten. Solche Inhalte sind möglicherweise durch Urheberrechte oder andere Rechte und Verträge hinsichtlich des geistigen Eigentums geschützt und können den Nutzungsbestimmungen des Dritten unterliegen, der diese Inhalte bereitstellt. Die eingeräumte Lizenz gewährt dem Lizenznehmer keine Rechte zur Nutzung solcher Inhalte und umfasst keine Garantie, dass diese Inhalte weiterhin für diesen verfügbar sein werden.

            Sofern Software eines Dritten zum Einsatz kommt, unterliegt diese den Vertragsbedingungen des Dritten. Der Lizenzgeber wird den Lizenznehmer darauf hinweisen, wenn Software eines Dritten zum Einsatz kommt.

            Der urheberrechtliche Schutz umfasst auch Weiterentwicklungen der Software (allgemeine Produkt-Erweiterungen und Individualentwicklungen für den Lizenznehmer). Die Software darf ohne Zustimmung des Lizenzgebers nicht kopiert, verändert oder auf andere Weise als in der Auftragsbestätigung oder diesen Nutzungsbedingungen ausdrücklich gestattet, verwendet werden. Es wird ausdrücklich vereinbart, dass allfällig notwendig werdende Änderungen oder Anpassungen von Software, etwa über Kundenwunsch, ausschließlich vom Lizenzgeber selbst vorgenommen werden; der Lizenznehmer hat darauf jedoch keinen Rechtsanspruch.

            Der Lizenzgeber wird in diesem Zusammenhang im Rahmen vom Lizenznehmer bezogener Serviceleistungen der Droid Marketing Cloud, soweit es möglich ist, auf Wünsche des Lizenznehmers eingehen, behält sich jedoch vor, gewünschte Adaptierungen oder Änderungen ohne Angabe von Gründen abzulehnen.
          </span>
          <li>Bearbeitung / Veränderung der Software /Support</li>
          <span>
            Eine Bearbeitung oder Veränderung der Software durch den Lizenznehmer ist ausgeschlossen, sofern dies nicht ausdrücklich vereinbart ist. Im Übrigen sind Veränderungen der Software ausschließlich durch den Lizenzgeber möglich und zulässig, wobei die Abwicklung solcher Dienstleistungen des Lizenzgebers in einer gesonderten Dienstleistungsvereinbarung (Service Level Agreement) geregelt, wird sofern es sich dabei nicht um geringfügige technische Support – Leistung ("Problemlösung") handelt. Der Lizenzgeber steht im Rahmen des monatlichen Lizenzentgelts für geringfügige technische Support - Leistungen (Problemlösung) zu üblichen Geschäftszeiten von Montag bis Freitag zur Verfügung. Derartige Support-Leistungen sind solche, welche von geringer Komplexität sind, über Telefon oder Fernwartung erbracht werden können und ein Gesamtausmaß von drei Stunden pro Monat nicht überschreiten.  Eine Rückübersetzung des Objekt-Codes in den Quell-Code bzw. das Reverse-Engineering und die Dekompilation sind dem Lizenznehmer ausdrücklich nicht gestattet und stellen derartige Maßnahmen eine Verletzung des Urheberrechtes des Lizenzgebers dar.
          </span>
          <li>Unterbrechung / Verfügbarkeit</li>
          <span>
            Die Nutzung der Software ist an 24 Stunden, an 7 Tagen die Woche möglich. Der Lizenznehmer ist in Kenntnis davon, dass es zu allfälligen Einschränkungen aufgrund von Wartungen, Implementierungen weiterer Module, Aktualisierungen der Software, etc. kommen kann und sind jegliche daraus resultierende Ansprüche seitens des Lizenznehmers ausgeschlossen. Eine Verfügbarkeit im Jahresdurchschnitt von 98 % gilt als vertragsgemäß.

            Allfällige Funktionsstörungen der Software sind dem Lizenzgeber unverzüglich schriftlich zu melden, andernfalls der Lizenzgeber berechtigt ist, für die Behebung derartiger Störungen gesonderte Kosten entsprechend den jeweils vom Lizenzgeber publizierten Preisen zu verrechnen. Sofern durch unverzüglich gemeldete Funktionsstörungen der vorstehend vereinbarte Verfügbarkeits – Jahresdurchschnitt unterschritten wird, erfolgt eine Gutschrift in Höhe der anteiligen Offline – Zeit, dies jedoch beschränkt mit der Höhe des für einen Monat zu entrichtenden Lizenzentgelts.
          </span>
          <li>Entgeldbestimmungen</li>
          <span>
            Ein gegenüber der zwischen den Vertragsparteien getroffenen Vereinbarung unzulässigerweise gesteigerter oder erweiterter Gebrauch der Droid Marketing Cloud, z.B. durch eine größere Anzahl autorisierter User, an zusätzlichen Einsatzorten, durch verbundene Gesellschaften oder für eine nach Art, Umfang und Intensität gesteigerte Nutzung geben dem Lizenzgeber das Recht zu einer angemessenen Anpassung des Lizenzentgelts. Ein darüberhinausgehender Anspruch auf Schadenersatz des Lizenzgebers bleibt davon unberührt.
          </span>
          <li>Rückstellungspflichten bei Vertragsbeendigung</li>
          <span>
            Ab dem Datum des Inkrafttretens von Rücktritt oder Kündigung erlischt das Recht des Lizenznehmers zum bestimmungsgemässen Gebrauch der vertragsgegenständlichen Software.
            Der Lizenznehmer ist verpflichtet, erstellte Kopien oder Teilkopien sowie alle geänderten oder mit anderen Programmen oder Datensystemen verbundenen Teile der Software/Droid Marketing Cloud spätestens innert 30 Tagen nach Erlöschen der Lizenz vollständig und nachweislich zu löschen. Wenn der Lizenznehmer dieser Pflicht nicht nachkommt, hat der Lizenzgeber das Recht, bis auf Weiteres das wiederkehrende Lizenzentgelt in Rechnung zu stellen. Vorbehalten bleibt das Recht des Lizenznehmers, für die Erfüllung gesetzlicher Aufbewahrungspflichten notwendige Unterlagen zu archivieren.

            Die vom Lizenzgeber zur Verfügung gestellte Dokumentation ist an den Lizenzgeber zurückzustellen oder nachweislich zu vernichten.

            Für den Fall der Zuwiderhandlung gilt Punkt 15 Abs 5 sinngemäß.
          </span>
          <li>Gewährleistung / Haftung des Lizenzgebers</li>
          <span>Der Lizenzgeber leistet nur Gewähr, dass</span>
          <ul>
            <li>er berechtigt ist, die nach diesem Vertrag gewährte Nutzungslizenz an der Droid Marketing Cloud einzuräumen,</li>
            <li>die Droid Marketing Cloud den in der Auftragsbestätigung vereinbarten Funktionsumfang aufweist, sofern sie in gängiger Systemumgebung mit den vom Lizenzgeber spezifizierten Komponenten (z.B. Webbrowser) eingesetzt wird,</li>
            <li>eine Datenverarbeitung seitens des Lizenzgebers ausschließlich im vertraglich vereinbarten Umfang erfolgt.</li>
          </ul>
          <span>Darüber hinaus macht der Lizenzgeber keinerlei Zusicherungen.</span>
          <span>Der Lizenzgeber haftet insbesondere nicht</span>
          <ul>
            <li>für bestimmte, über die ausdrücklich angeführten hinausgehende, technische Eigenschaften oder eine bestimmte Art der Verwendbarkeit der Droid Marketing Cloud,</li>
            <li>aus dem Grund des Datenmissbrauchs oder des Datendiebstahls, oder der sonstigen Verwendung der übertragenen oder gespeicherten Daten durch Unberechtigte,</li>
            <li>für fehler- oder lückenhafte Aufzeichnung von Daten, oder Datenverlust,</li>
            <li>für Serverausfälle oder Ausfälle von Kommunikationsleitungen und</li>
            <li>Softwarefehler.</li>
          </ul>
          <span>
            Eine Haftung des Lizenzgebers für mittelbare Schäden, Mangelfolgeschäden, entgangenen Gewinn sowie nicht eingetretene Ersparnis, gleich aus welchem Rechtsgrund, einschließlich aus verschuldensunabhängiger Haftung, ist, soweit gesetzlich zulässig, ausgeschlossen. Sollte die Haftung aufgrund zwingender gesetzlicher Vorschriften nicht im vorbeschriebenen Ausmaß ausgeschlossen werden können, so ist die Haftung des Lizenzgebers jedenfalls auf Vorsatz und grobe Fahrlässigkeit und der Höhe der 3fachen monatlichen Lizenzgebühr beschränkt.
          </span>
          <span>
            Die Vertragsparteien werden sich wechselseitig unverzüglich schriftlich benachrichtigen, sofern ihnen gegenüber Ansprüche aus der behaupteten Verletzung von Schutzrechten geltend gemacht werden, oder aus diesem Rechtsgrund behördliche Aufträge ergehen. Jedwede Gewährleistung des Lizenzgebers ist ausgeschlossen, wenn eine Verletzung von Schutzrechten Dritter durch ein vom Lizenznehmer gesetztes Verhalten (sei es auch durch Änderungen und/oder Ergänzungen der Software durch den Lizenznehmer) verursacht wird. Die eigenmächtige Änderung oder Bearbeitung der Software durch den Lizenznehmer führt zum gänzlichen Ausschluss von Ansprüchen auf Gewährleistung gegenüber dem Lizenzgeber.
          </span>
          <span>
            Ein allenfalls auftretender Mangel (Beeinträchtigung der Funktionalität der Software) ist bei sonstigem Verlust jedweder Ansprüche vom Lizenznehmer unverzüglich dem Lizenzgeber schriftlich mitzuteilen, um diesem die Behebung ermöglichen zu können. Der Lizenznehmer ist verpflichtet, sämtliche Maßnahmen vom Lizenzgeber zu akzeptieren, welche erforderlich sind, um einen allfälligen Mangel an der Software zu beheben, andernfalls er auf seine Ansprüche auf Mangelbehebung verzichtet. Die Erfüllung der Gewährleistungspflicht durch den Lizenzgeber erfolgt primär durch Verbesserung.
          </span>
          <li>Haftung des Lizenznehmers</li>
          <span>
            Der Lizenznehmer ist verpflichtet für die Einhaltung sämtlicher für die Verarbeitung der von ihm eingespeisten Daten relevanter Rechtsvorschriften Sorge zu tragen, insbesondere die Bestimmungen des Datenschutzrechtes einzuhalten und hat den Lizenzgeber diesbezüglich schad- und klaglos zu halten. Eine Nutzung der Software für illegale, sittenwidrige oder strafrechtliche Zwecke ist ausdrücklich ausgeschlossen.

            Der Lizenznehmer verpflichtet sich, es zu unterlassen und es anderen nicht zu gestatten, die Droid Marketing Cloud Software oder jegliche von der Droid Marketing Cloud bereitgestellten Dienste (auch von Dritten) oder Teile davon zu kopieren (sofern dies nicht ausdrücklich im Rahmen der Auftragsbestätigung oder der Nutzungsbestimmungen, sofern diese für Sie gelten, gestattet ist), zu dekompilieren, zurückzuentwickeln, zu disassemblieren, Versuche zur Ableitung des Quellcodes zu unternehmen, den Quellcode zu entschlüsseln, zu modiﬁzieren oder abgeleitete Werke der Droid Marketing Cloud oder jeglicher von der Droid Marketing Cloud bereitgestellter Dienste (auch von Dritten) oder Teilen davon zu erstellen (sofern dies nicht und nur in dem Ausmaß, in dem jegliche vorgenannte Beschränkung durch gesetzliche Vorschriften untersagt ist oder durch die Lizenzbestimmungen, die die Nutzung der in der Droid Marketing Cloud möglicherweise enthaltenen Open-Source-Komponenten regeln, gestattet ist).

            Der Lizenznehmer haftet für jedweden Schaden, welcher dem Lizenzgeber oder von diesem beigezogenen Dritten durch die Nutzung der Software und die Einspeisung der Daten (etwa durch Trojaner, Viren, etc.) entsteht. Die Haftung des Lizenznehmers erstreckt sich auch auf das Verhalten von ihm zur Nutzung berechtigter Mitarbeiter oder sonstiger berechtigter Dritter, wie etwa Agenturen oder Berater. Der Lizenznehmer hat die sich aus dieser Vereinbarung ergebenden Pflichten im Zusammenhang mit der Nutzung der Droid Marketing Cloud auf sämtliche zur Nutzung berechtigte Dritte zu überbinden.

            Der Lizenznehmer ist zudem für Auswahl, Gebrauch und Unterhalt der im Zusammenhang mit der Droid Marketing Cloud eingesetzten Informatiksysteme, weiterer Programme und Datensysteme sowie die dafür erforderlichen Dienstleistungen zuständig und stellt die für den Einsatz der Droid Marketing Cloud geeignete Aufbau- und Ablauforganisation bereit.

            Sollte der Lizenznehmer, dessen Mitarbeiter oder Hilfspersonen absichtlich oder grobfahrlässig die Bestimmungen dieses Vertrages über den Gebrauch und den Schutz des Lizenzmaterials verletzen, schuldet der Lizenznehmer dem Lizenzgeber für jeden Fall der Verletzung als Entschädigung den dreifachen Betrag des für den bestimmungsgemäßen Gebrauch der Droid Marketing Cloud während eines Jahres geschuldeten vollen (wertgesicherten) Lizenzentgelts (ohne Rabatte oder Staffelung). Vorbehalten bleibt die Geltendmachung des weiteren Schadens.

            Die Bezahlung dieser Konventionalstrafe befreit den Lizenznehmer nicht von seinen vertraglichen Pflichten. Der Lizenzgeber ist insbesondere berechtigt, jederzeit die Beseitigung des rechtswidrigen Zustandes bzw. der Vertragsverletzung zu verlangen oder den Vertrag bzw. den betreffenden Anhang gemäß den vereinbarten Kündigungsbestimmungen aufzulösen.

            Der Lizenzgeber ist für den Fall der Verletzung vertraglicher Pflichten, insbesondere beim begründeten Verdacht der Verletzung von Rechten Dritter oder relevanten gesetzlichen Bestimmungen, berechtigt, den Zugang des Lizenznehmers zur Droid Marketing Cloud vorübergehend oder dauerhaft, bei unveränderter Pflicht zur Zahlung des vereinbarten Entgelts, zu sperren. Die Sperre ist unverzüglich aufzuheben, wenn ein solcher Verdacht entkräftet oder die Rechtsverletzung aufgehoben ist.

            Damit verbundene Kosten hat der Lizenznehmer zu ersetzen, sofern die Sperre tatsächlich von diesem zu vertreten war. Jegliche daraus resultierende Haftung des Lizenzgebers ist ausgeschlossen, sofern die Sperre nicht vorsätzlich oder grob fahrlässig wider besseren Wissens erfolgt ist.
          </span>
          <li>Datensicherheit und Datenschutz</li>
          <span>
            Sofern kein gesonderter Auftragsverarbeitervertrag abgeschlossen wird, gilt zusätzlich folgendes:
            <br />
            16.1.	Datensicherheit
            Durch den Einsatz der Droid Marketing Cloud werden kundenspezifische Verhaltensdaten über Programmierschnittstellen (APIs) vom Lizenznehmer an die Droid Marketing Cloud zur weiteren Verarbeitung und Analyse übergeben.

            Dies bei gleichzeitiger Sicherstellung von Datensicherheit und angemessener Sicherungsverfahren. Festgehalten wird, dass der Lizenznehmer für die Einhaltung der datenschutzrechtlichen Bestimmungen hinsichtlich der von ihm in die Droid Marketing Cloud eingespeisten Daten ausschließlich verantwortlich ist und bestätigt mit seiner Zustimmung zu diesen Bedingungen, dass der Schutz der von ihm eingespeisten Kundendaten abschließend gewährleistet und er zu deren Nutzung berechtigt ist. Jegliche diesbezügliche Haftung seitens des Lizenzgebers ist ausgeschlossen.

            Der Lizenzgeber stellt sicher, dass die mit der Droid Marketing Cloud verbundenen Leistungen an sicheren Rechenzentrumsstandorten im Europäischen Wirtschaftsraum (EWR) erbracht und sämtliche Sicherheitsmaßnahmen ergriffen werden, um die jeweiligen gesetzlichen Anforderungen zu erfüllen.

            Die in die Cloud eingespeisten Daten werden täglich gesichert, der Lizenzgeber trifft die erforderlichen Maßnahmen zur Vermeidung von Angriffen auf die gespeicherten Daten sowie die Einspielung von SchadSoftware.

            Der Lizenznehmer ergreift in seinem Betrieb die erforderlichen organisatorischen und technischen Massnahmen, um die ungewollte Preisgabe bzw. Zugriff, Diebstahl oder Missbrauch der Zugangsdaten zur Droid Marketing Cloud durch Unberechtigte zu verhindern.

            Der Lizenznehmer stellt im Weiteren die Kontrolle von Anzahl und Identität der berechtigten User im Zusammenhang mit dem bestimmungsgemässen Gebrauch sicher. Insbesondere bei Beendigung des Dienstverhältnisses eines zugriffsberechtigten Users sind diesem die Zugriffsrechte unmittelbar zu entziehen.

            Der Lizenzgeber ist bemüht bei der Verhinderung eines allfälligen Datenmissbrauchs oder Datendiebstahls mitzuwirken. Der Lizenzgeber verpflichtet sich zudem zur Verschwiegenheit und somit dazu, keine in seiner Sphäre gespeicherten Daten ohne Zustimmung des Lizenznehmers an Dritte weiterzugeben.

            Dem Lizenznehmer ist bekannt, dass allenfalls staatliche Eingriffs- und Einsichtsrechte in die in der Cloud gespeicherten Daten bestehen können, welche der Lizenzgeber zu erfüllen hat. Jegliche daraus allenfalls resultierende Haftung des Lizenzgebers ist ausgeschlossen. Gleichzeitig verpflichtet sich der Lizenzgeber die notwendigen technischen und organisatorischen Voraussetzungen zu schaffen, um allfälligen Auskunfts-, Richtigstellungs- und Löschungspflichten, mit welchen der Lizenznehmer konfrontiert wird, nachzukommen.

            Die Droid Marketing Cloud wird vom Lizenznehmer nicht für produktionskritische Zwecke (Mission Critical Data) eingesetzt werden, da es möglich ist, dass die Droid Marketing Cloud teilweise oder zur Gänze ausfallen kann.

            Der Lizenznehmer wird die Droid Marketing Cloud nicht für sicherheitsrelevante Zwecke einsetzen, etwa zur Überwachung von Daten, durch deren fehlerhafte Erfassung Gefahr für Sachschäden oder Leib und Leben besteht.
            <br />
            16.2.Datenschutz
            Dem Lizenznehmer ist bekannt, dass die Nutzung zu einer Bearbeitung personenbezogener Daten über die Vertragspartner, deren Mitarbeiter, Unterauftragnehmer usw. führen kann. Mit Zustimmung zu diesen Bedingungen erklärt sich der Lizenznehmer damit einverstanden, dass solche Daten zur Abwicklung und Pflege ihrer Geschäftsbeziehungen verwendet und zu diesem Zweck auch an Dritte wie z.B. Kooperationspartner, Zulieferer, Inhaber von Schutzrechten, Unterauftragnehmer, Spediteure, Kreditinstitute im In- oder Ausland bekannt gegeben werden können. Der Lizenzgeber wird in solchen Fällen durch geeignete organisatorische, technische und vertragliche Vorkehrungen für die Gewährleistung des Datenschutzes sorgen.

            Sämtliche Kundendaten des Lizenznehmers, die vom Lizenznehmer aufgrund dieses Vertrages an den Lizenzgeber übermittelt werden, bleiben im ausschließlichen Eigentum des Lizenznehmers. Der Lizenzgeber ist jedoch berechtigt, sämtliche Daten in anonymisierter Form zur Verbesserung der vom Lizenzgeber angebotenen Software selbst zu nutzen.

            Der Lizenznehmer haftet darüber hinaus für die im Zusammenhang mit der Verwendung der kundenspezifischen Verhaltensdaten und der Weiterverarbeitung in der Droid Marketing Cloud verwendeten Daten. Die Haftung des Lizenznehmers schließt auch die Ermittlung ein, ob Datenschutzgesetze, -vorschriften oder -pflichten für die Daten gelten. Der Lizenznehmer leistet Gewähr, dass die Verarbeitung oder Verwendung der zur Verfügung gestellten Daten im Zusammenhang mit der Droid Marketing Cloud keinen Einschränkungen unterliegen.
          </span>
        </ol>
      </div>
    </div>
  )
}

export default TermsModal;