import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import moment from 'moment';

import Select from 'react-select';

import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './Reports.css';

import { changeThemeColor, apiPath, globalErrorHandler, messages, selectTheme } from '../../reusableFunctions';

import ReportsCharts from './ReportsCharts';
import { UserSearch } from '../UserSearch/UserSearch';
import { Menu } from '../Main/Menu';

const ReportsHeaderTop = (props) => {
  const getChannelName = props.getChannelName;
  let channel = props.channel;

  let showUserSearch = props.showUserSearch

  const showHideUserSearch = props.showHideUserSearch;
  const moveMenu = props.moveMenu;

  return (
    <div className="reports-header">
      <UserSearch
        show={showUserSearch}
        showHideUserSearch={props.showHideUserSearch}
      />
      <div className="row">
        <div className="col-8">
          <span className="reports-header-text">{getChannelName(channel)}</span>
        </div>
        <div className="col-4 text-right">
          <button
            onClick={showHideUserSearch}
            style={{ background: 'transparent', border: 'none', color: 'white', padding: 0 }}
          >
            <i className="fas fa-search data-search"></i>
          </button>
          <button
            className="reports-menu-button"
            onClick={() => moveMenu()}
            style={{ background: 'transparent', border: 'none', color: 'white', padding: 0 }}
          >
            <i className="fas fa-bars data-menu"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

class ReportsHeaderBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      resetSelect: false
    };
  };

  changeDatesFromProps = () => {
    if (this.props.dateFrom && this.props.dateTo) {

      let dateFromProps = moment(this.props.dateFrom);
      let dateToProps = moment(this.props.dateTo);

      if (this.state.startDate === null) {
        this.setState({
          startDate: dateFromProps,
          endDate: dateToProps
        });
      }
    }
  }

  componentDidUpdate = () => { this.changeDatesFromProps(); }


  afterStateChange = () => {
    let { startDate, endDate, focusedInput } = this.state;

    if (!focusedInput && this.valuesDirty) {
      this.valuesDirty = false;
      this.props.onChange && this.props.onChange({ startDate: startDate, endDate: endDate });
      this.props.changeDate(this.state.startDate, this.state.endDate);
      this.changeTimespan('reset');
    }
  };

  handleDatesChange = ({ startDate, endDate }) => {
    if (startDate && !startDate.isSame(this.state.startDate)) {
      this.valuesDirty = true;
    }
    if (endDate && !endDate.isSame(this.state.endDate)) {
      this.valuesDirty = true;
    }

    this.setState({ startDate, endDate }, this.afterStateChange);
  };

  handleFocusChange = focusedInput => {
    this.setState({ focusedInput: focusedInput }, this.afterStateChange);
  };

  timespanSeletion = [
    { type: 'timespan', value: '24', label: messages['Data.timespanpselect.24hours'] },
    { type: 'timespan', value: '48', label: messages['Data.timespanpselect.48hours'] },
    { type: 'timespan', value: '30', label: messages['Data.timespanpselect.30days'] },
    { type: 'timespan', value: '60', label: messages['Data.timespanpselect.60days'] },
    { type: 'timespan', value: '90', label: messages['Data.timespanpselect.90days'] },
    { type: 'timespan', value: '0', label: messages['Data.timespanpselect.revert'] },
  ]

  changeTimespan = (value) => {
    let startDate = false;
    let resetSelect = false;

    switch (value) {
      case '90':
        startDate = moment().subtract(3, 'month');
        break;
      case '60':
        startDate = moment().subtract(2, 'month');
        break;
      case '30':
      case '0':
      default:
        startDate = moment().subtract(1, 'month');
        break;
      case '0':
        startDate = moment().subtract(1, 'month');
        resetSelect = true;
        break;
      case '48':
        startDate = moment().subtract(2, 'day');
        break;
      case '24':
        startDate = moment().subtract(1, 'day');
        break;
      case 'reset':
        this.setState({ resetSelect: true });
        return false;
        break;
    }
    let endDate = moment();

    this.setState({
      startDate: startDate,
      endDate: endDate,
      resetSelect: resetSelect
    }, () => this.props.changeDate(startDate, endDate));
  }

  render() {
    return (
      <div className="data-header-bottom text-right" >
        <div className="timespan-datepicker">
          {this.state.resetSelect ?
            <Select
              theme={selectTheme}
              classNamePrefix="data-timespan-select"
              options={this.timespanSeletion}
              isSearchable={false}
              value={null}
              placeholder={<FormattedMessage
                id="Data.timespanplaceholder"
                defaultMessage="Zeitraum"
              />}
              onChange={(e) => { this.changeTimespan(e.value) }}
            />
            :
            <Select
              theme={selectTheme}
              classNamePrefix="data-timespan-select"
              options={this.timespanSeletion}
              isSearchable={false}
              placeholder={<FormattedMessage
                id="Data.timespanplaceholder"
                defaultMessage="Zeitraum"
              />}
              onChange={(e) => { this.changeTimespan(e.value) }}
            />
          }
          <span className="time-span-text">
            <FormattedMessage
              id="Data.timespantext"
              defaultMessage="Dargestellter Zeitraum"
            />:</span>
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId="data-bottom-header-startdate"
            endDate={this.state.endDate}
            endDateId="data-bottom-header-enddate"
            onDatesChange={({ startDate, endDate }) => this.handleDatesChange({ startDate, endDate })}
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.handleFocusChange(focusedInput)}
            numberOfMonths={(window.innerWidth < 650) ? 1 : 2}
            monthFormat="MMMM YYYY"
            isOutsideRange={day => (moment().diff(day, 'days') < 0)}
            readOnly={true}
          />
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class Reports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      channels: ['newsletter', 'sms', 'print', 'banner'],
      channel: '',
      showMenu: false,
      showUserSearch: false,
      dateFrom: null,
      dateTo: null,
      journies: [],
      currentJourney: null,
      startDate: null,
      endDate: null,
      type: false
    }
  }

  showHideUserSearch = () => {
    this.setState({
      showMenu: false,
      showUserSearch: !this.state.showUserSearch
    });
  }

  moveMenu = () => {
    this.setState({
      showUserSearch: false,
      showMenu: !this.state.showMenu
    });
  }

  showJourneyDetails = (journey_id) => {
    this.setState({
      currentJourney: journey_id
    });
  }

  showHideJournies = (e) => {
    let target = document.querySelector('#reports-journies-inner-' + e.target.id);
    let carretUp = document.querySelector('.reports-journies-inner-indicator-up#' + e.target.id);
    let carretDown = document.querySelector('.reports-journies-inner-indicator-down#' + e.target.id);
    let currentStyle = target.style.display;
    if (currentStyle === '' || currentStyle === 'block') { // is open => close
      target.style.display = 'none';
      carretDown.style.display = 'none';
      carretUp.style.display = 'block';
    } else { // is closed => open
      target.style.display = 'block';
      carretDown.style.display = 'block';
      carretUp.style.display = 'none';
    }
  }

  getJournies = () => {
    var that = this;
    let currentToken = localStorage.getItem('token');

    if (!currentToken) {
      return false;
    }

    fetch(apiPath + 'journies/type', {
      method: 'GET',
      dataType: 'json',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + currentToken
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        localStorage.setItem('client_id', data[0]['client_id']);
        let journies = [];
        journies = (data.map((type) =>
          <li className="row" key={type.id}>
            <i className="fas fa-caret-up reports-journies-inner-indicator-up" id={type.name.toLowerCase()} style={{ display: 'none' }}></i>
            <i className="fas fa-caret-down reports-journies-inner-indicator-down" id={type.name.toLowerCase()}></i>
            <div className="col-md-11 text-left bold journey-type-name" id={type.name.toLowerCase()} onClick={(e) => that.showHideJournies(e)}>{type.name}</div>
            {/* <div className="col-md-2 text-right">0</div> for development
            <div className="col-md-1 icon-color">
              <i className="fas fa-users"></i>
            </div> */}
            <ul className="reports-journies-left-side-inner col-md-12" id={"reports-journies-inner-" + type.name.toLowerCase()}>
              {type.journies.map(function (journey) {
                return (
                  <li className="row" key={journey.id}>
                    <div className="col-md-12 text-left">
                      <Link
                        to={{ pathname: '/reports/journey/' + journey.id }}>
                        <span className="single-journey-name">{journey.name}</span>
                      </Link>
                    </div>
                    {/* <div className="col-md-2 text-right">0</div> for development
                    <div className="col-md-1 icon-color">
                      <i className="fas fa-users"></i>
                    </div> */}
                    <hr />
                  </li>
                );
              })}
            </ul>
          </li>
        ));

        that.setState({
          journies: journies
        });
      })
      .catch(function (error) {
        globalErrorHandler(error);
      });
  }


  getChannelName = (channel) => {
    let channelName = '';
    switch (channel) {
      case 'allgemeine_werte':
        channelName = 'Allgemeine werte reports';
        break;
      case 'journey':
        channelName = 'Journey reports';
        break;
      default:
        channelName = "Reports";
    }
    return channelName;
  }

  componentWillMount = () => {
    this.checkParams();
    this.getJournies();
  }

  componentDidMount = () => {
    let color = '#399A44';
    changeThemeColor(color);
    this.changeDate(moment().subtract(1, 'month'), moment());
  }

  componentDidUpdate = () => {
    this.checkParams();
  }

  checkParams = () => {
    if (Object.keys(this.props.match.params).length > 0) {
      if (this.props.match.params.channel !== this.state.channel) {
        this.setState({
          channel: this.props.match.params.channel,
          showMenu: false
        });
      }
      if (this.props.match.params.type !== this.state.type && this.props.match.params.type !== undefined) {
        this.setState({
          type: this.props.match.params.type,
          showMenu: false
        });
      }
      if (this.props.match.params.id !== this.state.currentJourney) {
        this.setState({
          currentJourney: this.props.match.params.id,
          showMenu: false
        });
      }
    }
  }


  changeDate = (dateFrom, dateTo) => {
    if (dateFrom !== this.state.dateFrom) {
      this.setState({
        dateFrom: dateFrom,
      });
    }
    if (dateTo !== this.state.dateTo) {
      this.setState({
        dateTo: dateTo,
      });
    }
  }

  render() {
    return (
      <div className="reports droid-module">
        <Menu
          showMenu={this.state.showMenu}
          moveMenu={this.moveMenu}
          moduleName="reports"
        />
        <ReportsHeaderTop
          channel={this.state.channel}
          getChannelName={this.getChannelName}
          showHideUserSearch={this.showHideUserSearch}
          showUserSearch={this.state.showUserSearch}
          moveMenu={this.moveMenu}
        />
        {((!this.state.type || this.state.type === 'allgemeine_werte')) ?
          <div className="data-lower-part">
            <div className="row">
              <div className="col-md-12 ">
                <ReportsCharts
                  channel='reports_general'
                  journey={this.state.currentJourney}
                  dateFrom={this.state.dateFrom}
                  dateTo={this.state.dateTo}
                  unique={this.props.match.params.id}
                  categories={this.props.categories}
                />
              </div>
            </div>
          </div>
          :
          <div className="data-lower-part">
            <div className="row">
              <div className="col-md-3">
                <div className="reports-journies-left-side">
                  <span>
                    <FormattedMessage
                      id="Reports.journey.journeyCategories"
                      defaultMessage="Journey - Kategorien"
                    />:
                  </span>
                  <ul className="main-ul">
                    {this.state.journies}
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-9 reports-journies-right-side">
                {this.props.match.params.id ?
                  <div>
                    <ReportsHeaderBottom
                      dateFrom={this.state.dateFrom}
                      dateTo={this.state.dateTo}
                      changeDate={this.changeDate}
                      channel={this.state.channel}
                    />
                    <ReportsCharts
                      channel='journey_insight'
                      journey={this.state.currentJourney}
                      dateFrom={this.state.dateFrom}
                      dateTo={this.state.dateTo}
                      unique={this.props.match.params.id}
                      categories={this.props.categories}
                    />
                  </div>
                  :
                  <FormattedMessage
                    id="Reports.journey.chooseListLeftSide"
                    defaultMessage="Bitte wählen Sie aus der Liste auf der linken Seite"
                  />
                }
              </div>
            </div>
          </div>
        }

      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Reports
