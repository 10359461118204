import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import { DataLineChart, DataProgressBarChart, SingleTile } from './prototypeCharts';
import { getDataProgressChartSet } from './ChartOptions';
import { getChartData } from './fetchData';
import { getLabelsAndData, messages, apiPath, decimalAdjust } from '../../../reusableFunctions';
import {
  createChartsDatasetsProgressCircle,
  SingleChartWrapper
} from '../Charts';

class PrintCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      send: false,
      address: false,
      returnCharts: false,
      dateFrom: null,
      dateTo: null,
      finishedLoading: false,
      monthly: false
    };
  };

  componentWillMount = () => { this.getData(); }

  componentDidUpdate = () => { this.changeDate(); }

  changeMonthly = (bool) => {
    if (bool !== this.state.monthly) {
      this.setState({
        monthly: bool,
      }, () => this.reloadData());
    }
  }

  changeDate = () => {
    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      });
    } else {
      if (
        (this.props.dateFrom.format('YYYY-MM-DD') !== this.state.dateFrom.format('YYYY-MM-DD')) ||
        (this.props.dateTo.format('YYYY-MM-DD') !== this.state.dateTo.format('YYYY-MM-DD'))
      ) {
        this.setState({
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo
        }, () => this.reloadData());
      }
    }
  }

  reloadData = () => {
    this.setState({
      finishedLoading: false
    }, () => this.getData());
  }

  getHasAddress = () => {
    return fetch(apiPath + 'data/address', {
      method: 'GET',
      mode: 'cors',
      dataType: 'json',
      headers: {
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      return data;
    }).catch(function (error) {
      return error;
    });
  }

  getData = () => {
    let dateFrom = this.props.dateFrom;
    let dateTo = this.props.dateTo;

    if (!this.state.finishedLoading) {
      NProgress.start();
      getChartData('print', 'send', dateFrom, dateTo, this.state.monthly).then(
        response => this.setState(
          { send: getLabelsAndData(response) },
          () => {
            this.getHasAddress().then(
              response => this.setState(
                { address: response },
                () => { this.constructCharts() && NProgress.set(0.4) }
              )
            )
          }
        )
      );
    }
  }

  constructCharts = () => {
    let address = this.state.address ? this.state.address : false;
    address['percent'] = decimalAdjust('floor', (address.address / address.total) * 100, -1);
    let currentDataProgressChartProto = getDataProgressChartSet();
    currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.send.data,
      <FormattedMessage id="Data.charts.print.sentPrint" defaultMessage="Versendete Direct-2-Print" />,
      currentDataProgressChartProto
    );

    let returnCharts = <div>
      <SingleChartWrapper
        rightChart={
          <DataProgressBarChart
            topText={<FormattedMessage id="Data.charts.print.sentPrint" defaultMessage="Versendete Direct-2-Print" />}
            data={currentDataProgressChartProto}
          />
        }
        lineChart={
          <DataLineChart
            labels={[this.state.send.labels]}
            names={[messages['Data.charts.print.sentPrint']]}
            data={[this.state.send.data]}
            stateMonthly={this.state.monthly}
            changeMonthly={this.changeMonthly}
          />
        }
      />
      {address && (address.total !== address.address) ?
        <div className="row crm-card-wrapper shadow print-address">
          <SingleTile
            text={messages['Data.charts.print.totalCustomers']}
            number={address.total}
          />
          <SingleTile
            text={messages['Data.charts.print.reachable']}
            number={address.address + ' (' + address.percent + '%)'}
          />
        </div>
        : null}
    </div>;

    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {(this.state.finishedLoading || this.state.dateFrom !== null) ?
          <div>
            <div>{this.state.returnCharts}</div>
          </div>
          :
          <div className="text-center">
            <span className="bold">
              <FormattedMessage
                id="Data.charts.print.loadingCharts"
                defaultMessage="Charts werden geladen"
              />
            </span>
          </div>
        }
      </div>
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default PrintCharts;