import React, { Component } from 'react';

import NProgress from 'nprogress';

import { apiPath } from '../../reusableFunctions';

import NewsletterChart from './Charts/Newsletter';
import SMSChart from './Charts/SMS';
import BannerChart from './Charts/Banner';
import RetargetingBannerChart from './Charts/RetargetingBanner';
import ConversionChart from './Charts/Conversion';
import PrintChart from './Charts/Print';

const Step = (props) => {
  if (props.channel === "newsletter") {
    return <NewsletterChart
      step={props.step}
      journeyId={props.journeyId}
      abJourney={props.abJourney}
      dateFrom={props.dateFrom}
      dateTo={props.dateTo}
      abJourneyIndex={props.abJourneyIndex}
    />
  } else if (props.channel === 'sms') {
    return <SMSChart
      step={props.step}
      journeyId={props.journeyId}
    />
  } else if (props.channel === 'banner') {
    return <BannerChart
      step={props.step}
      journeyId={props.journeyId}
    />
  } else if (props.channel === 'retargetingbanner') {
    return <RetargetingBannerChart
      step={props.step}
      journeyId={props.journeyId}
    />
  } else if (props.channel === 'print') {
    return <PrintChart
      step={props.step}
      journeyId={props.journeyId}
    />
  } else {
    return false;
  }
}

const JourneySteps = (props) => {
  if (props.steps) {
    return props.steps.map((step, index) => {
      return (
        <div key={step.id}>
          {props.conversion && index == 0 ?
            <ConversionChart
              step={step}
              journeyId={props.journeyId}
              abJourney={props.abJourney}
              abJourneyIndex={index}
              dateFrom={props.dateFrom}
              dateTo={props.dateTo}
            /> : null
          }
          <Step
            step={step}
            channel={step.channel}
            journeyId={props.journeyId}
            abJourney={props.abJourney}
            abJourneyIndex={index}
            dateFrom={props.dateFrom}
            dateTo={props.dateTo}
          />
        </div>
      )
    });
  } else {
    return false;
  }
}

class JourneyInsight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journeySteps: [],
      journeyId: 0,
      journeyData: false,
      journeyInfo: false,
      abJourney: false,
      conversion: false,
      dateFrom: false,
      dateTo: false
    };
  };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = () => {
    this.changeDate();
  }

  getData = () => {
    let currentToken = localStorage.getItem('token');
    if (!currentToken) {
      return false;
    }
    // if (this.state.journeyId !== this.props.unique) {
    this.setState({
      journeyId: this.props.unique
    }, () => {
      NProgress.start();
      this.fetchJourneyInfo(currentToken);
      this.fetchJourneyData(currentToken);
    });
    // }
  }

  changeDate = () => {
    if (this.state.dateFrom === null && this.state.dateTo === null) {
      this.setState({
        dateFrom: this.props.dateFrom.format('YYYY-MM-DD'),
        dateTo: this.props.dateTo.format('YYYY-MM-DD')
      });
    } else {
      if (
        (this.props.dateFrom.format('YYYY-MM-DD') !== this.state.dateFrom) ||
        (this.props.dateTo.format('YYYY-MM-DD') !== this.state.dateTo)
      ) {
        this.setState({
          dateFrom: this.props.dateFrom.format('YYYY-MM-DD'),
          dateTo: this.props.dateTo.format('YYYY-MM-DD')
        }, () => this.getData());
      }
    }
  }

  fetchJourneyInfo = (token) => {
    let that = this;

    fetch(apiPath + 'journey/' + this.state.journeyId, {
      method: 'GET',
      dataType: 'json',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        let abJourney = false;
        let conversion = data.conversion === 'on' ? true : false;
        for (let i in data.steps) {
          if (data.steps[i].template_ab && data.steps[i].template_ab_id) {
            abJourney = true;
          }
        }
        that.setState({
          journeyInfo: data,
          journeySteps: data.steps,
          abJourney: abJourney,
          conversion: conversion
        }, () => NProgress.set(0.5));
      });
  }

  fetchJourneyData = (token) => {
    let that = this;
    fetch(apiPath + 'data/journey/' + this.state.journeyId, {
      method: 'GET',
      dataType: 'json',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        that.setState({
          journeyData: data
        });
      });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 mt-2">
            <div
              className="reports-journey-back-mobile"
              style={{ dispay: 'none', cursor: 'pointer' }}
              onClick={() => window.history.go(-1)}
            >
              <i className="fas fa-arrow-left"></i>
            </div>
            <h4>{this.state.journeyInfo.name}</h4>
          </div>
        </div>
        <div>
          <JourneySteps
            abJourney={this.state.abJourney}
            steps={this.state.journeySteps}
            journeyId={this.state.journeyId}
            conversion={this.state.conversion}
            dateFrom={this.state.dateFrom}
            dateTo={this.state.dateTo}
          />
        </div>

      </div>
    );
  }

  componentWillUnmount = () => {
    this.setState({
      journeySteps: [],
      journeyId: 0,
      journeyData: false,
      journeyInfo: false,
      abJourney: false
    });
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default JourneyInsight;