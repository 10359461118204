

import moment from 'moment';
import 'moment/locale/de-at';

import { apiPath } from '../../../reusableFunctions';

import Droid from '../../Main/Droid';

export function getChartData(channel, query, dateFrom, dateTo, monthly = false) {
  if (dateFrom === undefined || dateFrom === null) {
    dateFrom = moment().subtract(1, 'month').format('YYYY-MM-DD');
  } else {
    dateFrom = dateFrom.format('YYYY-MM-DD');
  }

  if (dateTo === undefined || dateTo === null) {
    dateTo = moment().format('YYYY-MM-DD');
  } else {
    dateTo = dateTo.format('YYYY-MM-DD');
  }

  if (!query) {
    throw new Error('No query defined');
  }
  let currentToken = localStorage.getItem('token');
  if (!currentToken) {
    // Droid.removeTokenFromLocalStorage();
    // throw new Error('No Token');
  }

  let url = apiPath + 'data/' + channel + '/' + dateFrom + '/' + dateTo + '/' + query;
  if (channel === 'basket' || channel === 'bounces') {
    url = apiPath + 'data/' + channel + '/' + query + '/' + dateFrom + '/' + dateTo;
  }
  if (channel === 'reachable') {
    url = apiPath + 'data/' + channel + '/' + query;
  }

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    dataType: 'json',
    headers: {
      'Cache-Control': 'no-cache',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    }
  }).then(function (response) {
    if (response.status !== 401) {
      return response.json();
    } else {
      Droid.removeTokenFromLocalStorage();
      location.reload();
    }
  }).then(function (data) {
    if (data.message === "Token has expired") {
      Droid.removeTokenFromLocalStorage();
    }

    if (monthly) {
      var groupByMonth = [];
      Object.keys(data).forEach(function (key, index) {
        groupByMonth[key.split('-')[1] + '-' + key.split('-')[2]] = (groupByMonth[key.split('-')[1] + '-' + key.split('-')[2]] || 0) + data[key];
      });
      return groupByMonth;
    } else {
      return data;
    }

  }).catch(function (error) {
    Droid.removeTokenFromLocalStorage();
    return error;
  });
}


export function getChartDataReports(channel, query, from, to, journeyId) {
  if (!query) {
    throw new Error('No query defined');
  }
  if (!channel) {
    throw new Error('No channel defined');
  }

  let currentToken = localStorage.getItem('token');
  if (!currentToken) {
    Droid.removeTokenFromLocalStorage();
    // throw new Error('No Token');
  }

  return fetch(apiPath + 'report/' + channel + '/' + journeyId + '/' + from + '/' + to + '/' + query, {
    method: 'GET',
    mode: 'cors',
    dataType: 'json',
    headers: {
      'Cache-Control': 'no-cache',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    }
  }).then(function (response) {
    return response.json();
  }).then(function (data) {
    if (data.message === "Token has expired") {
      Droid.removeTokenFromLocalStorage();
    }
    return data;
  }).catch(function (error) {
    Droid.removeTokenFromLocalStorage();
    return error;
  });
}
