import React, { Component } from 'react';
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document
} from '@react-pdf/renderer';

import { language, messages } from '../../reusableFunctions';

let GDPRLanguage = 'de';
if (language.includes('en')) {
  GDPRLanguage = 'en';
}

const styles = ({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    paddingTop: 10
  },
  subtitle: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10
  },
  subtitleRed: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 20,
    color: 'red'
  },
  text: {
    margin: 2,
    fontSize: 14,
    textAlign: 'justify',
  },
  textAbsatz: {
    margin: 2,
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'justify',
  },
  textAbsatzOben: {
    margin: 2,
    fontSize: 14,
    marginTop: 10,
    textAlign: 'justify',
  },
  textUnderline: {
    margin: 2,
    fontSize: 14,
    textAlign: 'justify',
    textDecoration: 'underline'
  },
  textbold: {
    margin: 2,
    fontSize: 15,
    textAlign: 'justify',
  },
  stammdatentext: {
    margin: 2,
    fontSize: 14,
    textAlign: 'left',
  },
  blabla: {
    fontSize: 10,
    textAlign: 'justify'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  pageHeader: {
    position: 'absolute',
    fontSize: 12,
    top: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  contactView: {
    marginTop: 10,
    marginBottom: 10
  },
  stammdatenView: {
    marginTop: 10,
    marginBottom: 10
  },
  tableViewLeft: {
    borderTopColor: 'black',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderBottomColor: 'black',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderRightColor: 'black',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderLeftColor: 'black',
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    width: '50%'
  },
  tableViewRight: {
    borderTopColor: 'black',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderBottomColor: 'black',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderRightColor: 'black',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderLeftColor: 'black',
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    width: '50%'
  }
});

const PDFDocument = (customer, timeline) => {

  let dateNow = new Intl.DateTimeFormat('de-DE', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date());
  let timeNow = new Intl.DateTimeFormat('de-DE', { hour: 'numeric', minute: 'numeric' }).format(new Date());
  if (GDPRLanguage === 'en') {
    return (
      <Document
        title="Requests for information according to article 15"
        creator="Droid Marketing"
        producer="Droid Marketing"
      >
        <Page style={styles.body}>
          <Text style={styles.pageHeader} render={({ pageNumber }) => (
            (pageNumber !== 1) ? "Requests for information according to article 15" : ''
          )} fixed />
          <View>
            <Text style={styles.title} wrap={false}>Requests for information according to article 15</Text>
            <Text style={styles.subtitle}>
              Pursuant to Art. 15 GDPR, every data subject has the right to obtain information about the data
              stored about him or her. This report informs about all those personal data of the person concerned
              which were stored in the customer system on {dateNow} at {timeNow}.
          </Text>
            <Text style={styles.subtitleRed}>
              This information serves the data protection representative of the responsible person to answer the request for information.
          </Text>
          </View>
          <View>
            <Text style={styles.textUnderline}>The following data will be processed:</Text>
            <View style={styles.stammdatenView}>
              <Text style={styles.textUnderline}>Personal data and interests:</Text>
              {Object.keys(customer).map((info, index) =>
                <Text style={styles.stammdatentext} key={index}>{info + ': ' + customer[info]}</Text>
              )}
            </View>
            <Text style={styles.textUnderline}>Behavioral data:</Text>
            {(timeline.web.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Web:</Text>
                {timeline.web.map((web, index) =>
                  <Text style={styles.stammdatentext} key={index}>{web.url}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.mail.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Mail:</Text>
                {timeline.mail.map((mail, index) =>
                  <Text style={styles.stammdatentext} key={index}>{mail.action + ' - ' + mail.email}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.sms.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>SMS:</Text>
                {timeline.sms.map((sms, index) =>
                  <Text style={styles.stammdatentext} key={index}>{sms.action + ' - ' + sms.phone}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.banner.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Website Banner:</Text>
                {timeline.banner.map((banner, index) =>
                  <Text style={styles.stammdatentext} key={index}>{banner.action + ' - ' + banner.email}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.print.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Direct-2-Print:</Text>
                {timeline.print.map((print, index) =>
                  <Text style={styles.stammdatentext} key={index}>{print.action + ' - ' + print.email}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
          </View>
          <View>
            <Text style={styles.textUnderline}>Origin of the data:</Text>
            <Text style={styles.text}>- From the person concerned</Text>
            <Text style={styles.textAbsatz}>- Third parties: If a data enrichment is commissioned by the responsible party, this will be carried out by Österreichische Post AG.</Text>
          </View>
          <Text style={styles.textUnderline}>Transmission of the data:</Text>
          <Text style={styles.text}>The following data can be disclosed to the following categories of recipients:</Text>
          <Text style={styles.textbold}>Data:</Text>
          <Text style={styles.text}>1. Form of address, title, first name, last name, e-mail address, customer number</Text>
          <Text style={styles.text}>2. Form of address, title, first name, last name, address, postal code, city, customer number</Text>
          <Text style={styles.text}>3. Telephone number</Text>
          <Text style={styles.textbold}>Categories of recipients:</Text>
          <Text style={styles.text}>1. E-mail-sending -provider</Text>
          <Text style={styles.text}>2. Production, lettershop, postal sending -provider</Text>
          <Text style={styles.text}>3. SMS-sending -provider</Text>
          <Text style={styles.textAbsatzOben}>No data will be transferred to third countries.</Text>
          <Text>---------------------------------------------------------------------------------------</Text>
          <View style={styles.contactView}>
            <Text style={styles.blabla}>
              Any data subject may request the rectification of incorrect data (Art. 16 GDPR) and the deletion of inadmissibly processed data (Art. 17 GDPR) from the data processor. A data subject may raise objections to data processing based on Art. 6 (1)(e) (provision of public services, in particular: official tasks) or - in the private area - on Art. 6 (1)(f) (legitimate interests of the responsible or a third party) on the grounds of violation of his data protection interests according to Art. 21 GDPR. For the duration of a legal dispute concerning data processing or if data needs to be secured for evidence purposes of the data subject, the data subject may assert his or her right to limitation of processing (Art. 18 GDPR) against the data processor. A right to data transferability is entitled within the scope of Art. 20 GDPR. In case that the person responsible does not take full account of a request made by a data subject, the data subject is entitled to appeal to the data protection authority.
          </Text>
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    )
  } else {
    return (
      <Document
        title="Informationen für das Auskunftsbegehren nach Artikel 15"
        creator="Droid Marketing"
        producer="Droid Marketing"
      >
        <Page style={styles.body}>
          <Text style={styles.pageHeader} render={({ pageNumber }) => (
            (pageNumber !== 1) ? "Informationen für das Auskunftsbegehren nach Artikel 15" : ''
          )} fixed />
          <View>
            <Text style={styles.title} wrap={false}>Informationen für das Auskunftsbegehren nach Artikel 15</Text>
            <Text style={styles.subtitle}>
              Gemäß Art. 15 DSGVO hat jeder Betroffene das Recht auf eine Auskunft über die zu seiner
              Person gespeicherten Daten. Dieser Bericht informiert über all jene personenbezogenen Daten des
            Betroffenen, die am {dateNow} um {timeNow} Uhr im Kundensystem gespeichert waren.
          </Text>
            <Text style={styles.subtitleRed}>
              Diese Informationen dienen dem Datenschutzbeauftragten des Verantwortlichen, das Auskunftsbegehren zu beantworten.
          </Text>
          </View>
          <View>
            <Text style={styles.textUnderline}>Folgende Daten werden verarbeitet:</Text>
            <View style={styles.stammdatenView}>
              <Text style={styles.textUnderline}>Stammdaten und Interessen:</Text>
              {Object.keys(customer).map((info, index) =>
                <Text style={styles.stammdatentext} key={index}>{info + ': ' + customer[info]}</Text>
              )}
            </View>
            <Text style={styles.textUnderline}>Verhaltensdaten:</Text>
            {(timeline.web.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Web:</Text>
                {timeline.web.map((web, index) =>
                  <Text style={styles.stammdatentext} key={index}>{(web.created_at ? web.created_at + ' - ' : '') + web.url}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.mail.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Mail:</Text>
                {timeline.mail.map((mail, index) =>
                  <Text style={styles.stammdatentext} key={index}>{mail.action + ' - ' + mail.email + (mail.created_at ? ' - ' + mail.created_at : '')}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.sms.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>SMS:</Text>
                {timeline.sms.map((sms, index) =>
                  <Text style={styles.stammdatentext} key={index}>{sms.action + ' - ' + sms.phone}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.banner.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Website Banner:</Text>
                {timeline.banner.map((banner, index) =>
                  <Text style={styles.stammdatentext} key={index}>{banner.action + ' - ' + banner.email}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
            {(timeline.print.length > 0) ?
              <View style={styles.stammdatenView}>
                <Text style={styles.textbold}>Direct-2-Print:</Text>
                {timeline.print.map((print, index) =>
                  <Text style={styles.stammdatentext} key={index}>{print.action + ' - ' + print.email}</Text>
                )}
              </View>
              :
              <Text></Text>
            }
          </View>
          <View>
            <Text style={styles.textUnderline}>Herkunft der Daten:</Text>
            <Text style={styles.text}>- Vom Betroffenen</Text>
            <Text style={styles.textAbsatz}>- Von Dritten: Wenn eine Datenanreicherung seitens des Verantwortlichen beauftragt wird, erfolgt diese durch die Österreichische Post AG.</Text>
          </View>
          <Text style={styles.textUnderline}>Weitergabe der Daten:</Text>
          <Text style={styles.text}>Folgende Daten können gegenüber den folgenden Empfängerkategorien offengelegt werden:</Text>
          <Text style={styles.textbold}>Daten:</Text>
          <Text style={styles.text}>1. Anrede, Titel, Vorname, Nachname, E-Mail-Adresse, Kundennummer</Text>
          <Text style={styles.text}>2. Anrede, Titel, Vorname, Nachname, Adresse, PLZ, Ort, Kundennummer</Text>
          <Text style={styles.text}>3. Telefonnummer</Text>
          <Text style={styles.textbold}>Empfängerkategorien:</Text>
          <Text style={styles.text}>1. E-Mail-Versanddienstleister</Text>
          <Text style={styles.text}>2. Produktion, Lettershop, postalischer Versanddienstleister</Text>
          <Text style={styles.text}>3. SMS-Versanddienstleister</Text>
          <Text style={styles.textAbsatzOben}>Es erfolgt keine Datenübermittlung ins Drittausland.</Text>
          <Text>---------------------------------------------------------------------------------------</Text>
          <View style={styles.contactView}>
            <Text style={styles.blabla}>
              Jede betroffene Person kann die Berichtigung unrichtiger Daten (Art. 16 DSGVO) und die Löschung unzulässig verarbeiteter Daten (Art. 17 DSGVO) vom Verantwortlichen fordern.
              Gegen eine Datenverarbeitung, die sich auf Art. 6 (1)(e) (Besorgung öffentlicher, insbesondere: behördlicher Aufgaben) oder - im privaten Bereich - auf Art. 6 (1)(f) (berechtigte Interessen des Verantwortlichen oder eines Dritten) stützt,
              kann ein Betroffener beim Verantwortlichen Widerspruch wegen Verletzung seiner Datenschutzinteressen nach Art. 21 DSGVO erheben. Während der Dauer eines Rechtsstreits über eine Datenverarbeitung oder wenn Daten zu Beweiszwecken des Betroffenen gesichert werden sollen,
              kann der Betroffene sein Recht auf Einschränkung der Verarbeitung (Art.18 DSGVO) gegenüber dem Verantwortlichen geltend machen. Ein Recht auf Datenübertragbarkeit steht im Umfang des Art. 20 DSGVO zu.
              Für den Fall, dass der Verantwortliche einem Begehren eines Betroffenen nicht vollinhaltlich Rechnung trägt, steht dem Betroffenen ein Beschwerderecht an die Datenschutzbehörde zu.
          </Text>
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    )
  }
}

export class DownloadGDPR extends Component {

  constructor(props) {
    super(props);
    this.state = { loading: false }
  }

  render() {

    let pdfName = "AuskunftDSGVO-Droid.pdf";
    if (GDPRLanguage === 'en') {
      pdfName = "informationGDPR-Droid.pdf"
    }

    return (
      <div>
        {this.props.showGDPR ?
          <PDFDownloadLink document={PDFDocument(this.props.customer, this.props.timeline)} fileName={pdfName}>
            {({ blob, url, loading, error }) =>
              <div>
                {!error ?
                  <button className="user-profile-info-button gdpr">
                    {(loading || this.state.loading) ? messages['UserSearch.GDPR.generatingDocument'] + '...' : messages['UserSearch.GDPR.dataInformation']}
                  </button>
                  :
                  <button className="user-profile-info-button gdpr">
                    Error
                  </button>
                }
              </div>
            }
          </PDFDownloadLink>
          :
          <button className="user-profile-info-button gdpr">{messages['UserSearch.GDPR.generatingDocument'] + '...'}</button>
        }
      </div >
    )
  }
}