import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import NProgress from 'nprogress';

import { DataLineChart, DataProgressBarChart } from '../../Data/Charts/prototypeCharts';
import { getDataProgressChartSet } from '../../Data/Charts/ChartOptions';
import { getChartDataReports } from '../../Data/Charts/fetchData';
import { getLabelsAndData, messages, decimalAdjust } from '../../../reusableFunctions';
import { createChartsDatasetsProgressCircle, SingleChartWrapperReports } from '../../Data/Charts';

class SMSChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      monthly: true,
      open: false,
      click: false,
      send: false,
      returnCharts: []
    };
  };

  componentWillMount = () => {
    this.collectData();
  }

  currentDataProgressChartProto = [];
  currentDataLineChartProto = [];

  collectData = () => {

    getChartDataReports('sms', 'click', this.props.step.id).then(
      response => this.setState(
        { click: getLabelsAndData(response) },
        () => getChartDataReports('sms', 'open', this.props.step.id).then(
          response => this.setState(
            {
              open: getLabelsAndData(response),
            }, () => getChartDataReports('sms', 'send', this.props.step.id).then(
              response => this.setState(
                {
                  send: getLabelsAndData(response),
                  finishedLoading: true
                }, () => { this.constructChart() && NProgress.set(0.4) }
              )
            )
          )
        )
      )
    );
  }

  constructChart = () => {
    this.currentDataProgressChartProto = [];
    this.currentDataProgressChartProto = getDataProgressChartSet();
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.open.data,
      messages['Reports.charts.sms.open'],
      this.currentDataProgressChartProto
    );
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.click.data,
      messages['Reports.charts.sms.click'],
      this.currentDataProgressChartProto
    );
    this.currentDataProgressChartProto = createChartsDatasetsProgressCircle(
      this.state.send.data,
      messages['Reports.charts.sms.sent'],
      this.currentDataProgressChartProto
    );

    let values = this.currentDataProgressChartProto.values;
    values.map((value, index) => {
      if (index === 0 || index === 1) {
        let percent = decimalAdjust('floor', (value / values[2]) * 100, -1);
        if (!Number.isNaN(percent)) {
          values[index] = value + ' (' + percent + '%)';
        }
      }
    });

    let returnCharts = <SingleChartWrapperReports
      rightChart={
        <DataProgressBarChart
          topText={<FormattedMessage id="Reports.charts.sms.topText" defaultMessage="SMS" />}
          data={this.currentDataProgressChartProto}
          hiddenProgress={true}
          reports={true}
        />
      }
      lineChart={
        <DataLineChart
          labels={[this.state.open.labels, this.state.click.labels, this.state.send.labels]}
          names={[messages['Reports.charts.sms.open'], messages['Reports.charts.sms.click'], messages['Reports.charts.sms.sent']]}
          data={[this.state.open.data, this.state.click.data, this.state.send.data]}
        />
      }
    />

    this.setState({
      returnCharts: returnCharts
    }, () => NProgress.done());
  }

  render() {
    return (
      <div>
        {this.state.returnCharts}
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default SMSChart;