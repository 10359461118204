import React, { Component } from 'react';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { HashRouter as Router, Route, NavLink } from 'react-router-dom';

import { createBrowserHistory } from "history";

import NProgress from 'nprogress';

import Spider from '../Main/Spider';
import Login from '../Login/Login';
import Data from '../Data/Data';
import Audience from '../Audience/Audience';
import Journey from '../Journey/Journey';
import Delivery from '../Delivery/Delivery';
import Reports from '../Reports/Reports';
import UserProfile from '../UserSearch/UserProfile';
import Connector from '../Connector/Connector';
import SVGLogo from './pictures/logo_login.svg';
import Menu from './Menu';

import { apiPath, globalErrorHandler, checkToken } from '../../reusableFunctions';

import './Navbar.css';
import DroidLogoNavbar from '../../pictures/logo_droid_navbar.png';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faCaretDown, faCaretUp, faCog } from '@fortawesome/free-solid-svg-icons'; // Import Icons from FontAwesome

library.add(faCogs, faCaretDown, faCaretUp, faCog); // Add FontAweseome icons to FontAwesome libary

class Droid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      token: localStorage.getItem('token'),
      apiPath: apiPath
    };
  };

  passToken = (token) => {
    this.setState({
      token: token
    }, () => this.getCategories());
  }

  reloadToken = () => {
    let that = this;
    fetch(apiPath, {
      headers: {
        'cache-control': 'no-cache',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      if (response.status === 401) {
        that.logout();
        return false;
      } else {
        return response.json();
      }
    }).then(function (data) {
      if (data.message === 'Unauthenticated.' || data.error === 'Unauthorized') {
        that.logout();
      } else {
        localStorage.setItem('token', data.access_token);
      }
    }).catch(function (error) {
      globalErrorHandler(error);
      that.notifySession();
      Droid.removeTokenFromLocalStorage();
      that.logout();
    });
  }

  logout = (hideToast) => {
    Droid.removeTokenFromLocalStorage(hideToast);
    this.removeTokenFromState();
  }

  static removeTokenFromLocalStorage = (hideToast) => {
    if (!hideToast) {
      toast('Session expired', {
        className: 'login-notify',
        autoClose: 3000,
        progressClassName: 'login-notify-progressbar'
      });
    }
    localStorage.removeItem('token');
    localStorage.removeItem('ttl');
  }

  notifySession = () => toast('Session expired', {
    className: 'login-notify',
    autoClose: 3000,
    progressClassName: 'login-notify-progressbar'
  });

  removeTokenFromState = () => {
    this.setState({
      token: false,
      categories: []
    });
  }

  getCategories = () => {
    let that = this;
    if (!this.state.token) {
      return false;
    }
    fetch(this.state.apiPath + 'categories', {
      headers: { 'Authorization': 'Bearer' + this.state.token },
    })
      .then(function (response) {
        if (response.status === 401) {
          that.logout();
          return false;
        } else {
          return response.json();
        }
      })
      .then(function (data) {
        let categories = [];
        for (let i in data) {
          categories.push(data[i].name);
        }
        if (categories.length === 0) {
          categories = false;
        }
        if (categories !== that.state.categories) {
          that.setState({
            categories: categories
          });
        }
      })
      .catch(function (error) {
        globalErrorHandler(error);
        that.reloadToken();
      })
  }

  componentWillMount = () => {
    this.getCategories();
  }

  render() {
    return (
      <div>
        <ToastContainer />
        {(!this.state.token) ?
          <Login
            passToken={this.passToken}
          />
          :
          <div>
            {this.state.categories ?
              (this.state.categories.length > 0) ?
                <Navigation
                  token={this.state.token}
                  categories={this.state.categories}
                  logout={this.logout}
                  reloadToken={this.reloadToken}
                />
                :
                ''
              :
              <Navigation
                token={this.state.token}
                categories={false}
                logout={this.logout}
                reloadToken={this.reloadToken}
              />
            }
          </div>
        }
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settingsMenu: false,
      navBar: true
    };
  };

  componentDidMount = () => {
    this.checkMobileNavbar();
  }

  checkMobileNavbar = () => {
    if (window.innerWidth < 768) {
      this.setState({
        navBar: false
      });
    }
  }

  moveSettingsMenu = () => {
    this.setState({
      settingsMenu: !this.state.settingsMenu
    });
  }

  moveNavBar = () => {
    this.setState({
      navBar: !this.state.navBar
    });
  }

  render() {
    const history = createBrowserHistory(); // remove NProgress on site change
    history.listen(() => {
      this.checkMobileNavbar();
      NProgress.done();
      NProgress.remove();
      checkToken();
    });

    return (
      <div>
        <Router>
          <div>
            <div className="navbar react-navbar" id="desktop_nav">
              <div className="text-center navbar-switch" onClick={() => this.moveNavBar()}>
                {this.state.navBar ?
                  <i className="fas fa-caret-down"></i>
                  :
                  <i className="fas fa-caret-up"></i>
                }
              </div>
              {this.state.navBar ?
                <div className="inner-navbar">
                  <ul className="nav navbar-nav">
                    <li className="navbar-li navbar-li-center">
                      <NavLink
                        className="droid-logo-link"
                        activeClassName="active"
                        to={{ pathname: '/' }}
                      >
                        <div className="droid-logo">
                          <img id="droid-logo-navbar" src={DroidLogoNavbar} alt="droid logo" />
                        </div>
                      </NavLink>
                    </li>
                    <li className="navbar-li navbar-li-data">
                      <NavLink
                        className="bubble data-connector-bubble nav_data"
                        activeClassName="active"
                        to="/data"
                      >
                        <span className="uppercase bold">Data</span>
                      </NavLink>
                    </li>
                    <li className="navbar-li navbar-li-audience">
                      <NavLink
                        className="bubble evaluation-connector-bubble nav_evaluation"
                        activeClassName="active"
                        to="/audience"
                      >
                        <span className="uppercase bold">Audience</span>
                      </NavLink>
                    </li>
                    <li className="navbar-li navbar-li-journey">
                      <NavLink
                        className="bubble journey-connector-bubble nav_journey"
                        activeClassName="active"
                        to="/journey"
                      >
                        <span className="uppercase bold">Journey</span>
                      </NavLink>
                    </li>
                    <li className="navbar-li navbar-li-creator">
                      <a href="https://creator.droidmarketing.com" target="_blank" rel="noopener noreferrer" className="bubble creator-connector-bubble nav_creator">
                        <span className="uppercase bold">Creator</span>
                      </a>
                    </li>
                    <li className="navbar-li navbar-li-delivery">
                      <NavLink
                        className="bubble delivering-connector-bubble nav_deliver"
                        activeClassName="active"
                        to={{ pathname: '/delivery' }}
                      >
                        <span className="uppercase bold">Delivery</span>
                      </NavLink>
                    </li>
                    <li className="navbar-li navbar-li-reports">
                      <NavLink
                        className="bubble controlling-connector-bubble nav_controlling"
                        activeClassName="active"
                        to={{ pathname: '/reports' }}
                      >
                        <span className="uppercase bold">Reports</span>
                      </NavLink>
                    </li>
                  </ul>
                  <Menu
                    showMenu={this.state.settingsMenu}
                    moveMenu={this.moveSettingsMenu}
                    moduleName="settings"
                    logout={this.props.logout}
                  />
                  <div className="mobile-home">
                    <NavLink to={'/'}>
                      <img id="droid-mobile-home" src={SVGLogo} alt="home" />
                    </NavLink>
                  </div>
                  <div className="settings">
                    {/* <div className="spinning-cogs-wrapper">
                      <i id="gear1" class="fas fa-cog spin"></i>
                      <i id="gear2" class="fas fa-cog spin"></i>
                      <i id="gear3" class="fas fa-cog spin"></i>
                    </div> */}
                    <i className="fas fa-cogs" onClick={() => this.moveSettingsMenu()}></i>
                  </div>
                </div>
                :
                ''
              }
            </div>
            <Route
              exact path="/"
              render={(props) => <Spider {...props} token={this.props.token} />}
            />
            <Route
              path="/user-profile/:email/:shop?"
              render={(props) => <UserProfile {...props} token={this.props.token} />}
            />
            <Route
              path="/data/:channel?"
              render={(props) => <Data {...props} token={this.props.token} categories={this.props.categories} />}
            />
            <Route
              path="/audience"
              render={(props) => <Audience {...props} token={this.props.token} />}
            />
            <Route
              path="/journey"
              render={(props) => <Journey {...props} token={this.props.token} />}
            />
            <Route
              path="/delivery/:channel?"
              render={(props) => <Delivery {...props} token={this.props.token} />}
            />
            <Route
              path="/reports/:type?/:id?"
              render={(props) => <Reports {...props} token={this.props.token} categories={this.props.categories} />}
            />
            <Route
              path="/connector/:type?"
              render={(props) => <Connector {...props} token={this.props.token} categories={this.props.categories} />}
            />
          </div>
        </Router >
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


export default Droid;