import React, { Component } from 'react';


import ConnectorSpider from './ConnectorSpider';
import ConnectorTimeline from './ConnectorTimeline';
class Connector extends Component {

  constructor(props) {
    super(props);
    this.state = { type: false }
  }

  componentDidMount = () => { this.changeType(); }

  componentDidUpdate = () => { this.changeType(); }

  changeType = () => {
    if (this.state.type !== this.props.match.params.type) {
      this.setState({ type: this.props.match.params.type });
    }
  }

  render() {
    return (
      <div className="droid-module connector">
        <div className="row">
          <div className="col-sm-12 col-md-5">
            <ConnectorTimeline
              channel={this.state.type}
            />
          </div>
          <div className="col-md-7">
            <ConnectorSpider />
          </div>
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Connector;