import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import { changeThemeColor, apiPath, globalErrorHandler, messages } from '../../reusableFunctions';

import { JourneyBuilder, notifyChannel, notifyTemplate } from './JourneyBuilder';
import JourneySettings from './JourneySettings';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import { Menu } from '../Main/Menu';

import '../Audience/Audience.css';
import './Journey.css';

import { library } from '@fortawesome/fontawesome-svg-core';
// Import Icons from FontAwesome
import { faSearch, faBars, faArrowLeft, faArrowRight, faPlay, faPause, faPlusCircle, faMinusCircle, faChevronDown, faChevronUp, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

library.add(faToggleOff, faToggleOn, faSearch, faBars, faSave, faArrowLeft, faArrowRight, faPlay, faPause, faTrashAlt, faPlusCircle, faMinusCircle, faChevronDown, faChevronUp);

let saveButtonText = <FormattedMessage id="Journey.saveButtonText" defaultMessage="Speichern" />;
let deleteButtonText = <FormattedMessage id="Journey.delete.deleteButtonText" defaultMessage="Löschen" />;
let journeyTypeName = '';

const JourneyHeader = (props) => {
  let journeyType = props.type;
  let moveSideMenu = props.moveSideMenu;

  if (journeyTypeName === '') {
    switch (journeyType) {
      case 1:
        journeyTypeName = <FormattedMessage id="Journey.typeName.case1" defaultMessage="Journey" />;
        break;
      case 2:
        journeyTypeName = <FormattedMessage id="Journey.typeName.case2" defaultMessage="Verhaltensgesteuerte Journey" />;
        break;
      case 3:
        journeyTypeName = <FormattedMessage id="Journey.typeName.case3" defaultMessage="Zeitgesteuerte Journey" />;
        break;
      default:
        journeyTypeName = <FormattedMessage id="Journey.typeName.default" defaultMessage="Verhaltensgesteuerte Journey" />;
        break;
    }
  }

  return (
    <div className="row journey-header">
      <div className="col-10 text-left">
        <span className="journey_new_artname">{journeyTypeName}</span>
      </div>
      <div className="col-2 text-right">
        <button
          onClick={() => { moveSideMenu() }}
          id="journey_back"
          style={{ background: 'transparent', border: 'none', padding: 0, left: '5px' }}
        >
          <i className="fas fa-bars"></i>
        </button>
      </div>
    </div>
  )
}


const JourneyStartBar = (props) => {
  let personCount = props.personCount;
  let started = props.started;

  const saveJourney = props.saveJourney;
  const deleteJourney = props.deleteJourney;
  const startStopJourney = props.startStopJourney;

  let saved = props.saved;
  let journeyId = props.journeyId;

  function deleteAlert(journeyId) {
    if (started) {
      toast('Eine gestartete Journey kann nicht gelöscht werden.', {
        className: 'journey-notify',
        autoClose: 3000,
        progressClassName: 'journey-notify-progressbar'
      });
      return false;
    }
    confirmAlert({
      title: messages['Journey.delete.title'],
      message: messages['Journey.delete.message'],
      buttons: [
        {
          label: messages['Journey.delete.yes'],
          onClick: () => { deleteJourney(journeyId); }
        },
        {
          label: messages['Journey.delete.no'],
          onClick: () => { return false; }
        }
      ]
    })
  }

  return (
    <div className="startbar-wrapper shadow">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="row">
            <div className="col-7">
              <div className="row">
                <div className="col-6">
                  {started ?
                    <button
                      key={started}
                      onClick={() => { startStopJourney(false) }}
                      className="journey-start-button shadow"
                    >
                      <span>{messages['Journey.stopButtonText']}&ensp;&ensp;</span>
                      <i className="fas fa-pause"></i>
                    </button>
                    :
                    <button
                      key={started}
                      onClick={() => { startStopJourney(true) }}
                      className="journey-start-button shadow"
                    >
                      <span>{messages['Journey.startButtonText']}&ensp;&ensp;</span>
                      <i className="fas fa-play"></i>
                    </button>
                  }
                </div>
                <div className="col-6">
                  {(saved && journeyId !== undefined) ?
                    <Link
                      type="button"
                      className="go-reports-journey"
                      to={{ pathname: '/reports/journey/' + journeyId }}>
                      <span>Reports</span>
                    </Link>
                    :
                    null}
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="little-loading-droid"></div>
              <span className="person-count">
                <FormattedMessage
                  id="Journey.reachablePeople"
                  defaultMessage="{count} erreichbare Personen"
                  values={{
                    count: personCount
                  }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="row">
            {journeyId ?
              <div className="col">
                <button
                  onClick={() => deleteAlert(journeyId)}
                  className="btn-journey btn-journey-save"
                >
                  {deleteButtonText}
                  <i className="far fa-trash-alt"></i>
                </button>
              </div>
              :
              <div className="col">
                <button
                  onClick={() => { saveJourney() }}
                  className="btn-journey btn-journey-save"
                >
                  {saveButtonText}
                  <i className="far fa-save"></i>
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function getBannerBudget() {
  return fetch(apiPath + 'delivery/banner', {
    method: 'GET',
    dataType: 'json',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }).then(function (response) {
    return response.json();
  }).then(function (data) {
    let volume = Number(data.overview.volume) - Number(data.overview.spend_from_volume);
    return volume * Number(data.overview.costs);
  }).catch(function (error) {
    console.log(error);
  });
}
class Journey extends Component {

  notifyJourneyTypeText = <FormattedMessage id="Journey.notify.journeyTypeText" defaultMessage="Bitte wählen sie einen Journey-Typ" />;
  // notifyCategorieText = <FormattedMessage id="Journey.notify.categorieText" defaultMessage="Bitte wählen Sie eine Kategorie" />;
  notifyTargetgroupText = <FormattedMessage id="Journey.notify.targetgroupText" defaultMessage="Bitte wählen Sie eine Empfängergruppe" />;
  notifyIntervallText = <FormattedMessage id="Journey.notify.intervallText" defaultMessage="Bitte wählen Sie ein Intervall" />;
  notifyJourneyNameText = <FormattedMessage id="Journey.notify.journeyNameText" defaultMessage="Bitte geben Sie der Journey einen Namen" />;
  notifyJourneyNameInUseText = 'Journey name already in use'; // @tranlate
  notifySuccessfulSave = <FormattedMessage id="Journey.notify.successfulSave" defaultMessage="Journey erfolgreich gespeichert!" />;
  notifySaveText = <FormattedMessage id="Journey.notify.saveText" defaultMessage="Bitte Journey vorher speichern" />;
  notifyStartText = <FormattedMessage id="Journey.notify.startText" defaultMessage="Journey erfolgreich gestartet" />;
  notifySavedText = <FormattedMessage id="Journey.notify.savedText" defaultMessage="Journey ist bereits gespeichert" />;
  notifyTimeText = <FormattedMessage id="Journey.notify.timeText" defaultMessage="Bitte überprüfen Sie Ihre Journey-Start Zeitangabe" />;
  notifyDeleteText = <FormattedMessage id="Journey.notify.journeyDeleted" defaultMessage="Journey erfolgreich gelöscht" />;
  notfiyRetargetingBudgetText = 'Please set a budget for the Retargetingbanner';
  notifyRetargetingDaysText = 'Please set the days running for the Retargetingbanner';
  notfiyRetargetingBudgetTooLessText = 'Not sufficient available budget for this campaign. Please top up your banner budget.'

  notifyJourneyType = () => toast(this.notifyJourneyTypeText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyTargetgroup = () => toast(this.notifyTargetgroupText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyIntervall = () => toast(this.notifyIntervallText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyJourneyName = () => toast(this.notifyJourneyNameText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyJourneyNameInUse = () => toast(this.notifyJourneyNameInUseText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifySave = () => toast(this.notifySaveText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyStartStop = () => toast(this.notifyStartText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifySaved = () => toast(this.notifySavedText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyTime = () => toast(this.notifyTimeText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyRetargetingBudget = () => toast(this.notfiyRetargetingBudgetText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notfiyRetargetingBudgetTooLess = () => toast(this.notfiyRetargetingBudgetTooLessText, {
    className: 'journey-notify',
    autoClose: 5000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyRetargetingDays = () => toast(this.notifyRetargetingDaysText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyDelete = () => toast(this.notifyDeleteText, {
    className: 'journey-notify',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });
  notifyJourneyError = (message) => toast(message, {
    className: 'journey-notify-error',
    autoClose: 3000,
    progressClassName: 'journey-notify-progressbar'
  });

  journeyIntervals = [
    { type: 'interval', value: '1', label: messages['Journey.journeyStart.timespanpselect.once'] },
    { type: 'interval', value: '10', label: messages['Journey.journeyStart.timespanpselect.everyHour'] },
    { type: 'interval', value: '24', label: messages['Journey.journeyStart.timespanpselect.every24Hours'] },
    { type: 'interval', value: '48', label: messages['Journey.journeyStart.timespanpselect.every48Hours'] },
    { type: 'interval', value: '72', label: messages['Journey.journeyStart.timespanpselect.every72Hours'] },
    { type: 'interval', value: '720', label: messages['Journey.journeyStart.timespanpselect.every30Days'] }
  ]

  conversion = [
    { type: 'conversion', value: 'off', label: 'Off' },
    { type: 'conversion', value: 'on', label: 'On' }
  ]

  targetgroups = []
  categories = []
  types = []
  journey = {};

  constructor(props) {
    super(props);
    this.state = {
      journeyType: 1,
      personCount: 0,
      journeyMenu: false,
      journeyTypes: false,
      journeyCategories: false,
      targetgroups: false,
      journeyIntervals: this.journeyIntervals,
      existingJournies: false,
      selectedJourney: false,
      selectedJourneySettings: false,
      selectedTargetgroup: false,
      fullSelectedTargetgroup: false,
      journey: {},
      journeyName: false,
      retargetingOptions: false,
      started: false,
      saved: false,
      conversion: this.conversion
    };
    this.baseState = this.state;
  };

  renewJourney = () => {
    this.setState(this.baseState, () => {
      this.gatherData();
      journeyTypeName = '';
    });
  }

  startStopJourney = (bool) => {
    let that = this;
    if (this.state.saved) {
      this.setState({
        started: !this.state.started
      }, () => saveStartStop(bool));
    } else {
      this.notifySave();
    }

    function saveStartStop(bool) {

      let start = 'start';
      that.notifyStartText = <FormattedMessage id="Journey.notify.startText" defaultMessage="Journey erfolgreich gestartet" />;
      if (!bool) {
        start = 'stop';
        that.notifyStartText = <FormattedMessage id="Journey.notify.stopText" defaultMessage="Journey erfolgreich gestoppt" />;;
      }

      let id = that.state.selectedJourney.id;

      fetch(apiPath + 'journey/' + id + '/' + start, {
        mode: 'cors',
        dataType: 'json',
        headers: {
          'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data.status === "success") {
          that.notifyStartStop();
        } else {
          that.notifyJourneyError(data.message ? data.message : 'Error');
          console.log(data);
        }
      }).catch(function (error) {
        globalErrorHandler(error);
      });
    }
  }

  getExistingJournies() {
    let that = this;
    fetch(apiPath + 'journies', {
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      data.reverse();
      that.setState({ existingJournies: data });
    }).catch(function (error) {
      globalErrorHandler(error);
    });

  }
  getExisitingTargetgroups() {
    this.targetgroups = [];
    let that = this;
    if (!this.state.targetgroups) {
      fetch(apiPath + 'audiences', {
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function (response) {
        return response.json()
      }).then(function (data) {
        data.sort(function (a, b) {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
        for (let i in data) {
          that.createNewSelectObject(that.targetgroups, 'targetgroup', data[i].id, data[i].name);
        }
        that.setState({ targetgroups: that.targetgroups });
      }).catch(function (error) {
        globalErrorHandler(error);
      });
    }
  }

  getCategories = () => {
    this.categories = [];
    let that = this;
    if (!this.state.journeyCategories) {
      fetch(apiPath + 'categories', {
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function (response) {
        return response.json()
      }).then(function (data) {
        for (let i in data) {
          that.createNewSelectObject(that.categories, 'categorie', data[i].id, data[i].name);
        }
        that.setState({ journeyCategories: that.categories });
      }).catch(function (error) {
        globalErrorHandler(error);
      });
    }
  }

  getJourneyTypes = () => {
    this.types = [];
    let that = this;
    if (!this.state.journeyTypes) {
      fetch(apiPath + 'types', {
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function (response) {
        return response.json()
      }).then(function (data) {
        for (let i in data) {
          that.createNewSelectObject(that.types, 'journeytype', data[i].id, data[i].name);
        }
        that.setState({ journeyTypes: that.types });
      }).catch(function (error) {
        globalErrorHandler(error);
      });
    }
  }

  createNewSelectObject(object, type, value, label) {
    let add = { type: type, value: value, label: label };
    object.push(add);
  }

  createJourney = (kind, value) => {
    this.journey[kind] = value;
    this.setState({ journey: this.journey });
    if (kind === 'targetgroup') {
      this.getCorrespondingTargetgroup(value, true);
    }
  }

  setJourneyName = (name) => {
    this.setState({ journeyName: name });
  }

  saveJourney = () => {
    if (this.state.saved) {
      this.notifySaved();
      return false;
    }

    let hourInput = document.getElementsByClassName('react-datetime-picker__button__input__input react-datetime-picker__button__input__hour')[0];
    let minuteInput = document.getElementsByClassName('react-datetime-picker__button__input__input react-datetime-picker__button__input__minute')[0];
    let error = 0;

    if (!this.state.journey.journeytype) {
      this.notifyJourneyType();
      error++;
    }
    if (!this.state.journey.targetgroup) {
      this.notifyTargetgroup();
      error++;
    }
    if (!this.state.journey.interval) {
      this.notifyIntervall();
      error++;
    }
    if (!this.state.journeyName) {
      this.notifyJourneyName();
      error++;
    } else {
      for (let i in this.state.existingJournies) {
        if (this.state.existingJournies[i].name === this.state.journeyName) {
          this.notifyJourneyNameInUse();
          error++;
        }
      }
    }
    if (hourInput && minuteInput) {
      if (!hourInput.checkValidity() || !minuteInput.checkValidity()) {
        this.notifyTime();
        error++;
      }
    }
    if (!this.state.journey.channels) {
      notifyChannel();
      error++;
    } else {
      if (
        !this.state.journey.channels[0].template_id &&
        !this.state.journey.channels[0].template &&
        !this.state.journey.channels[0].custom_template &&
        this.state.journey.channels[0].channel !== 'facebook'
      ) {
        notifyTemplate();
        error++;
      }
    }
    let retargetingChannel = false;
    for (let i in this.state.journey.channels) {
      if (this.state.journey.channels[i].channel === 'retargetingbanner') {
        retargetingChannel = true;
        if (!this.state.journey.channels[i].budget) {
          this.notifyRetargetingBudget();
          error++;
        } else {
          let that = this;
          getBannerBudget().then(function (value) {
            let setValue = that.state.journey.channels[i].budget;
            if (setValue > value) {
              error++;
              that.notfiyRetargetingBudgetTooLess();
            }
            if (!that.state.journey.channels[i].days) {
              that.notifyRetargetingDays();
              error++;
            }
            that.createJourneySendObject(error);
          });
        }
      }
    }
    if (!retargetingChannel) {
      this.createJourneySendObject(error);
    }
  }

  createJourneySendObject = (error) => {
    if (error === 0) {
      let currentJourney = this.state.journey;
      let currentChannels = this.state.journey.channels;
      let currentDecisions = this.state.journey.decisions;
      let currentJourneyName = this.state.journeyName;

      let d = new Date(this.state.journey.startdate);
      let startdate = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length === 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length === 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length === 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((parseInt(d.getMinutes() / 5, 10) * 5).toString().length === 2 ? (parseInt(d.getMinutes() / 5, 10) * 5).toString() : "0" + (parseInt(d.getMinutes() / 5, 10) * 5).toString()) + ":00";

      let journeySendObject = {
        "name": currentJourneyName,
        "type_id": currentJourney.journeytype,
        "category_id": currentJourney.category_id,
        "target_group_id": currentJourney.targetgroup,
        "client_id": 1,
        "start": startdate,
        "interval": currentJourney.interval,
        "from_address": currentJourney['from-address'] ? currentJourney['from-address'][1] : false,
        "from_name": currentJourney['from-address'] ? currentJourney['from-address'][0] : false,
        "conversion": currentJourney.conversion === 'on' ? 'on' : 'off',
        "conversion_days": currentJourney.conversion === 'on' && currentJourney.conversion_days ? currentJourney.conversion_days : false,
        "steps": []
      };

      let channelSendObject = [];

      for (let i in currentChannels) {
        channelSendObject[i] = currentChannels[i]; // give the current channels to the journey object [steps]
        if (currentDecisions[i]) {
          if (currentDecisions[i].delay) {
            currentDecisions[i].delay = currentDecisions[i].delay * 60;
          }
          if (currentDecisions[i].delay_option === 'days') { // change days to hours
            currentDecisions[i].delay = currentDecisions[i].delay * 24;
          }
          // give the current decisions to the channel and add it to the journey object [steps]
          channelSendObject[i] = Object.assign(currentDecisions[i], channelSendObject[i])
        }
        journeySendObject.steps = channelSendObject;
      }
      this.sendJourneyObjectToApi(journeySendObject);
    }
  }

  sendJourneyObjectToApi = (journeyObject) => {
    let that = this;
    let data = JSON.stringify(journeyObject);
    fetch(apiPath + 'journey', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: data
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      that.setState({
        saved: true
      }, () => {
        toast(that.notifySuccessfulSave, {
          className: 'journey-notify',
          autoClose: 3000,
          progressClassName: 'journey-notify-progressbar'
        });
        that.getExistingJournies();
        that.recreateJourney(data);
      });
    }).catch(function (error) {
      that.notifyJourneyError(error.message ? error.message : 'Error');
      globalErrorHandler(error);
    });

  }

  recreateJourney = (journey) => {

    this.renewJourney();
    if (this.state.journeyMenu) {
      this.moveSideMenu();
    }

    let that = this
    fetch(apiPath + 'journey/' + journey.id, {
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      that.getCorrespondingTargetgroup(data.target_group_id);

      let started = false;
      if (data.status === 'on') {
        started = true;
      }
      journeyTypeName = data.name;
      that.setState({
        selectedJourney: data,
        saved: true,
        started: started,
      }, () => { that.constructSelectedJourney(); });
    }).catch(function (error) {
      globalErrorHandler(error);
    });
  }

  saveRetargeting = (options) => {
    this.setState({ retargetingOptions: options });
  }

  constructSelectedJourney = () => {
    let selectedJourneySettings = {
      name: this.state.selectedJourney.name,
      type: '',
      category: ''
    };

    if (
      this.state.journeyTypes &&
      this.state.journeyCategories &&
      this.state.selectedTargetgroup
    ) {
      for (let i in this.state.journeyTypes) {
        if (this.state.journeyTypes[i].value === this.state.selectedJourney.type_id) {
          selectedJourneySettings.type = this.state.journeyTypes[i].label;
        }
      }
      for (let i in this.state.journeyCategories) {
        if (this.state.journeyCategories[i].value === this.state.selectedJourney.category_id) {
          selectedJourneySettings.category = this.state.journeyCategories[i].label;
        }
      }
      this.setState({
        selectedJourneySettings: selectedJourneySettings
      });
    } else {
      let that = this;
      setTimeout(function () { that.constructSelectedJourney() }, 100);
    }
  }

  loadingCount = (bool) => { // show/hide loading-droid while getting the count
    let personCount = document.querySelector('.startbar-wrapper .person-count');
    let loadingDroid = document.querySelector('.startbar-wrapper .little-loading-droid');
    if (bool) {
      if (personCount) {
        if (personCount.style) {
          personCount.style.display = 'none';
        }
      }
      if (loadingDroid) {
        if (loadingDroid.style) {
          loadingDroid.style.display = 'block';
        }
      }
    } else {
      if (personCount) {
        if (personCount.style) {
          personCount.style.display = 'block';
        }
      }
      if (loadingDroid) {
        if (loadingDroid.style) {
          loadingDroid.style.display = 'none';
        }
      }
    }
  }

  getCorrespondingTargetgroup = (targetgroupId, save) => {
    let that = this;
    this.loadingCount(true);
    fetch(apiPath + 'audience/' + targetgroupId, {
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      if (!save) {
        that.setState({
          selectedTargetgroup: data.name,
          targetgroup: data
        });
      } else {
        that.setState({ targetgroup: data });
      }
      if (data.realtime_count) {
        that.changePersonCount(data.realtime_count);
      } else {
        that.changePersonCount(data.lastCount);
      }
    }).catch(function (error) {
      globalErrorHandler(error);
      that.loadingCount(false);
    });
  }

  changePersonCount = (count) => {
    this.setState({
      personCount: count
    }, () => this.loadingCount(false));
  }

  moveSideMenu = () => {
    this.setState({ journeyMenu: !this.state.journeyMenu });
  }

  gatherData = () => {
    this.getExistingJournies();
    this.getExisitingTargetgroups();
    this.getCategories();
    this.getJourneyTypes();
  }

  deleteJourney = (id) => {
    let that = this;
    fetch(apiPath + 'journey/delete/' + id, {
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      if (data.status === 'success') {
        that.notifyDelete();
        that.renewJourney();
      }
    }).catch(function (error) {
      // globalErrorHandler(error);
      console.log(error);
    });
  }

  componentDidMount = () => {
    this.renewJourney();
    let color = '#126363';
    changeThemeColor(color);
  }

  render() {
    return (
      <div className="journey droid-module">
        <ToastContainer />
        <JourneyHeader
          type={this.state.journeyType}
          moveSideMenu={this.moveSideMenu}
        />
        <Menu
          showMenu={this.state.journeyMenu}
          moveMenu={this.moveSideMenu}
          moduleName="journey"
          existingJournies={this.state.existingJournies}
          recreateJourney={this.recreateJourney}
          renewJourney={this.renewJourney}
        />
        <div className="row journey-lower-part">
          {(this.state.targetgroups && this.state.journeyCategories && this.state.journeyTypes)
            || (this.state.selectedJourney && this.state.selectedJourneySettings) ?
            <div className="col-12">
              <JourneyStartBar
                personCount={this.state.personCount}
                saveJourney={this.saveJourney}
                deleteJourney={this.deleteJourney}
                started={this.state.started}
                startStopJourney={this.startStopJourney}
                saved={this.state.saved}
                journeyId={this.state.selectedJourney.id}
              />
              <div className="journey-main">
                <div className="row">
                  <div className="col-xl-4 offset-xl-2 col-lg-6 col-sm-12">
                    <JourneySettings
                      selectedJourney={this.state.selectedJourney}
                      selectedJourneySettings={this.state.selectedJourneySettings}
                      selectedTargetgroup={this.state.selectedTargetgroup}
                      setJourneyName={this.setJourneyName}
                      journeyTypes={this.state.journeyTypes}
                      journeyCategories={this.state.journeyCategories}
                      targetgroups={this.state.targetgroups}
                      journeyIntervals={this.state.journeyIntervals}
                      createJourney={this.createJourney}
                      conversion={this.state.conversion}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-6 col-sm-12">
                    <JourneyBuilder
                      createJourney={this.createJourney}
                      selectedJourney={this.state.selectedJourney}
                      personCount={this.state.personCount}
                      selectedTargetgroup={this.state.targetgroup}
                      saveRetargeting={this.saveRetargeting}
                    />
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="text-center" style={{ width: '100%', paddingTop: '50px', fontSize: '1.2em' }}>
              <span>
                <FormattedMessage
                  id="Journey.loadingModuleText"
                  defaultMessage="Journey Modul wird geladen"
                />
              </span>
            </div>
          }
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Journey;