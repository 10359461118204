import localeDE from './locales/de.json';
import localeEN from './locales/en.json';

export const apiPath = process.env.REACT_APP_API_URL;
export let isLocalhost = process.env.NODE_ENV === 'development' ? true : false;

export let language;
if (!localStorage.getItem('lang')) {
    language = //const
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
    localStorage.setItem('lang', language);
} else {
    language = localStorage.getItem('lang');
}

export let messages = localeDE;
if (language.includes('en')) {
    messages = localeEN;
}

export function changeLanguage(lang) {
    localStorage.setItem('lang', lang);
    location.reload();
}

export function checkToken() {
    let tokenSetTime = localStorage.getItem('ttl');
    let ttl = Date.now() - Number(tokenSetTime);
    ttl = Math.abs(ttl);
    if (ttl < 120000) {
        fetch(apiPath + 'auth/refresh', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "cache-control": "no-cache",
            }
        }).then(function(response) {
            if (response.status === 401) {
                return false;
            } else {
                return response.json();
            }
        }).then(function(data) {
            localStorage.setItem('token', data.access_token);
            // let oneMin = 60000;
            const tenMin = 600000;
            const thirtyMin = tenMin * 3;
            localStorage.setItem('ttl', (Date.now() + thirtyMin));
            location.reload();
        }).catch(function(error) {
            console.log(error);
        });
    }
}

export function getKeys(arr) {
    arr = Object.keys(arr);
    let newArr = [];
    for (let m = 0; m < arr.length; m++) {
        newArr.push(m);
    }
    return newArr;
};

export function removeArrayValue(arr) {
    let what, a = arguments,
        L = a.length,
        ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function addZero(i) {
    if (i < 10) {
        i = '0' + i;
    }
    return i;
}

export function sortByDate(a, b) {
    var aDate = a.d.toLowerCase();
    var bDate = b.d.toLowerCase();
    return ((aDate > bDate) ? -1 : ((aDate < bDate) ? 1 : 0));
}

export function decimalAdjust(type, value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

export function numberWithPoints(x) {
    Math.round10 = function(value, exp) {
        return decimalAdjust('round', value, exp);
    };
    Math.floor10 = function(value, exp) {
        return decimalAdjust('floor', value, exp);
    };

    return x.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // if (x > 999999) {
    //   x = Math.floor10((Math.round(x / 1000) / 1000), -1).toString().replace('.', ',') + 'M';
    //   return x;
    // } else {
    //   return x.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // }
}

export function numberWithPointsOnly(x) {
    return x.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function numberWithComma(x) {
    return x.toString().replace('.', ',');
}

export function getUrlParameters(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}

export function convertDate(string) {
    if (string) {
        var tempArray = string.split('-');
        return tempArray[2] + '-' + tempArray[1] + '-' + tempArray[0];
    }
}

export function changeThemeColor(color) {
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", color);
}

export function getLabelsAndData(object) {

    let interLabels = [];
    let labels = [];
    let data = [];

    if (typeof object === 'object') {
        interLabels = Object.keys(object)
        for (let i in interLabels) {
            labels.push(interLabels[i].replace(/-/gm, '.'));
        }
        data = Object.values(object);
    }

    let returnObject = {
        labels,
        data
    };

    return returnObject;
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export const colorArray = [
    'rgb(205,242,197)',
    'rgb(239,172,173)',
    'rgb(197,205,242)',
    'rgb(234,197,242)',
    'rgb(252,221,157)',
    'rgb(242,211,197)',
    'rgb(197,229,242)',
    'rgb(108,227,225)',
    'rgb(229,242,197)'
];

export const newColorArray = ['rgb(253,65,27)', 'rgb(237,227,117)', 'rgb(88,44,153)', 'rgb(154,120,204)', 'rgb(212,168,212)'];

// new Grafik Colors:
// export const red = 'rgb(175,38,10)';
// export const redish = 'rgb(253,65,27)';
// export const redpale = 'rgb(250,130,76)';

// export const lightyellow = 'rgb(237,227,117)';
// export const yellow = 'rgb(186,174,36)';
// export const darkyellow = 'rgb(109,102,10)';

// export const darkviolette = 'rgb(39,14,76)';
// export const violette = 'rgb(88,44,153)';
// export const lightviolette = 'rgb(154,120,204)';

// export const pink = 'rgb(212,168,212)';

// Old Colors:
export const green = 'rgb(205,242,197)';
export const red = 'rgb(239,172,173)';
export const violette = 'rgb(197,205,242)';
export const pink = 'rgb(234,197,242)';
export const yellow = 'rgb(252,221,157)';
export const redish = 'rgb(232, 183, 183)';

export const selectTheme = (theme) => ({ // Theme for <Selects>
    ...theme,
    borderRadius: 3,
    colors: {
        ...theme.colors,
        text: 'black',
        primary25: 'lightgray',
        primary: 'lightgray',
    },
});

export function getPercent(object) {
    let total = object['total'];

    for (let i in object) {
        if (i === 'total') delete object[i];
        let value = object[i];
        let percent = decimalAdjust('floor', (value / total) * 100, -1);
        if (!Number.isNaN(percent)) {
            object[i] = percent;
        }
    }
    return object;
}

// https://gist.github.com/nicbell/6081098
Object.compareMod = function(obj1, obj2) {
    //Loop through properties in object 1
    for (var p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof(obj1[p])) {
            //Deep compare objects
            case 'object':
                if (!Object.compareMod(obj1[p], obj2[p])) return false;
                break;
                //Compare function code
            case 'function':
                if (typeof(obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
                //Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }
    return true;
};

export function capitalizeFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function globalErrorHandler(error) {
    console.log(error);
}

export function removeTokenSession() {
    localStorage.removeItem('token');
}

export function seconds_with_leading_zeros(dt) {
    return (dt.getSeconds() < 10 ? '0' : '') + dt.getSeconds();
}

export function getTimestamp(dt) {
    let hours = (dt.getHours() < 10 ? '0' : '') + dt.getHours();
    let minutes = (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
    let seconds = (dt.getSeconds() < 10 ? '0' : '') + dt.getSeconds();

    return hours + ':' + minutes + ':' + seconds;
}

export function dateFromDatettime(datettime) {
    let index = datettime.indexOf(' ');
    let date = datettime.slice(0, index);
    return date;
}

export function checkArray(foo, element) {
    let error = 0;
    for (let i in foo) {
        if (foo[i] === element) {
            error++;
        }
    }
    if (error > 0) {
        return false;
    } else {
        return true;
    }
}

export function getElementWidth(el) {
    var width = el.offsetWidth;
    var style = getComputedStyle(el);
    var margins = parseInt(style.marginLeft.replace('px', '')) + parseInt(style.marginRight.replace('px', ''));
    var paddings = parseInt(style.paddingLeft.replace('px', '')) + parseInt(style.paddingRight.replace('px', ''));
    width = width - paddings;
    return width;
};

export function getElementHeight(el) {
    var height = el.offsetHeight;
    var style = getComputedStyle(el);
    var margins = parseInt(style.marginTop.replace('px', '')) + parseInt(style.marginBottom.replace('px', ''));
    var paddings = parseInt(style.paddingTop.replace('px', '')) + parseInt(style.paddingBottom.replace('px', ''));
    height = height - paddings;
    return height;
};

/* Polyfills */
if (!Object.entries) {
    Object.entries = function(obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}