import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import Particles from 'react-particles-js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { apiPath } from '../../reusableFunctions';

import './Login.css';

import SVGLogo from '../Main/pictures/logo_login.svg';

import TermsModal from './TermsOfUse';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: false,
      password: false,
      token: false,
      apiPath: apiPath,
      validatedU: true,
      validatedP: true,
      terms: false
    };
    this.checkToken();
  };

  handleChange = (kind, e) => {
    if (this.state.validatedP !== true || this.state.validatedU !== true) {
      this.setState({
        validatedU: true,
        validatedP: true
      })
    }
    if (kind === 'u') { this.setState({ username: e.value }); }
    if (kind === 'p') { this.setState({ password: e.value }); }
  }

  checkToken = () => {
    let currentToken = localStorage.getItem('token');
    if (currentToken) { this.props.passToken(currentToken); }
  }

  setToken = (token) => {
    localStorage.setItem('token', token);
    const tenMin = 600000;
    const thirtyMin = tenMin * 3;
    const oneMin = 60000;
    localStorage.setItem('ttl', (Date.now() + thirtyMin));
    this.setState({
      token: token
    }, () => this.props.passToken(this.state.token));
  }

  getToken = (email, password, terms = false) => {
    let that = this;
    let data = false;
    if (terms) {
      data = JSON.stringify({
        "email": email,
        "password": password,
        "terms_of_use": true
      });
    } else {
      data = JSON.stringify({
        "email": email,
        "password": password
      });
    }
    localStorage.removeItem('token');
    localStorage.removeItem('ttl');
    fetch(this.state.apiPath + 'auth/login', {
      method: 'POST',
      dataType: 'json',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      localStorage.removeItem('client_id');
      if (!data.error) {
        that.setToken(data.access_token);
      } else {
        if (data.error.includes('Terms of Use')) {
          if (that.state.terms) {
            that.setState({ terms: false }, () => {
              that.setState({ terms: true });
            });
          } else {
            that.setState({ terms: true });
          }
        }
        that.notifyLogin(data.error);
      }
    }).catch(function (error) {
      console.log(error);
      that.notifyLogin(error);
    });
  }

  acceptTerms = () => {
    this.getToken(this.state.username, this.state.password, true);
  }

  getVerified = () => {
    if (this.state.username === '' || this.state.username === 'E-Mail' || !this.state.username) {
      this.setState({
        validatedU: false
      });
      return false;
    }
    if (this.state.password === '' || this.state.password === 'Password' || !this.state.password) {
      this.setState({
        validatedP: false
      });
      return false;
    }
    return true;
  }

  handleRequest = (e) => {
    let verified = this.getVerified();
    if (verified) {
      this.getToken(this.state.username, this.state.password);
    }
    e.preventDefault();
  }

  shouldComponentUpdate(nextProps, nextState) { // needed so particlesjs doesnt rerender all the time
    if (this.state.validatedU !== nextState.validatedU || this.state.validatedP !== nextState.validatedP) {
      return true;
    } else if (this.state.terms !== nextState.terms) {
      return true;
    }
    return !(nextProps === this.props);
  }

  notifyLogin = (error) => toast(error ? error : <FormattedMessage
    id="Login.error"
    defaultMessage="Falscher Username oder Passwort."
    values={{ error: error }}
  />,
    {
      className: 'login-notify',
      autoClose: 3000,
      progressClassName: 'login-notify-progressbar'
    });

  render() {
    return (
      <div>
        <DroidParticles />
        <ToastContainer />
        {this.state.terms ? <TermsModal acceptTerms={this.acceptTerms} /> : null}
        <div className="login droid-module">
          <div className="container">
            <div className="droid-login-form-wrapper">
              <div className="droid-login-logo">
                <img src={SVGLogo} alt="droid-logo" /><br />
                <div className="login-logo-text-wrapper">
                  <span className="bold">DROID</span><span className="thin">MARKETING</span>
                </div>
              </div>
              <form className="droid-login-form">
                <label><i className=""></i></label>
                <input
                  className={this.state.validatedU ? 'correct' : 'notcorrect'}
                  type="text"
                  id="droid-login-form-username"
                  placeholder="E-Mail"
                  onChange={(event) => this.handleChange('u', event.target)}
                ></input><br />
                <input
                  className={this.state.validatedP ? 'correct' : 'notcorrect'}
                  type="password"
                  id="droid-login-form-password"
                  placeholder="Password"
                  onChange={(event) => this.handleChange('p', event.target)}
                ></input><br />
                <button className="droid-btn-login" onClick={(e) => this.handleRequest(e)}>
                  <FormattedMessage
                    id="Login.loginButtonText"
                    defaultMessage="Login"
                  />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Login;

class DroidParticles extends Component {
  render() {
    const particlesParams = {
      "particles": {
        "number": {
          "value": 25,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#ffffff"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "img/github.svg",
            "width": 100,
            "height": 100
          }
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 3,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 6,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "bounce": false,
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 200,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    }
    return (
      <Particles
        params={particlesParams}
        style={{
          width: '100%',
          backgroundColor: 'transparent',
          position: 'absolute',
          zIndex: 1
        }}
      />
    )
  }
}