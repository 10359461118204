
import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import Select from 'react-select';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';

import moment from 'moment';
import 'moment/locale/de-at';

import Charts from './Charts';
import { UserSearch } from '../UserSearch/UserSearch';
import { changeThemeColor, messages, selectTheme } from '../../reusableFunctions';
import { Menu } from '../Main/Menu';
import InfoTextsModal from '../Main/InfoTextsModal';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusSquare, faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

import './Data.css';

library.add(faPlusSquare, faDesktop, faMobileAlt);

class DataHeaderBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      resetSelect: false
    };
  };

  changeDatesFromProps = () => {
    if (this.props.dateFrom && this.props.dateTo) {

      let dateFromProps = moment(this.props.dateFrom);
      let dateToProps = moment(this.props.dateTo);

      if (this.state.startDate === null) {
        this.setState({
          startDate: dateFromProps,
          endDate: dateToProps
        });
      }
    }
  }

  componentDidUpdate = () => { this.changeDatesFromProps(); }


  afterStateChange = () => {
    let { startDate, endDate, focusedInput } = this.state;

    if (!focusedInput && this.valuesDirty) {
      this.valuesDirty = false;
      this.props.onChange && this.props.onChange({ startDate: startDate, endDate: endDate });
      this.props.changeDate(this.state.startDate, this.state.endDate);
      this.changeTimespan('reset');
    }
  };

  handleDatesChange = ({ startDate, endDate }) => {
    if (startDate && !startDate.isSame(this.state.startDate)) {
      this.valuesDirty = true;
    }
    if (endDate && !endDate.isSame(this.state.endDate)) {
      this.valuesDirty = true;
    }

    this.setState({ startDate, endDate }, this.afterStateChange);
  };

  handleFocusChange = focusedInput => {
    this.setState({ focusedInput: focusedInput }, this.afterStateChange);
  };

  timespanSeletion = [
    { type: 'timespan', value: '24', label: messages['Data.timespanpselect.24hours'] },
    { type: 'timespan', value: '48', label: messages['Data.timespanpselect.48hours'] },
    { type: 'timespan', value: '30', label: messages['Data.timespanpselect.30days'] },
    { type: 'timespan', value: '60', label: messages['Data.timespanpselect.60days'] },
    { type: 'timespan', value: '90', label: messages['Data.timespanpselect.90days'] },
    { type: 'timespan', value: '0', label: messages['Data.timespanpselect.revert'] },
  ]

  changeTimespan = (value) => {
    let startDate = false;
    let resetSelect = false;

    switch (value) {
      case '90':
        startDate = moment().subtract(3, 'month');
        break;
      case '60':
        startDate = moment().subtract(2, 'month');
        break;
      case '30':
      case '0':
      default:
        startDate = moment().subtract(1, 'month');
        break;
      case '0':
        startDate = moment().subtract(1, 'month');
        resetSelect = true;
        break;
      case '48':
        startDate = moment().subtract(2, 'day');
        break;
      case '24':
        startDate = moment().subtract(1, 'day');
        break;
      case 'reset':
        this.setState({ resetSelect: true });
        return false;
        break;
    }
    let endDate = moment();

    this.setState({
      startDate: startDate,
      endDate: endDate,
      resetSelect: resetSelect
    }, () => this.props.changeDate(startDate, endDate));
  }

  render() {
    return (
      <div className="data-header-bottom text-right" >
        {/* {(this.props.channel === 'website') ?
          <WebsiteSwitcher />
          :
          ''
        } */}
        <div className="timespan-datepicker">
          {this.state.resetSelect ?
            <Select
              theme={selectTheme}
              classNamePrefix="data-timespan-select"
              options={this.timespanSeletion}
              isSearchable={false}
              value={null}
              placeholder={<FormattedMessage
                id="Data.timespanplaceholder"
                defaultMessage="Zeitraum"
              />}
              onChange={(e) => { this.changeTimespan(e.value) }}
            />
            :
            <Select
              theme={selectTheme}
              classNamePrefix="data-timespan-select"
              options={this.timespanSeletion}
              isSearchable={false}
              placeholder={<FormattedMessage
                id="Data.timespanplaceholder"
                defaultMessage="Zeitraum"
              />}
              onChange={(e) => { this.changeTimespan(e.value) }}
            />
          }
          <span className="time-span-text">
            <FormattedMessage
              id="Data.timespantext"
              defaultMessage="Dargestellter Zeitraum"
            />:</span>
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId="data-bottom-header-startdate"
            endDate={this.state.endDate}
            endDateId="data-bottom-header-enddate"
            onDatesChange={({ startDate, endDate }) => this.handleDatesChange({ startDate, endDate })}
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.handleFocusChange(focusedInput)}
            numberOfMonths={(window.innerWidth < 650) ? 1 : 2}
            monthFormat="MMMM YYYY"
            isOutsideRange={day => (moment().diff(day, 'days') < 0)}
            readOnly={true}
          />
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}


class WebsiteSwitcher extends Component {

  constructor(props) {
    super(props)
    this.state = {
      websites: false,
      returnSlides: false
    }
  }

  createSwiper = () => {
    const websites = ['faie.at', 'faie.de', 'faie.ch', 'richtiges-werkzeug.at'];
    let returnSlides = (websites.map((website, index) =>
      <div key={index} className="single-slide">
        <span>{website}</span>
      </div>
    ));

    if (this.state.websites.length !== websites.length) {
      console.log(this.state.websites);
      console.log(websites);
      this.setState({
        websites: websites,
        returnSlides: returnSlides
      });
    }
  }

  componentWillMount = () => { this.createSwiper(); }

  componentDidUpdate = () => { this.createSwiper(); }

  render() {
    const params = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 0,
      slidesPerView: 1
    }

    return (
      <div className="data-swiper-wrapper">
        <Swiper {...params}>
          {this.state.returnSlides}
        </Swiper>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

const DataHeaderTop = (props) => {
  const getChannelName = props.getChannelName;
  let channel = props.channel;

  let showUserSearch = props.showUserSearch

  const showHideUserSearch = props.showHideUserSearch;
  const moveMenu = props.moveMenu;

  return (
    <div className="data-header">
      <UserSearch
        show={showUserSearch}
        showHideUserSearch={props.showHideUserSearch}
      />
      <div className="row">
        <div className="col-8">
          <span className="data-header-text">{getChannelName(channel)}</span>
        </div>
        <div className="col-4 text-right">
          <InfoTextsModal
            info="Data:website:general"
          />
          <button onClick={showHideUserSearch}
            style={{ background: 'transparent', border: 'none', color: 'white', padding: 0 }}>
            <i className="fas fa-search data-search"></i>
          </button>
          <button onClick={() => moveMenu()}
            style={{ background: 'transparent', border: 'none', color: 'white', padding: 0 }}>
            <i className="fas fa-bars data-menu"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

class Data extends Component {

  constructor(props) {
    super(props);
    this.state = {
      channels: ['website', 'newsletter', 'sms', 'print'],
      channel: '',
      showUserSearch: false,
      showMenu: false,
      dateFrom: null,
      dateTo: null,
    };
  };

  showHideUserSearch = () => {
    this.setState({
      showMenu: false,
      showUserSearch: !this.state.showUserSearch
    });
  }

  moveMenu = () => {
    this.setState({
      showUserSearch: false,
      showMenu: !this.state.showMenu
    });
  }

  getChannelName = (channel) => {
    let channelName = '';
    switch (channel) {
      case 'website':
        channelName = 'Website Tracking';
        break;
      case 'newsletter':
        channelName = 'Newsletter Tracking';
        break;
      case 'sms':
        channelName = 'SMS Tracking';
        break;
      case 'print':
        channelName = 'Direct-2-Print';
        break;
      case 'banner':
        channelName = 'Banner Tracking';
        break;
      case 'crm':
        channelName = 'CRM Tracking';
        break;
      default:
        channelName = 'Data';
    }
    return channelName
  }

  componentDidMount = () => {
    this.checkParams();
    let color = '#1F75D5';
    changeThemeColor(color);
    this.changeDate(moment().subtract(1, 'month'), moment());
  }

  componentDidUpdate = () => { this.checkParams(); }

  checkParams = () => {
    if (Object.keys(this.props.match.params).length > 0) {
      if (this.props.match.params.channel !== this.state.channel) {
        if (this.props.match.params.channel === undefined) {
          if (this.state.channel !== 'website') {
            this.setState({
              channel: 'website',
              showMenu: false
            });
          }
        } else {
          this.setState({
            channel: this.props.match.params.channel,
            showMenu: false
          });
        }
      }
    }
  }

  changeDate = (dateFrom, dateTo) => {
    this.setState({
      dateFrom: dateFrom,
      dateTo: dateTo
    });
  }

  render() {
    return (
      <div className="data droid-module">
        <Menu
          showMenu={this.state.showMenu}
          moveMenu={this.moveMenu}
          moduleName="data"
          channels={this.state.channels}
        />
        <DataHeaderTop
          channel={this.state.channel}
          getChannelName={this.getChannelName}
          showHideUserSearch={this.showHideUserSearch}
          showUserSearch={this.state.showUserSearch}
          moveMenu={this.moveMenu}
        />
        <div className="data-lower-part">
          <DataHeaderBottom
            dateFrom={this.state.dateFrom}
            dateTo={this.state.dateTo}
            changeDate={this.changeDate}
            channel={this.state.channel}
          />
          <Charts
            channel={this.state.channel}
            dateFrom={this.state.dateFrom}
            dateTo={this.state.dateTo}
            categories={this.props.categories}
          />
          <AddModal />
        </div>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class AddModal extends Component {

  openModal = () => {
    confirmAlert({
      title: messages['Data.evaluationmodal.title'],
      message: messages['Data.evaluationmodal.message'],
      buttons: [
        {
          label: messages['Data.evaluationmodal.contact'],
          onClick: () => {
            window.location.href = "mailto:beep@droidmarketing.com?subject=Neue Auswertung hinzufügen";
          }
        },
        {
          label: messages['Data.evaluationmodal.close'],
          onClick: () => { return false; }
        }
      ]
    })
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
          <button className="btn-add-eval shadow" onClick={() => this.openModal()}>
            <span><FormattedMessage
              id="Data.addevaluation"
              defaultMessage="Auswertung hinzufügen"
            /></span>
            <i className="fas fa-plus-square"></i>
          </button>
        </div>
      </div>
    )
  }
}

export default Data;