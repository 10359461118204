import React from 'react';
import ReactDOM from 'react-dom';



import { language, messages } from './reusableFunctions'

/* IE11 Polyfill */
import 'core-js/es6/';
import 'isomorphic-fetch';

/* Top ProgressBar */
import 'nprogress/nprogress.css';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';

addLocaleData([...en, ...de]);

/* Moment package, like modern "new Date()" object */
import moment from 'moment';
import 'moment/locale/de-at';

import { FontAwesomeIcon } from '@fortawesome/fontawesome-free';
import { library } from '@fortawesome/fontawesome-svg-core';

/* Droid start point */
import './index.css';
import './droid_modules/Main/Main.css';
import Droid from './droid_modules/Main/Droid';

import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

moment.locale(language ? language : 'de-at');

function Fragment(props) {
  return props.children || <span {...props} /> || null;
}

if (process.env.NODE_ENV === "production") {

}


if (!window.location.hostname.includes('local')) {

}



ReactDOM.render(
  <IntlProvider locale={language} messages={messages} textComponent={Fragment}>
    <Droid />
  </IntlProvider>,
  document.getElementById('root')
);

unregister();
// registerServiceWorker();