import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import Select from 'react-select';
import TimePicker from 'react-times';
import Checkbox from 'rc-checkbox';

import 'react-times/css/classic/default.css';
import { messages } from '../../reusableFunctions';

class RetargetingBannerSelects extends Component {

  constructor(props) {
    super(props);
    this.state = {
      frequencyCapping: [
        { type: 'capping', disabled: false, value: '1', label: '1' },
        { type: 'capping', disabled: false, value: '2', label: '2' },
        { type: 'capping', disabled: false, value: '3', label: '3' },
        { type: 'capping', disabled: false, value: '4', label: '4' },
        { type: 'capping', disabled: false, value: '5', label: '5' },
        { type: 'capping', disabled: false, value: '6', label: '6' },
        { type: 'capping', disabled: false, value: '7', label: '7' },
        { type: 'capping', disabled: false, value: '8', label: '8' },
        { type: 'capping', disabled: false, value: '9', label: '9' },
        { type: 'capping', disabled: false, value: '10', label: '10' },
        { type: 'capping', disabled: false, value: '11', label: '11' },
        { type: 'capping', disabled: false, value: '12', label: '12' },
        { type: 'capping', disabled: false, value: '13', label: '13' },
        { type: 'capping', disabled: false, value: '14', label: '14' },
        { type: 'capping', disabled: false, value: '15', label: '15' },
      ],
      states: [
        { type: 'state', disabled: false, value: 0, label: messages['Journey.retargetingBannerSelect.states.vienna'] },
        { type: 'state', disabled: false, value: 1, label: messages['Journey.retargetingBannerSelect.states.burgenland'] },
        { type: 'state', disabled: false, value: 2, label: messages['Journey.retargetingBannerSelect.states.carinthia'] },
        { type: 'state', disabled: false, value: 3, label: messages['Journey.retargetingBannerSelect.states.lowerAustria'] },
        { type: 'state', disabled: false, value: 4, label: messages['Journey.retargetingBannerSelect.states.upperAustria'] },
        { type: 'state', disabled: false, value: 5, label: messages['Journey.retargetingBannerSelect.states.salzburg'] },
        { type: 'state', disabled: false, value: 6, label: messages['Journey.retargetingBannerSelect.states.styria'] },
        { type: 'state', disabled: false, value: 7, label: messages['Journey.retargetingBannerSelect.states.tyrol'] },
        { type: 'state', disabled: false, value: 8, label: messages['Journey.retargetingBannerSelect.states.vorarlberg'] },
      ],
      days: [
        { type: 'day', disabled: false, value: 'monday', label: messages['Journey.retargetingBannerSelect.days.monday'] },
        { type: 'day', disabled: false, value: 'tuesday', label: messages['Journey.retargetingBannerSelect.days.tuesday'] },
        { type: 'day', disabled: false, value: 'wednesday', label: messages['Journey.retargetingBannerSelect.days.wednesday'] },
        { type: 'day', disabled: false, value: 'thursday', label: messages['Journey.retargetingBannerSelect.days.thursday'] },
        { type: 'day', disabled: false, value: 'friday', label: messages['Journey.retargetingBannerSelect.days.friday'] },
        { type: 'day', disabled: false, value: 'saturday', label: messages['Journey.retargetingBannerSelect.days.saturday'] },
        { type: 'day', disabled: false, value: 'sunday', label: messages['Journey.retargetingBannerSelect.days.sunday'] },
      ],
      weather: [
        { type: 'weather', disabled: false, value: 'sunny', label: messages['Journey.retargetingBannerSelect.weather.sunny'] },
        { type: 'weather', disabled: false, value: 'cloudy', label: messages['Journey.retargetingBannerSelect.weather.cloudy'] },
        { type: 'weather', disabled: false, value: 'rainy', label: messages['Journey.retargetingBannerSelect.weather.rainy'] },
        { type: 'weather', disabled: false, value: 'stormy', label: messages['Journey.retargetingBannerSelect.weather.stormy'] },
        { type: 'weather', disabled: false, value: 'snow', label: messages['Journey.retargetingBannerSelect.weather.snow'] },
        { type: 'weather', disabled: false, value: 'equal', label: messages['Journey.retargetingBannerSelect.weather.equal'] },
      ],
      hourFrom: false,
      minuteFrom: false,
      hourTo: false,
      minuteTo: false,
      desktop: true,
      mobile: true,
      budget: false,
      daysRunning: false,
      selectedFrequency: false,
      selectedRegional: false,
      selectedDays: false,
      selectedWeather: false,
      retargetingOptions: false,
      selectedRetargetingOptions: false
    };
  };

  componentDidMount = () => {
    if (!this.props.selectedRetargetingOptions) {
      this.collectRetargetingInfo();
    } else {
      this.setState({
        selectedRetargetingOptions: this.props.selectedRetargetingOptions
      });
    }
  }

  getChange = (kind, value) => {
    if (kind === 'frequency') {
      this.setState({
        selectedFrequency: Number(value.value)
      }, () => this.collectRetargetingInfo());
    } else if (kind === 'regional') {
      this.setState({
        selectedRegional: value
      }, () => this.collectRetargetingInfo());
    } else if (kind === 'day') {
      this.setState({
        selectedDays: value
      }, () => this.collectRetargetingInfo());
    } else if (kind === 'weather') {
      this.setState({
        selectedWeather: value.value
      }, () => this.collectRetargetingInfo());
    }
  }

  onTimeChange(kind, options) {
    if (kind === 'from') {
      this.setState({
        hourFrom: options.hour,
        minuteFrom: options.minute
      }, () => this.collectRetargetingInfo());
    } else if (kind === 'to') {
      this.setState({
        hourTo: options.hour,
        minuteTo: options.minute
      }, () => this.collectRetargetingInfo());
    }
  }

  onCheckboxChange = (value) => {
    if (value.target.name === 'desktop') {
      this.setState({
        desktop: value.target.checked
      }, () => this.collectRetargetingInfo());
    } else if (value.target.name === 'mobile') {
      this.setState({
        mobile: value.target.checked
      }, () => this.collectRetargetingInfo());
    }
  }

  onInputChange = (kind, value) => {
    if (kind === 'days') {
      this.setState({
        daysRunning: Number(value)
      }, () => this.collectRetargetingInfo());
    } else if (kind === 'budget') {
      this.setState({
        budget: Number(value)
      }, () => this.collectRetargetingInfo());
    }
  }

  collectRetargetingInfo = () => {
    let retargetingOptions = {};
    // Frequency Capping
    let frequency_capping = this.state.selectedFrequency;
    if (!frequency_capping) {
      frequency_capping = 1;
    }

    // Regional
    let currentStates = this.state.selectedRegional;
    if (!currentStates) {
      currentStates = this.state.states;
    }
    let states = [];
    for (let i in currentStates) {
      states.push(currentStates[i].value);
    }

    // Zeittargeting
    let currentSelected_days = this.state.selectedDays;
    if (!currentSelected_days) {
      currentSelected_days = this.state.days;
    }
    let selected_days = [];
    for (let i in currentSelected_days) {
      selected_days.push(currentSelected_days[i].value);
    }

    let time_from = this.state.hourFrom + ':' + this.state.minuteFrom;
    if (!this.state.hourFrom && !this.state.minuteFrom) {
      time_from = '06:00';
    }
    let time_to = this.state.hourTo + ':' + this.state.minuteTo;
    if (!this.state.hourTo && !this.state.minuteTo) {
      time_to = '22:00';
    }

    let desktop = this.state.desktop;
    let mobile = this.state.mobile;

    let weather = this.state.selectedWeather;
    if (!weather) {
      weather = 'equal';
    }

    let days = this.state.daysRunning;
    let budget = this.state.budget;

    retargetingOptions = {
      frequency_capping: frequency_capping,
      state: states,
      day: selected_days,
      time_from: time_from,
      time_to: time_to,
      mobile: mobile.toString(),
      desktop: desktop.toString(),
      weather: weather,
      days: days,
      budget: budget
    };

    this.setState({
      retargetingOptions: retargetingOptions
    }, () => this.props.getChange('retargetingoptions', this.state.retargetingOptions, this.props.num));
  }

  returnCorrectOptions = (kind, options) => {
    if (kind === 'states') {
      let states = this.state.states;
      let returnStates = [];
      for (let i in states) {
        for (let k in options) {
          if (states[i].value === options[k]) {
            returnStates.push(states[i])
          }
        }
      }
      return returnStates;
    } else if (kind === 'day') {
      let days = this.state.days;
      let returnDays = [];
      for (let i in days) {
        for (let k in options) {
          if (days[i].value === options[k]) {
            returnDays.push(days[i]);
          }
        }
      }
      return returnDays;
    } else if (kind === 'weather') {
      let weather = this.state.weather;
      let returnWeather = [];
      for (let i in weather) {
        if (weather[i].value === options) {
          returnWeather.push(weather[i]);
        }
      }
      return returnWeather;
    }
  }

  componentWillUnmount = () => this.setState({ selectedRetargetingOptions: false });

  render() {
    return (
      <div className="retargeting-select-wrapper">
        <label className="journey-select-label">
          <FormattedMessage
            id="Journey.retargetingBannerSelect.journeySelectLabel.frequencyCapping.header"
            defaultMessage="Frequency Capping pro User"
          />:
          </label>
        {this.state.selectedRetargetingOptions ?
          <div>
            <Select
              id="retargeting-frequency"
              theme={this.props.theme}
              classNamePrefix="journey-channel-select"
              placeholder={this.state.selectedRetargetingOptions.frequency_capping}
              isDisabled
            />
          </div>
          :
          <Select
            id="retargeting-frequency"
            theme={this.props.theme}
            classNamePrefix="journey-channel-select"
            options={this.state.frequencyCapping}
            defaultValue={this.state.frequencyCapping[0]}
            isSearchable={false}
            placeholder={<FormattedMessage id="Journey.retargetingBannerSelect.journeySelectLabel.frequencyCapping.placeholder" defaultMessage="FrequencyCapping" />}
            onChange={(e) => { this.getChange('frequency', e) }}
            isOptionDisabled={(option) => option.disabled}
          />
        }
        <label className="journey-select-label">
          <FormattedMessage
            id="Journey.retargetingBannerSelect.journeySelectLabel.regional"
            defaultMessage="Regional"
          />:
          </label>
        {this.state.selectedRetargetingOptions ?
          <div>
            <Select
              id="retargeting-regional"
              theme={this.props.theme}
              classNamePrefix="journey-channel-select"
              isMulti={true}
              isSearchable={false}
              defaultValue={this.returnCorrectOptions('states', this.state.selectedRetargetingOptions.state)}
              placeholder={[this.state.states[0], this.state.states[1]]}
              isDisabled
            />
          </div>
          :
          <Select
            id="retargeting-regional"
            theme={this.props.theme}
            classNamePrefix="journey-channel-select"
            options={this.state.states}
            defaultValue={[this.state.states[0], this.state.states[1], this.state.states[2], this.state.states[3], this.state.states[4], this.state.states[5], this.state.states[6], this.state.states[7], this.state.states[8]]}
            isSearchable={false}
            isMulti={true}
            placeholder="Region"
            onChange={(e) => { this.getChange('regional', e) }}
            isOptionDisabled={(option) => option.disabled}
          />
        }
        <label className="journey-select-label">
          <FormattedMessage
            id="Journey.retargetingBannerSelect.journeySelectLabel.timetargeting"
            defaultMessage="Zeittargeting"
          />:
          </label>
        {this.state.selectedRetargetingOptions ?
          <div>
            <Select
              id="retargeting-day"
              theme={this.props.theme}
              classNamePrefix="journey-channel-select"
              options={this.state.days}
              defaultValue={this.returnCorrectOptions('day', this.state.selectedRetargetingOptions.day)}
              isSearchable={false}
              isMulti={true}
              isDisabled
            />
          </div>
          :
          <Select
            id="retargeting-day"
            theme={this.props.theme}
            classNamePrefix="journey-channel-select"
            options={this.state.days}
            defaultValue={[this.state.days[0], this.state.days[1], this.state.days[2], this.state.days[3], this.state.days[4], this.state.days[5], this.state.days[6]]}
            isSearchable={false}
            isMulti={true}
            placeholder={<FormattedMessage id="Journey.retargetingBannerSelect.retargetingDay" defaultMessage="Wochentag" />}
            onChange={(e) => { this.getChange('day', e) }}
            isOptionDisabled={(option) => option.disabled}
          />
        }
        <div className="retargeting-time-wrapper">
          <div className="row">
            <div className="col-2">
              <span>
                <FormattedMessage
                  id="Journey.retargetingBannerSelect.retargetingTimeWrapper.from"
                  defaultMessage="Von"
                />:
              </span>
            </div>
            {this.state.selectedRetargetingOptions ?
              <div className="col-10">
                <TimePicker
                  closeOnOutsideClick={false}
                  id="retargeting-time-from"
                  theme="classic"
                  colorPalette="dark"
                  disabled
                  time={this.state.selectedRetargetingOptions.time_from}
                />
              </div>
              :
              <div className="col-10">
                <TimePicker
                  closeOnOutsideClick={false}
                  id="retargeting-time-from"
                  theme="classic"
                  colorPalette="dark"
                  onTimeChange={(e) => this.onTimeChange('from', e)}
                  time={this.state.hourFrom && this.state.minuteFrom ? `${this.state.hourFrom}:${this.state.minuteFrom}` : '06:00'}
                />
              </div>
            }
          </div>
          <div className="row">
            <div className="col-2">
              <span>
                <FormattedMessage
                  id="Journey.retargetingBannerSelect.retargetingTimeWrapper.to"
                  defaultMessage="Bis"
                />:
              </span>
            </div>
            {this.state.selectedRetargetingOptions ?
              <div className="col-10">
                <TimePicker
                  closeOnOutsideClick={false}
                  id="retargeting-time-to"
                  theme="classic"
                  colorPalette="dark"
                  disabled
                  time={this.state.selectedRetargetingOptions.time_to}
                />
              </div>
              :
              <div className="col-10">
                <TimePicker
                  closeOnOutsideClick={false}
                  id="retargeting-time-to"
                  theme="classic"
                  colorPalette="dark"
                  onTimeChange={(e) => this.onTimeChange('to', e)}
                  time={this.state.hourTo && this.state.minuteTo ? `${this.state.hourTo}:${this.state.minuteTo}` : '22:00'}
                />
              </div>
            }
          </div>
        </div>
        <label className="journey-select-label">
          <FormattedMessage
            id="Journey.retargetingBannerSelect.journeySelectLabel.retargetingDevice.header"
            defaultMessage="Endgeräte"
          />:
          </label>
        <div className="retargeting-device-wrapper">
          {!this.state.selectedRetargetingOptions ?
            <label>
              <Checkbox
                name="desktop"
                defaultChecked
                onChange={(e) => this.onCheckboxChange(e)}
              />
              &nbsp; Desktop
            </label>
            :
            <div>
              {Boolean(this.state.selectedRetargetingOptions.desktop) ?
                <label>
                  <Checkbox
                    name="desktop"
                    checked={1}
                  />
                  &nbsp; <FormattedMessage id="Journey.retargetingBannerSelect.journeySelectLabel.retargetingDevice.desktop" defaultMessage="Desktop" />
                </label>
                :
                <label>
                  <Checkbox
                    name="desktop"
                    checked={0}
                  />
                  &nbsp; Desktop
                </label>
              }
            </div>
          }
          {!this.state.selectedRetargetingOptions ?
            <label>
              <Checkbox
                name="mobile"
                defaultChecked
                onChange={(e) => this.onCheckboxChange(e)}
              />
              &nbsp; <FormattedMessage id="Journey.retargetingBannerSelect.journeySelectLabel.retargetingDevice.mobile" defaultMessage="Mobile" />
            </label>
            :
            <div>
              {Boolean(this.state.selectedRetargetingOptions.mobile) ?
                <label>
                  <Checkbox
                    name="mobile"
                    checked={1}
                  />
                  &nbsp; Mobile
                </label>
                :
                <label>
                  <Checkbox
                    name="mobile"
                    checked={0}
                  />
                  &nbsp; <FormattedMessage id="Journey.retargetingBannerSelect.journeySelectLabel.retargetingDevice.mobile" defaultMessage="Mobile" />
                </label>
              }
            </div>
          }
        </div>
        <label className="journey-select-label">
          <FormattedMessage
            id="Journey.retargetingBannerSelect.journeySelectLabel.retargetingWeather.header"
            defaultMessage="Wetter am Standort des Endgerätes"
          />:
          </label>
        {this.state.selectedRetargetingOptions ?
          <div>
            <Select
              id="retargeting-weather"
              theme={this.props.theme}
              classNamePrefix="journey-channel-select"
              isDisabled
              defaultValue={this.returnCorrectOptions('weather', this.state.selectedRetargetingOptions.weather)}
            />
          </div>
          :
          <Select
            id="retargeting-weather"
            theme={this.props.theme}
            classNamePrefix="journey-channel-select"
            options={this.state.weather}
            defaultValue={this.state.weather[5]}
            isSearchable={false}
            placeholder={<FormattedMessage id="Journey.retargetingBannerSelect.journeySelectLabel.retargetingWeather.placeholder" defaultMessage="Wetter" />}
            onChange={(e) => { this.getChange('weather', e) }}
            isOptionDisabled={(option) => option.disabled}
          />
        }
        <div className="retargeting-duration-budget-wrapper row">
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <label>
                  <FormattedMessage
                    id="Journey.retargetingBannerSelect.journeySelectLabel.budget"
                    defaultMessage="Budget"
                  />:
                </label>
              </div>
              <div className="col-6">
                {this.state.selectedRetargetingOptions ?
                  <input type="number" min="0" disabled placeholder={this.props.selectedRetargetingOptions.budget}></input>
                  :
                  <input type="number" min="0" onChange={(e) => this.onInputChange('budget', e.target.value)} placeholder="0"></input>
                }
              </div>
              <div className="col-2">
                <label>€</label>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label>
                  <FormattedMessage
                    id="Journey.retargetingBannerSelect.journeySelectLabel.numberDays"
                    defaultMessage="Anzahl der Tage"
                  />:
                </label>
              </div>
              <div className="col-6">
                {this.state.selectedRetargetingOptions ?
                  <input type="number" min="0" disabled placeholder={this.state.selectedRetargetingOptions.days}></input>
                  :
                  <input type="number" min="0" onChange={(e) => this.onInputChange('days', e.target.value)} placeholder="0"></input>
                }
              </div>
              <div className="col-2">
                <label>
                  <FormattedMessage
                    id="Journey.retargetingBannerSelect.journeySelectLabel.days"
                    defaultMessage="Tage"
                  />
                </label>
              </div>
            </div>
          </div>
        </div >
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default RetargetingBannerSelects;