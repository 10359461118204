import React, { Component } from 'react';
import './Data.css';


import { FormattedMessage } from 'react-intl';

import WebsiteCharts from './Charts/Website';
import NewsletterCharts from './Charts/Newsletter';
import PrintCharts from './Charts/Print'
import SMSCharts from './Charts/Sms';
import BannerCharts from './Charts/Banner';
import CRMCharts from './Charts/CRM';

import { getDataLineChartSet } from './Charts/ChartOptions';

import ReactCardFlip from 'react-card-flip';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faRetweet } from '@fortawesome/free-solid-svg-icons'; // Import Icons from FontAwesome

library.add(faRetweet);

export function createChartsDatasetsLine(labels, label, color, data, object) {
  let currentDataset = getDataLineChartSet();
  currentDataset.data = data;
  currentDataset.label = label;
  currentDataset.borderColor = color;
  currentDataset.pointHoverBackgroundColor = color;
  object.datasets.push(currentDataset);
  object.labels = labels;

  return object;
}

export function createChartsDatasetsPie(label, color, data, object) {
  let fullData = 0;

  for (let i in data) {
    fullData += data[i];
  }

  object.labels.push(label);
  object.datasets[0].data.push(fullData);
  object.datasets[0].backgroundColor.push(color);
  object.datasets[0].hoverBackgroundColor.push(color);

  return object;
}

export function createChartsDatasetsProgressBar(label, color, data, object) {

  let fullData = 0;

  for (let i in data) {
    fullData += data[i];
  }

  object.labels.push(label);
  object.datasets[0].data.push(fullData);
  object.datasets[0].backgroundColor.push(color);
  object.datasets[0].hoverBackgroundColor.push(color);

  return object;
}

export function createChartsDatasetsProgressCircle(values, label, object) {
  let fullData = 0;
  for (let i in values) {
    fullData += values[i];
  }

  object.values.push(fullData);
  object.labels.push(label);
  return object;
}

export class SingleChartWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick = () => {
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    return (
      <div>
        {(window.innerWidth <= 768) ?
          <div className="row data-charts-single" >
            <ReactCardFlip isFlipped={this.state.isFlipped}>
              <div
                key="front"
                className={"right-chart col-sm-12 col-md-5 col-lg-4 col-xl-3 " + this.state.isFlipped}
                onDoubleClick={() => this.handleClick()}>
                <i className="fas fa-retweet" onClick={() => this.handleClick()} style={{ position: 'absolute', margin: '15px', right: '10px' }}></i>
                {this.props.rightChart}
              </div>
              <div
                key="back"
                className={"left-chart col-sm-12 col-md-7 col-lg-8 col-xl-9 " + this.state.isFlipped}
                onDoubleClick={() => this.handleClick()}>
                <i className="fas fa-retweet" onClick={() => this.handleClick()} style={{ position: 'absolute', margin: '15px', zIndex: 1, right: '10px' }}></i>
                {this.props.lineChart}
              </div>
            </ReactCardFlip>
          </div>
          :
          <div className="row data-charts-single" >
            <div className="right-chart col-sm-12 col-md-5 col-lg-4 col-xl-3">
              {this.props.rightChart}
            </div>
            <div className="left-chart col-sm-12 col-md-7 col-lg-8 col-xl-9">
              {this.props.lineChart}
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export class SingleChartWrapperReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick = () => {
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    return (
      <div>
        {(window.innerWidth <= 768) ?
          <div className="row data-charts-single" >
            <ReactCardFlip isFlipped={this.state.isFlipped}>
              <div
                key="front"
                className={"right-chart col-sm-12 col-md-6 col-lg-5 col-xl-4 " + this.state.isFlipped}
                onDoubleClick={() => this.handleClick()}>
                {this.props.rightChart}
              </div>
              <div
                key="back"
                className={"left-chart col-sm-12 col-md-6 col-lg-7 col-xl-8 " + this.state.isFlipped}
                onDoubleClick={() => this.handleClick()}>
                {this.props.lineChart}
              </div>
            </ReactCardFlip>
          </div>
          :
          <div className="row data-charts-single" >
            <div className="right-chart col-sm-12 col-md-6 col-lg-5 col-xl-4">
              {this.props.rightChart}
              {this.props.conversionDownload ? this.props.conversionDownload : null}
            </div>
            <div className="left-chart col-sm-12 col-md-6 col-lg-7 col-xl-8">
              {this.props.lineChart}
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class Charts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      returnChart: false,
      channel: false,
      dateFrom: null,
      dateTo: null
    };
  };

  returnCorrectChart = () => {
    let returnChart = <div>
      <FormattedMessage id="Data.error.charts" defaultMessage="Probleme mit den Charts" />
    </div>;
    switch (this.state.channel) {
      case 'website':
        returnChart = <WebsiteCharts
          dateFrom={this.state.dateFrom}
          dateTo={this.state.dateTo}
          categories={this.props.categories}
        />
        break;
      case 'newsletter':
        returnChart = <NewsletterCharts
          dateFrom={this.props.dateFrom}
          dateTo={this.props.dateTo}
          categories={this.props.categories}
        />
        break;
      case 'print':
        returnChart = <PrintCharts
          dateFrom={this.props.dateFrom}
          dateTo={this.props.dateTo}
          categories={this.props.categories}
        />
        break;
      case 'sms':
        returnChart = <SMSCharts
          dateFrom={this.props.dateFrom}
          dateTo={this.props.dateTo}
          categories={this.props.categories}
        />
        break;
      case 'banner':
        returnChart = <BannerCharts
          dateFrom={this.props.dateFrom}
          dateTo={this.props.dateTo}
          categories={this.props.categories}
        />
        break;
      case 'crm':
        returnChart = <CRMCharts
          dateFrom={this.props.dateFrom}
          dateTo={this.props.dateTo}
          categories={this.props.categories}
        />
        break;
      default:
        returnChart = <div>
          <FormattedMessage
            id="Data.error.charts"
            defaultMessage="Probleme mit den Charts"
          />
        </div>
        break;
    }
    this.setState({ returnChart: returnChart });
  }

  componentDidMount = () => {
    this.changeChannel();
    this.changeDate();
  }

  componentDidUpdate = () => {
    this.changeChannel();
    this.changeDate();
  }

  changeChannel = () => {
    if (this.state.channel !== this.props.channel) {
      this.setState({
        channel: this.props.channel
      }, () => this.returnCorrectChart());
    }
  }

  changeDate = () => {
    if (this.props.dateFrom !== this.state.dateFrom || this.props.dateTo !== this.state.dateTo) {
      this.setState({
        dateFrom: this.props.dateFrom,
        dateTo: this.props.dateTo
      }, () => this.returnCorrectChart())
    }
  }

  render() {
    return (
      <div>
        {this.state.returnChart ?
          <div>{this.state.returnChart}</div>
          :
          <div>
            <FormattedMessage
              id="Data.error.charts"
              defaultMessage="Probleme mit den Charts"
            />
          </div>
        }
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Charts;