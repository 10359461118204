import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import moment from 'moment';
import 'moment/locale/de-at';

import {
  apiPath,
  globalErrorHandler,
  capitalizeFirst,
} from './../../reusableFunctions';

import AddInterface from './AddAPIInterface';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusSquare, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
library.add(faPlusSquare, faLongArrowAltLeft);

class ConnectorTimeline extends Component {

  constructor(props) {
    super(props);
    this.state = { channel: false }
  }

  componentDidUpdate = () => {
    if (this.props.channel !== this.state.channel) {
      this.setState({ channel: this.props.channel });
    }
  }

  changeChannel = (channel) => {
    if (channel !== this.state.channel) {
      this.setState({ channel: channel });
    }
  }

  render() {
    return (
      <div className="connector-timeline">
        {this.state.channel ?
          <div>
            <TimelineHeader
              channel={this.state.channel}
              changeChannel={this.changeChannel}
            />
            <TimelineData channel={this.state.channel} />
          </div>
          :
          ''
        }
      </div>
    )
  }
}

class TimelineHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      channel: false
    }
  }

  addInterface = (channel) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AddInterface
            channel={channel}
            onClose={onClose}
          />
        )
      }
    });
  }

  render() {
    return (
      <div className="connector-timeline-header">
        {(window.innerWidth < 768) ?
          <ChannelSwitcher
            changeChannel={this.props.changeChannel}
          />
          :
          <div>
            <span>{capitalizeFirst(this.props.channel)}</span>
            <i className="fas fa-plus-square" onClick={() => this.addInterface(this.props.channel)}></i>
          </div>
        }
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class ChannelSwitcher extends Component {

  constructor(props) {
    super(props)
    this.state = {
      channels: false,
      returnSlides: false
    }
  }

  channels = ['website', 'newsletter', 'crm']

  createSwiper = () => {

    let returnSlides = (this.channels.map((channel, index) =>
      <div key={index} className="single-slide">
        <span>{capitalizeFirst(channel)}</span>
      </div>
    ));

    if (this.state.channels.length !== this.channels.length) {
      this.setState({
        channels: this.channels,
        returnSlides: returnSlides
      });
    }
  }

  componentWillMount = () => { this.createSwiper(); }

  componentDidUpdate = () => { this.createSwiper(); }

  switchChannel = (index) => { this.props.changeChannel(this.channels[index]); }

  render() {
    const params = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 0,
      slidesPerView: 1,
      on: {
        slideChange: function () {
          this.params.switchChannel(this.activeIndex);
        }
      }
    }

    return (
      <div className="data-swiper-wrapper">
        <Swiper {...params} switchChannel={this.switchChannel}>
          {this.state.returnSlides}
        </Swiper>
      </div>
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

class TimelineData extends Component {

  constructor(props) {
    super(props);
    this.state = {
      channel: false,
      data: false,
      returnTimeline: [],
      error: false
    }
  }

  componentWillMount = () => this.setChannel();

  componentDidUpdate = () => this.setChannel();

  componentWillUnmount = () => clearInterval(this.timelineIntervall);

  startIntervall = () => {
    let that = this;
    this.getData();

    if (this.timelineIntervall) {
      clearInterval(this.timelineIntervall);
    }

    this.timelineIntervall = setInterval(function () {
      that.getData();
    }, 10000);
  }

  setChannel = () => {
    if (this.state.channel !== this.props.channel) {
      this.setState({
        channel: this.props.channel,
        returnTimeline: [],
        error: false
      }, () => this.startIntervall());
    }
  }

  getData = () => {
    let that = this;
    if (this.state.channel) {
      let channel = this.state.channel;

      fetch(apiPath + 'connector/' + channel, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        that.setState({
          data: data,
          error: false
        }, () => { that.getTimeline() });
      }).catch(function (error) {
        that.catchTimelineError();
        globalErrorHandler(error);
      });
    }
  }

  catchTimelineError = () => {
    clearInterval(this.timelineIntervall);
    this.setState({
      error: true
    }, () => this.getTimeline());
  }

  getTimeline = () => {
    let returnTimeline = [];
    if (!this.state.error && this.state.data.length > 0) {
      returnTimeline = this.state.data.map((data, index) => {

        let timestamp = false;
        if (data.created_at) {
          timestamp = moment(data.created_at).add('1', 'hours').format("HH:mm:ss");
        } else if (data.updated_at) {
          timestamp = moment(data.updated_at).add('1', 'hours').format("HH:mm:ss");
        }

        switch (this.state.channel) {
          case 'website':
            return (
              <div className="single-timeline" key={index.toString()}>
                <span className="single-timeline-id">{timestamp ? timestamp : ''}</span>
                <span className="single-timeline-device">{data.device ? data.device : ''}</span>
                {(data.category && data.category !== "false") ? <span className="single-timeline-url">{data.category}</span> : null}
                <span className="single-timeline-url">{data.url ? data.url : ''}</span>
              </div>
            )
            break;
          case 'newsletter':
            return (
              <div className="single-timeline" key={index.toString()}>
                <span className="single-timeline-id">{timestamp ? timestamp : ''}</span>
                <span className="single-timeline-id">{data.email ? data.email : ''}</span>
                <span className="single-timeline-device">{data.mailingname ? data.mailingname : data.mailingid ? data.mailingid : ''}</span>
                <span className="single-timeline-url">{data.action ? data.action : ''}</span>
              </div>
            )
            break;
          case 'messages':
            return (
              <div className="single-timeline" key={index.toString()}>
                <span className="single-timeline-id">{data.phone ? data.phone : data.email}</span>
                <span className="single-timeline-device">{data.action}</span>
              </div>
            )
            break;
          case 'crm':
            return (
              <div className="single-timeline" key={index.toString()}>
                <span className="single-timeline-id">{data.lastname ? data.lastname : '' + ' ' + data.firstname ? data.firstname : ''}</span>
                <span className="single-timeline-device">{data.email ? data.email : ''}</span>
                <span className="single-timeline-url">{data.optin ? 'Optin:' + data.optin : ''}</span>
              </div>
            )
            break;
          case 'order':
            return (
              <div className="single-timeline" key={index.toString()}>
                <span className="single-timeline-id">{data.date}</span>
                <span className="single-timeline-device">{data.product ? data.product.name : ''}</span>
                <span className="single-timeline-url">{data.sum}</span>
              </div>
            )
            break;
          case 'products':
            return (
              <div className="single-timeline" key={index.toString()}>
                <span className="single-timeline-id">{data}</span>
              </div>
            )
            break;
          default:
            return (
              <div className="single-timeline" key={index.toString()}>
                <span className="single-timeline-id">{timestamp}</span>
                <span className="single-timeline-device">{data.device}</span>
                <span className="single-timeline-url">{data.url}</span>
              </div>
            )
            break;
        }
      });
    } else {
      this.setState({
        error: true
      });
      returnTimeline = <div style={{ textAlign: 'center' }}>
        <FormattedMessage
          id="Connector.timeline.noData"
          defaultMessage="Keine Daten"
        />
      </div>;
    }

    this.setState({ returnTimeline: returnTimeline });
  }

  render() {
    return (
      <div>
        {this.state.returnTimeline.length > 0 || this.state.error ?
          <div className="single-timeline-wrapper">
            {this.state.returnTimeline}
          </div>
          :
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage
              id="Connector.timeline.loadingData"
              defaultMessage="Daten werden geladen"
            />
            ...</div>
        }
      </div >

    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default ConnectorTimeline;