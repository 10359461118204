import React, { Component } from 'react';


import { FormattedMessage } from 'react-intl';

import { CSSTransition } from 'react-transition-group'
import { Link } from 'react-router-dom';

import Select from 'react-select';

import { AudienceMenu } from '../Audience/Audience';

import TermsModal from '../Login/TermsOfUse';

import {
  apiPath,
  changeThemeColor,
  capitalizeFirst,
  globalErrorHandler,
  changeLanguage,
  language,
  selectTheme,
  messages
} from './../../reusableFunctions';

import './Menu.css';
import './DroidIcons.css';

class SettingsMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: false,
      lastName: false,
      email: false,
      role: false,
      selectedLang: 0,
      terms: false,
      languages: [
        { type: 'lang', isSelected: false, disabled: false, value: 'en', label: messages['Main.menu.languageSelect.english'] },
        { type: 'lang', isSelected: false, disabled: false, value: 'de', label: messages['Main.menu.languageSelect.german'] },
      ],
    }
  }

  componentWillMount = () => {
    this.getUserInfo();
    this.checkLanguage();
  }

  componentDidMount = () => {
    let color = '#243E5C';
    changeThemeColor(color);
  }

  checkLanguage = () => {
    let langSelect = this.state.languages;
    let selectedLang = 0;

    if (language.includes('en')) {
      langSelect[0].isSelected = true;
    } else {
      langSelect[1].isSelected = true;
      selectedLang = 1;
    }

    this.setState({
      languages: langSelect,
      selectedLang: selectedLang
    });
  }

  getUserInfo = () => {
    let that = this;
    if (!this.state.name && !this.state.email) {
      fetch(apiPath + 'auth/me', {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          that.setState({
            firstName: data.first_name,
            lastName: data.last_name,
            name: data.last_name + ' ' + data.first_name,
            email: data.email,
            role: capitalizeFirst(data.role)
          });
        })
        .catch(function (error) {
          globalErrorHandler(error);
        });
    }
  }

  showTermsOfUse = () => {
    this.setState({ terms: !this.state.terms });
  }

  render() {
    return (
      <div className="settings-menu-info-wrapper">
        {this.state.terms ? <TermsModal accepted={true} showTermsOfUse={this.showTermsOfUse} /> : null}
        <button
          className="settings-btn settings-btn-logout"
          onClick={() => this.props.logout(true)}>
          <FormattedMessage
            id="Main.menu.logoutBottonText"
            defaultMessage="Logout"
          />
        </button>
        <div>
          {(this.state.name && this.state.email) ?
            <div className="row text-left settings-info">
              <div className="col-sm-12 settings-name">
                <label>
                  <FormattedMessage
                    id="Main.menu.name"
                    defaultMessage="Name"
                  />:
                </label>
                <span>{this.state.name}</span>
              </div>
              <hr />
              <div className="col-sm-12 settings-email">
                <label>
                  <FormattedMessage
                    id="Main.menu.email"
                    defaultMessage="E-Mail Adresse"
                  />:
                </label>
                <span> {this.state.email}</span>
              </div>
              <hr />
              <div className="col-sm-12 settings-email">
                <label>
                  <FormattedMessage
                    id="Main.menu.userRole"
                    defaultMessage="User-Rolle"
                  />:
                </label>
                <span> {this.state.role}</span>
              </div>
            </div>
            :
            <div>
              <FormattedMessage
                id="Main.menu.noUserData"
                defaultMessage="Keine User Daten verfügbar"
              />
            </div>
          }
          <Select
            id={"lang-switch"}
            theme={selectTheme}
            classNamePrefix="journey-channel-select"
            options={this.state.languages}
            isSearchable={false}
            onChange={(e) => { changeLanguage(e.value) }}
            placeholder={messages['Main.menu.languageSelect.placeholder']}
            defaultValue={this.state.languages[this.state.selectedLang]}
            isOptionDisabled={(option) => option.disabled}
          />
          <button className="settings-btn" onClick={() => this.showTermsOfUse()}>AGB einsehen</button>
        </div>
      </div >
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

const DataMenu = () => {
  return (
    <div className="data-menu-inner">
      <span className="data-menu-inner-text">
        <FormattedMessage
          id="Main.menu.dataMenu.linkedDataSources"
          defaultMessage="Verknüpfte Datenquellen"
        />:
        </span>
      <Link
        to={{ pathname: '/data/website' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-website"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.dataMenu.website"
                defaultMessage="Website"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/data/newsletter' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-newsletter"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.dataMenu.newsletter"
                defaultMessage="Newsletter"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/data/print' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-direct"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.dataMenu.print"
                defaultMessage="Direct-2-Print"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/data/sms' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-sms"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.dataMenu.sms"
                defaultMessage="SMS"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/data/banner' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-onlinebanner"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.dataMenu.banner"
                defaultMessage="Banner"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/data/crm' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-crm"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.dataMenu.crm"
                defaultMessage="CRM-Daten"
              />
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

class JourneyMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      searchResults: '',
      singleJournies: <span><FormattedMessage id="Main.menu.journeyMenu.noJourneys" defaultMessage="Keine angelegten Journeys" /></span>,
      existingJournies: this.props.existingJournies
    }
  }

  componentWillMount = () => {
    this.showExistingJournies();
  }

  getSearchValue = (value) => {
    this.setState({
      searchValue: value
    }, () => this.searchJourney(value));
  }

  searchJourney(value) {

    let target = this.state.existingJournies;
    let searchResults = <div></div>;

    if (value === '') {
      searchResults = <div></div>;
      this.setState({ searchResults: searchResults });
      return false;
    }

    function searchCondition(obj) {
      let condition = ((obj.name.includes(value)) || (obj.name.toUpperCase().includes(value.toUpperCase()))) && (value !== '');
      if (condition) {
        return obj;
      }
    }

    searchResults = target.filter(target => searchCondition(target));
    let singleJournies = <div>Keine passenden Journies gefunden</div>;
    if (searchResults.length > 0) {
      singleJournies = searchResults.map((singleJourney) =>
        <div className="single-journies" key={singleJourney.id.toString()}>
          <a onClick={() => { this.props.recreateJourney(singleJourney) }} >
            <span className="single-journies-name">{singleJourney.name}</span>
            <div className="single-journies-status">
              {(singleJourney.status === 'on') ?
                <i className="fas fa-toggle-on"></i>
                :
                <i className="fas fa-toggle-off"></i>
              }
            </div><br />
          </a>
          <hr className="single-journies-hr" />
        </div>
      );
    }
    this.setState({ searchResults: singleJournies });
  }

  showExistingJournies = () => {
    let existingJournies = this.state.existingJournies;
    let singleJournies;
    if (existingJournies.length > 0) {
      singleJournies = existingJournies.map((singleJourney) =>
        <div className="single-journies" key={singleJourney.id.toString()}>
          <a onClick={() => { this.props.recreateJourney(singleJourney) }} >
            <span className="single-journies-name">{singleJourney.name}</span>
            <div className="single-journies-status">
              {(singleJourney.status === 'on') ?
                <i className="fas fa-toggle-on"></i>
                :
                <i className="fas fa-toggle-off"></i>
              }
            </div><br />
          </a>
          <hr className="single-journies-hr" />
        </div>
      );
      this.setState({ singleJournies: singleJournies });
    }
  }

  render() {
    return (
      <div className="available-journeys-wrapper journey-menu-inner">
        <button className="journey-btn shadow" onClick={() => this.props.renewJourney()}>
          <FormattedMessage
            id="Main.menu.journeyMenu.newJourneyButtonText"
            defaultMessage="Neue Journey erstellen"
          />
        </button>
        <div className="side-menu-search row">
          <div className="col align-self-center no-gutters">
            <div className="title row align-self-start">
              <span>
                <FormattedMessage
                  id="Main.menu.journeyMenu.journeySearch"
                  defaultMessage="Journey Suche"
                />:
              </span>
            </div>
            <div className="row align-self-start justify-content-between input">
              <div className="row no-gutters">
                <div className="col-11">
                  <JourneySearchInput
                    getSearchValue={this.getSearchValue}
                  />
                </div>
                <div className="col-1">
                  <i
                    className="icon-color fas fa-pencil-alt align-self-center"
                    style={{ position: 'relative', top: '5px' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <ul className="search-result col">{this.state.searchResults}</ul>
        </div>
        <span className="available-journeys-wrapper-header">
          <FormattedMessage
            id="Main.menu.journeyMenu.myJourneys"
            defaultMessage="Meine definierten Journeys"
          />:
      </span>
        <div className="single-journies-wrapper">
          {this.state.singleJournies}
        </div>
      </div>
    )
  }
}

class JourneySearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  interval = 1000;
  enterKey = 13;

  componentWillMount() {
    this.timer = null;
  }

  handleChange(value) {
    clearTimeout(this.timer);
    this.setState({ value }, () => {
      this.timer = setTimeout(() => this.triggerChange(this.state.value), this.interval);
    });
  }

  handleKeyDown(e) {
    if (e.keyCode === this.enterKey) {
      this.triggerChange(e.target.value);
    }
  }

  triggerChange(value) {
    this.setState({ value }, () => this.props.getSearchValue(this.state.value));
  }

  render() {
    const { className } = this.props;
    return (
      <input
        className={className}
        placeholder={messages['Main.menu.journeyMenu.journeySearchInput']}
        type="text"
        value={this.state.value}
        onChange={(e) => this.handleChange(e.target.value)}
        onKeyDown={(e) => this.handleKeyDown(e)}
      />
    );
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

const DeliveryMenu = () => {
  return (
    <div className="data-menu-inner">
      <span className="data-menu-inner-text">
        <FormattedMessage
          id="Main.menu.deliveryMenu.linkedDeliveryChannels"
          defaultMessage="Verknüpfte Versandkanäle"
        />:
      </span>
      <Link
        to={{ pathname: '/delivery/newsletter' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-newsletter"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.deliveryMenu.newsletter"
                defaultMessage="Newsletter"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/delivery/print' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-direct"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.deliveryMenu.print"
                defaultMessage="Direct-2-Print"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/delivery/sms' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-sms"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.deliveryMenu.sms"
                defaultMessage="SMS"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/delivery/banner' }}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-onlinebanner"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.deliveryMenu.banner"
                defaultMessage="Banner"
              />
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

const ReportsMenu = (props) => {
  return (
    <div className="data-menu-inner">
      <span className="data-menu-inner-text">
        <FormattedMessage
          id="Main.menu.reportsMenu.linkedDataSources"
          defaultMessage="Verknüpfte Datenquellen"
        />:
      </span>
      <Link
        to={{ pathname: '/reports/allgemeine_werte' }}
        onClick={() => props.moveMenu()}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-newsletter"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.reportsMenu.generalValues"
                defaultMessage="Allgemeine Werte"
              />
            </span>
          </div>
        </div>
      </Link>
      <hr />
      <Link
        to={{ pathname: '/reports/journey' }}
        onClick={() => props.moveMenu()}
      >
        <div className="row data-menu-inner-single">
          <div className="col-3">
            <i className="icon-direct"></i>
          </div>
          <div className="col-9 text-left">
            <span>
              <FormattedMessage
                id="Main.menu.reportsMenu.journey"
                defaultMessage="Journey"
              />
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

const MenuHead = (props) => {

  let moduleName = props.moduleName;

  let imgSrc = require('./pictures/logo-menu-' + moduleName + '.png');

  return (
    <div className="side-menu-container-head">
      <div className="menu-back-button" onClick={() => props.moveMenu()}>
        <i className="fas fa-arrow-right"></i>
      </div>
      <div className={"side-menu-header side-menu-header-" + moduleName + " row"}>
        <div className="col align-self-center">
          <img src={imgSrc} alt="logo-menu" />
          <h5 style={{ textTransform: 'uppercase' }}>
            <span className="bold">Droid </span>
            <span>{moduleName.toString().toUpperCase()}</span>
          </h5>
        </div>
      </div>
    </div>
  )
}

const InnerMenu = (props) => {

  let moduleName = props.moduleName;

  switch (moduleName) {
    case 'data':
      return <DataMenu channels={props.channels} />
    case 'audience':
      return (<AudienceMenu
        loadTargetGroup={props.loadTargetGroup}
        updateState={props.updateState}
        targetList={props.targetList}
      />)
    case 'journey':
      return (<JourneyMenu
        existingJournies={props.existingJournies}
        recreateJourney={props.recreateJourney}
        journeyMenu={props.journeyMenu}
        renewJourney={props.renewJourney}
      />)
    case 'delivery':
      return <DeliveryMenu />
    case 'reports':
      return <ReportsMenu moveMenu={props.moveMenu} />
    case 'settings':
      return <SettingsMenu logout={props.logout} />
    default:
      return (<div>
        <FormattedMessage
          id="Main.menu.noMenuLoading"
          defaultMessage="Konnte kein Menü laden"
        />
      </div>)
  }
}

export class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      moduleName: '',
    };
  };

  componentDidUpdate = () => {
    if (this.props.moduleName !== this.state.moduleName) {
      this.setState({
        moduleName: this.props.moduleName
      });
    }
    if (this.props.showMenu !== this.state.showMenu && this.props.showMenu !== undefined) {
      this.setState({
        showMenu: this.props.showMenu
      });
    }
  }

  render() {
    return (
      <div className="droid-menu">
        <CSSTransition
          classNames="fade"
          timeout={10}
          in={this.state.showMenu}
        >
          <div>
            {this.state.showMenu && (this.state.moduleName !== "") ?
              <div>
                <div className={"side-menu-container side-menu-container-" + this.state.moduleName + " container"}>
                  <MenuHead
                    moduleName={this.state.moduleName}
                    moveMenu={this.props.moveMenu}
                  />
                  <InnerMenu
                    moduleName={this.state.moduleName}
                    // for audience
                    loadTargetGroup={this.props.loadTargetGroup}
                    updateState={this.props.updateState}
                    targetList={this.props.targetList}
                    // for data
                    channels={this.props.channels}
                    // for journey
                    existingJournies={this.props.existingJournies}
                    recreateJourney={this.props.recreateJourney}
                    renewJourney={this.props.renewJourney}
                    // for settings
                    logout={this.props.logout}
                    // reports for now
                    moveMenu={this.props.moveMenu}
                  />
                </div>
              </div>
              :
              null}
          </div>
        </CSSTransition>
      </div >
    )
  }

  componentDidCatch = (error, errorInfo) => {

  }
}

export default Menu;